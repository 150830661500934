const GHOST_USER_HEADER_KEY = "g-auth";
const GHOST_USER_HEADER_VALUE = "8f9450795ffeb600222c58ad4656906b";

export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    const adder = {};
    adder[GHOST_USER_HEADER_KEY] = GHOST_USER_HEADER_VALUE;
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': user.token,
      ...user.userData.auth_type === 'A0' ? adder : {}
    };
  } else {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }
}