import {
  authHeader,
  handleResponse,
  setMyUserData,
  getMyUserData,
  toSha256,
  requestGA,
} from '../helpers';

export const userService = {
  companyUserList,
  franchiseBrandList,
  userProfileChange,
  myUserData,
  serviceNoticeList,
  getFaqList,
  completeTutorial,
  registUser,
  getTheCheckUser,
  submitTheCheckUser,
  getUserList,
  createMultiUser,
  createSingleUser,
  inviteSingleUser,
  inviteMultiUser,
  editUserInfo,
  disableUser,
  editUserAuth,
  pushPosInstall,
  setRedirectTo,
  editUserAuthInfo,
  withdrawalRequest,
  withdrawalCancel,
  setAppUserID,
  setInflow,
};

let abertControllers = {};

function companyUserList(companyCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode: companyCode,
    }),
  };

  return fetch('/user/companyUserList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function franchiseBrandList(userSno, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      serviceType: serviceType,
    }),
  };

  return fetch('/user/franchiseBrandList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function userProfileChange(userSno, brandCode, officeSno, serviceType, isLogout) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      officeSno: officeSno,
      serviceType: serviceType,
      isLogout: isLogout,
    }),
  };

  return fetch('/user/userProfileChange', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function myUserData(userSno, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      serviceType: serviceType,
    }),
  };

  return fetch('/user/myUserData', requestOptions)
    .then(handleResponse)
    .then(data => {
      setMyUserData(data.result);
      requestGA(data.result);

      return data;
    });
}

function serviceNoticeList(companyCode, brandCode, areaCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode: companyCode,
      brandCode: brandCode,
      areaCode: areaCode,
    }),
  };

  return fetch('/user/serviceNoticeList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getFaqList() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch('/user/getFaqList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function completeTutorial(userSno, tutorialBit) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      tutorialBit: tutorialBit,
    }),
  };

  var userData = getMyUserData();
  if (userData) {
    userData['tutorial_able_bit'] = tutorialBit;
    setMyUserData(userData);
  }

  return fetch('/user/completeTutorial', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function registUser(
  registType,
  phoneNumber,
  userName,
  email,
  password,
  companyName,
  brandName,
  officeCount,
  officeName,
  ceoName,
  businessNum,
  addressData,
  detailAddress,
  buinessFilePath,
  brandKey,
  memo,
  userKey,
  promotionCode,
  promotionType,
  checkList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      registType: registType,
      phoneNumber: phoneNumber,
      companyName: companyName,
      brandName: brandName,
      officeCount: officeCount,
      userName: userName,
      email: email,
      password: toSha256(password),
      officeName: officeName,
      ceoName: ceoName,
      businessNum: businessNum,
      addressData: addressData,
      detailAddress: detailAddress,
      buinessFilePath: buinessFilePath,
      brandKey: brandKey,
      memo,
      userKey,
      promotionCode,
      promotionType,
      checkList: checkList,
    }),
  };

  return fetch('/user/registUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getTheCheckUser(userKey) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userKey: userKey,
    }),
  };

  return fetch('/user/getTheCheckUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function submitTheCheckUser(userKey, inputValues) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userKey: userKey,
      inputValues: inputValues,
    }),
  };

  return fetch('/user/submitTheCheckUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getUserList(brandCode, areaCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      areaCode: areaCode,
    }),
  };

  return fetch('/user/getUserList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function createMultiUser(brandCode, list) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      list: list,
    }),
  };

  return fetch('/user/createMultiUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function createSingleUser(userName, phoneNumber, email, brandCode, areaList, authCode, managementList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userName: userName,
      phoneNumber: phoneNumber,
      email: email,
      brandCode: brandCode,
      areaList: areaList,
      authCode: authCode,
      accessFuncBit: managementList,
    }),
  };

  return fetch('/user/createSingleUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function inviteSingleUser(idx, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
      userSno: userSno,
    }),
  };

  return fetch('/user/inviteSingleUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function inviteMultiUser(brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
    }),
  };

  return fetch('/user/inviteMultiUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editUserInfo(idx, userName, email, phoneNumber) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
    }),
  };

  return fetch('/user/editUserInfo', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function disableUser(idx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
    }),
  };

  return fetch('/user/disableUser', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editUserAuth(idx, authCode, areaList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
      authCode: authCode,
      areaList: areaList,
    }),
  };

  return fetch('/user/editUserAuth', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function pushPosInstall(userSno, officeSno) {
  if (abertControllers['pushPosInstall']) {
    abertControllers['pushPosInstall'].abort();
  }
  abertControllers['pushPosInstall'] = new AbortController();
  let signal = abertControllers['pushPosInstall'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      officeSno: officeSno,
    }),
    signal
  };

  return fetch('/v2/message/pushPosInstall', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setRedirectTo(userSno, redirectTo) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      redirectTo: redirectTo,
    }),
  };

  return fetch('/user/setRedirectTo', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editUserAuthInfo(idx, AccessFuncBit, areaList, authCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
      AccessFuncBit: AccessFuncBit,
      areaList: areaList,
      authCode: authCode,
    }),
  };

  return fetch('/user/editUserAuthInfo', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function withdrawalRequest(userSno, serviceType, withdrawalReasonCode, withdrawalOtherReason) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      serviceType: serviceType,
      withdrawalReasonCode: withdrawalReasonCode,
      withdrawalOtherReason: withdrawalOtherReason,
    }),
  };

  return fetch('/user/withdrawalRequest', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function withdrawalCancel(userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
    }),
  };

  return fetch('/user/withdrawalCancel', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setAppUserID(userSno, init = false) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      init: init,
    }),
  };

  return fetch('/user/setAppUserID', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setInflow(buttonFlag, userSno, inflowPath) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      buttonFlag: buttonFlag,
      userSno: userSno,
      inflowPath: inflowPath,
    }),
  };

  return fetch('/user/setInflow', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
