export const brandConstants = {
  NOTICE_LIST_RESET: 'NOTICE_LIST_RESET',
  NOTICE_LIST_REQUEST: 'NOTICE_LIST_REQUEST',
  NOTICE_LIST_SUCCESS: 'NOTICE_LIST_SUCCESS',
  NOTICE_LIST_FAILURE: 'NOTICE_LIST_FAILURE',

  NOTICE_ONE_LOAD_REQUEST: 'NOTICE_ONE_LOAD_REQUEST',
  NOTICE_ONE_LOAD_SUCCESS: 'NOTICE_ONE_LOAD_SUCCESS',
  NOTICE_ONE_LOAD_FAILURE: 'NOTICE_ONE_LOAD_FAILURE',

  NOTICE_CREATE_RESET: 'NOTICE_CREATE_RESET',
  NOTICE_CREATE_REQUEST: 'NOTICE_CREATE_REQUEST',
  NOTICE_CREATE_SUCCESS: 'NOTICE_CREATE_SUCCESS',
  NOTICE_CREATE_FAILURE: 'NOTICE_CREATE_FAILURE',

  NOTICE_EDIT_RESET: 'NOTICE_EDIT_RESET',
  NOTICE_EDIT_REQUEST: 'NOTICE_EDIT_REQUEST',
  NOTICE_EDIT_SUCCESS: 'NOTICE_EDIT_SUCCESS',
  NOTICE_EDIT_FAILURE: 'NOTICE_EDIT_FAILURE',

  NOTICE_WRITE_COMMENT_RESET: 'NOTICE_WRITE_COMMENT_RESET',
  NOTICE_WRITE_COMMENT_REQUEST: 'NOTICE_WRITE_COMMENT_REQUEST',
  NOTICE_WRITE_COMMENT_SUCCESS: 'NOTICE_WRITE_COMMENT_SUCCESS',
  NOTICE_WRITE_COMMENT_FAILURE: 'NOTICE_WRITE_COMMENT_FAILURE',

  NOTICE_DISABLE_COMMENT_RESET: 'NOTICE_DISABLE_COMMENT_RESET',
  NOTICE_DISABLE_COMMENT_REQUEST: 'NOTICE_DISABLE_COMMENT_REQUEST',
  NOTICE_DISABLE_COMMENT_SUCCESS: 'NOTICE_DISABLE_COMMENT_SUCCESS',
  NOTICE_DISABLE_COMMENT_FAILURE: 'NOTICE_DISABLE_COMMENT_FAILURE',

  NOTICE_CONFIRM_RESET: 'NOTICE_CONFIRM_RESET',
  NOTICE_CONFIRM_REQUEST: 'NOTICE_CONFIRM_REQUEST',
  NOTICE_CONFIRM_SUCCESS: 'NOTICE_CONFIRM_SUCCESS',
  NOTICE_CONFIRM_FAILURE: 'NOTICE_CONFIRM_FAILURE',

  NOTICE_READ_LIST_REQUEST: 'NOTICE_READ_LIST_REQUEST',
  NOTICE_READ_LIST_SUCCESS: 'NOTICE_READ_LIST_SUCCESS',
  NOTICE_READ_LIST_FAILURE: 'NOTICE_READ_LIST_FAILURE',

  NOTICE_DISABLE_RESET: 'NOTICE_DISABLE_RESET',
  NOTICE_DISABLE_REQUEST: 'NOTICE_DISABLE_REQUEST',
  NOTICE_DISABLE_SUCCESS: 'NOTICE_DISABLE_SUCCESS',
  NOTICE_DISABLE_FAILURE: 'NOTICE_DISABLE_FAILURE',

  ALL_BRAND_LIST_REQUEST: 'ALL_BRAND_LIST_REQUEST',
  ALL_BRAND_LIST_SUCCESS: 'ALL_BRAND_LIST_SUCCESS',
  ALL_BRAND_LIST_FAILURE: 'ALL_BRAND_LIST_FAILURE',

  //매장 관리
  GET_FRANCHISE_OFFICE_STATUS_RESET: 'GET_FRANCHISE_OFFICE_STATUS_RESET',
  GET_FRANCHISE_OFFICE_STATUS_REQUEST: 'GET_FRANCHISE_OFFICE_STATUS_REQUEST',
  GET_FRANCHISE_OFFICE_STATUS_SUCCESS: 'GET_FRANCHISE_OFFICE_STATUS_SUCCESS',
  GET_FRANCHISE_OFFICE_STATUS_FAILURE: 'GET_FRANCHISE_OFFICE_STATUS_FAILURE',

  GET_ORDERAPP_OFFICE_STATUS_RESET: 'GET_ORDERAPP_OFFICE_STATUS_RESET',
  GET_ORDERAPP_OFFICE_STATUS_REQUEST: 'GET_ORDERAPP_OFFICE_STATUS_REQUEST',
  GET_ORDERAPP_OFFICE_STATUS_SUCCESS: 'GET_ORDERAPP_OFFICE_STATUS_SUCCESS',
  GET_ORDERAPP_OFFICE_STATUS_FAILURE: 'GET_ORDERAPP_OFFICE_STATUS_FAILURE',

  CHECK_SIGN_UP_LINK_CLICK_REQUEST: 'CHECK_SIGN_UP_LINK_CLICK_REQUEST',
  CHECK_SIGN_UP_LINK_CLICK_SUCCESS: 'CHECK_SIGN_UP_LINK_CLICK_SUCCESS',
  CHECK_SIGN_UP_LINK_CLICK_FAILURE: 'CHECK_SIGN_UP_LINK_CLICK_FAILURE',

  INSERT_MULTI_OFFICE_MANAGER_INFO_REQUEST: 'INSERT_MULTI_OFFICE_MANAGER_INFO_REQUEST',
  INSERT_MULTI_OFFICE_MANAGER_INFO_SUCCESS: 'INSERT_MULTI_OFFICE_MANAGER_INFO_SUCCESS',
  INSERT_MULTI_OFFICE_MANAGER_INFO_FAILURE: 'INSERT_MULTI_OFFICE_MANAGER_INFO_FAILURE',

  // INSERT_SINGLE_OFFICE_MANAGER_INFO_REQUEST: 'INSERT_SINGLE_OFFICE_MANAGER_INFO_REQUEST',
  // INSERT_SINGLE_OFFICE_MANAGER_INFO_SUCCESS: 'INSERT_SINGLE_OFFICE_MANAGER_INFO_SUCCESS',
  // INSERT_SINGLE_OFFICE_MANAGER_INFO_FAILURE: 'INSERT_SINGLE_OFFICE_MANAGER_INFO_FAILURE',

  // EDIT_OFFICE_MANAGER_INFO_RESET: 'EDIT_OFFICE_MANAGER_INFO_RESET',
  // EDIT_OFFICE_MANAGER_INFO_REQUEST: 'EDIT_OFFICE_MANAGER_INFO_REQUEST',
  // EDIT_OFFICE_MANAGER_INFO_SUCCESS: 'EDIT_OFFICE_MANAGER_INFO_SUCCESS',
  // EDIT_OFFICE_MANAGER_INFO_FAILURE: 'EDIT_OFFICE_MANAGER_INFO_FAILURE',

  // MANAGE_FRANCHISE_OFFICE_STATUS_REQUEST: 'MANAGE_FRANCHISE_OFFICE_STATUS_REQUEST',
  // MANAGE_FRANCHISE_OFFICE_STATUS_SUCCESS: 'MANAGE_FRANCHISE_OFFICE_STATUS_SUCCESS',
  // MANAGE_FRANCHISE_OFFICE_STATUS_FAILURE: 'MANAGE_FRANCHISE_OFFICE_STATUS_FAILURE',

  // CHECK_OFFICE_CLOSING_REQUEST: 'CHECK_OFFICE_CLOSING_REQUEST',
  // CHECK_OFFICE_CLOSING_SUCCESS: 'CHECK_OFFICE_CLOSING_SUCCESS',
  // CHECK_OFFICE_CLOSING_FAILURE: 'CHECK_OFFICE_CLOSING_FAILURE',

  // REMOVE_FRANCHISE_OFFICE_REQUEST: 'REMOVE_FRANCHISE_OFFICE_REQUEST',
  // REMOVE_FRANCHISE_OFFICE_SUCCESS: 'REMOVE_FRANCHISE_OFFICE_SUCCESS',
  // REMOVE_FRANCHISE_OFFICE_FAILURE: 'REMOVE_FRANCHISE_OFFICE_FAILURE',
};
