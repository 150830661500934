import './App.css';
import $ from 'jquery';
import React, { lazy, Suspense } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import { PublicRoute } from './helpers/PrivateRoute';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Home = lazy(() => import('./pages/Home'));
const LoadingFallback = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse" color="#4d5eff" />
      </div>
      <h6 className="mt-5">
        로딩중입니다. 잠시만 기다려 주십시오.
        <small>Home :: 문의사항이 있으실 경우, 앳트래커 고객센터로 문의주시기 바랍니다.</small>
        <small className="opacity-5 mt-4">고객센터 : 1899-8351 | 이메일 : support@nuvent.co.kr</small>
      </h6>
    </div>
  </div>
);

const isMobile = (checkWidth = true) => {
  const isWebView = window.ReactNativeWebView ? true : false;
  if (isWebView) {
    return true;
  }

  if (checkWidth && document.body.clientWidth > 768) {
    return false;
  }

  if (window.location.hash.includes('promotion-google')) {
    return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  } else {
    if (isTablet()) {
      return false;
    }

    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
};

const isTablet = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/SM-T[0-9]/)) {
    return true;
  }

  return /ipad|Tablet/i.test(userAgent);
};

const Main = () => {
  return (
    <>
      <Suspense fallback={LoadingFallback()}>
        <PublicRoute path="/" component={Home} />
      </Suspense>
    </>
  );
};

class App extends React.Component {
  state = {
    hasError: false,
    error: undefined,
    clsssnaming: '',
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      enableNarrowViewSidebar, // 상단 헤더, 없는경우 height: 100vh - 60px 제거
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => {
          if (width < 576 && enableMobileMenu) {
            $('body').css('overflow', 'hidden');
          } else {
            $('body').css('overflow', 'initial');
          }

          if (isMobile() === true) {
            $('body').css('background-color', '#f4f6f8');
          }

          return (
            <Container>
              <div
                id="app-container"
                className={cx(
                  `app-container app-theme-${colorScheme}`,
                  { 'fixed-header': enableFixedHeader },
                  { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                  { 'fixed-footer': enableFixedFooter },
                  { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                  { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                  { 'open-sidebar-mobile': width < 576 && enableMobileMenu }, // 모바일뷰 사이즈 아닐때도 추가되어 조건 보완
                  { 'body-tabs-shadow-btn': enablePageTabsAlt },
                  { 'narrow-view-sidebar': enableNarrowViewSidebar },
                  { 'mobile-page': isMobile() },
                  { 'not-mobile-page': !isMobile() },
                )}
              >
                {/* <UtilToolkit /> */}
                <Main />
              </div>
            </Container>
          );
        }}
      />
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  enableNarrowViewSidebar: state.ThemeOptions.enableNarrowViewSidebar,
});

const Container = styled.div``;

export default withRouter(connect(mapStateToProp)(App));
