import { setDownloadRequestValue } from '../constants';
import { authHeader, handleResponse } from '../helpers';

export const customerReviewService = {
  officeReviewList,
  orderAppReviewList,
  specificReviewList,
  mobileReviewList,
  mobileTodayReviewList,
  mobileSpecificReviewList,
  mobileTodaySpecificReviewList,
  insertAnswer,
  deleteAnswer,
  autoAnswerList,
  updateAutoAnswer,
  insertAutoAnswer,
  deleteAutoAnswer,
  specificReview,
  syncReviewList,
};

let abertControllers = {};

function officeReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList) {
  if (abertControllers['officeReviewList']) {
    abertControllers['officeReviewList'].abort();
  }
  abertControllers['officeReviewList'] = new AbortController();
  let signal = abertControllers['officeReviewList'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportOfficeReviewList', requestOptions);

  return fetch('/v2/customerReview/officeReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function orderAppReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
    }),
  };

  setDownloadRequestValue('/report/pageExportOrderAppReviewList', requestOptions);

  return fetch('/v2/customerReview/orderAppReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificReviewList(
  userSno,
  brandCode,
  startDate,
  endDate,
  officeSnoList,
  size,
  page,
  point,
  orderApp,
  officeSno,
  reviewOption,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      size: size,
      page: page,
      point: point,
      orderApp: orderApp,
      officeSno: officeSno,
      reviewOption: reviewOption,
    }),
  };

  return fetch('/v2/customerReview/specificReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mobileReviewList(brandCode, officeSno, startDate, endDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
    }),
  };

  return fetch('/v2/customerReview/mobileReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mobileTodayReviewList(brandCode, officeSno, startDate, endDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
    }),
  };

  return fetch('/v2/customerReview/mobileReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mobileSpecificReviewList(brandCode, officeSno, startDate, endDate, size, page, point, orderApp, answerYn) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      size: size,
      page: page,
      point: point,
      orderApp: orderApp,
      answerYn: answerYn,
    }),
  };

  return fetch('/v2/customerReview/mobileSpecificReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mobileTodaySpecificReviewList(
  brandCode,
  officeSno,
  startDate,
  endDate,
  size,
  page,
  point,
  orderApp,
  answerYn,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      size: size,
      page: page,
      point: point,
      orderApp: orderApp,
      answerYn: answerYn,
    }),
  };

  // 모바일 오늘의리뷰, 별점리뷰, 채널리뷰 공유 API
  return fetch('/v2/customerReview/mobileSpecificReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function insertAnswer(reviewIdx, reviewSno, answer) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      reviewIdx: reviewIdx,
      reviewSno: reviewSno,
      answer: answer,
    }),
  };

  return fetch('/v2/customerReview/insertAnswer', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deleteAnswer(reviewAnswerIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      reviewAnswerIdx: reviewAnswerIdx,
    }),
  };

  return fetch('/v2/customerReview/deleteAnswer', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function autoAnswerList(officeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeSno: officeSno,
    }),
  };

  return fetch('/v2/customerReview/autoAnswerList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function updateAutoAnswer(officeSno, point, answerIdx, autoYn, storeNumberList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeSno: officeSno,
      point: point,
      answerIdx: answerIdx,
      autoYn: autoYn,
      storeNumberList: storeNumberList,
    }),
  };

  return fetch('/v2/customerReview/updateAutoAnswer', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function insertAutoAnswer(officeSno, point, answer, autoYn, storeNumberList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeSno: officeSno,
      point: point,
      answer: answer,
      autoYn: autoYn,
      storeNumberList: storeNumberList,
    }),
  };

  return fetch('/v2/customerReview/insertAutoAnswer', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deleteAutoAnswer(answerIdx, storeNumberList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      answerIdx: answerIdx,
      storeNumberList: storeNumberList,
    }),
  };

  return fetch('/v2/customerReview/deleteAutoAnswer', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificReview(reviewIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      reviewIdx: reviewIdx,
    }),
  };

  return fetch('/v2/customerReview/specificReview', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function syncReviewList(officeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeSno: officeSno,
    }),
  };

  return fetch('/v2/customerReview/syncReviewList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
