export const customerReviewConstants = {
  OFFICE_REVIEW_LIST_RESET: 'OFFICE_REVIEW_LIST_RESET',
  OFFICE_REVIEW_LIST_REQUEST: 'OFFICE_REVIEW_LIST_REQUEST',
  OFFICE_REVIEW_LIST_SUCCESS: 'OFFICE_REVIEW_LIST_SUCCESS',
  OFFICE_REVIEW_LIST_FAILURE: 'OFFICE_REVIEW_LIST_FAILURE',

  ORDER_APP_REVIEW_LIST_RESET: 'ORDER_APP_REVIEW_LIST_RESET',
  ORDER_APP_REVIEW_LIST_REQUEST: 'ORDER_APP_REVIEW_LIST_REQUEST',
  ORDER_APP_REVIEW_LIST_SUCCESS: 'ORDER_APP_REVIEW_LIST_SUCCESS',
  ORDER_APP_REVIEW_LIST_FAILURE: 'ORDER_APP_REVIEW_LIST_FAILURE',

  SPECIFIC_REVIEW_LIST_RESET: 'SPECIFIC_REVIEW_LIST_RESET',
  SPECIFIC_REVIEW_LIST_REQUEST: 'SPECIFIC_REVIEW_LIST_REQUEST',
  SPECIFIC_REVIEW_LIST_SUCCESS: 'SPECIFIC_REVIEW_LIST_SUCCESS',
  SPECIFIC_REVIEW_LIST_FAILURE: 'SPECIFIC_REVIEW_LIST_FAILURE',

  MOBILE_REVIEW_LIST_RESET: 'MOBILE_REVIEW_LIST_RESET',
  MOBILE_REVIEW_LIST_REQUEST: 'MOBILE_REVIEW_LIST_REQUEST',
  MOBILE_REVIEW_LIST_SUCCESS: 'MOBILE_REVIEW_LIST_SUCCESS',
  MOBILE_REVIEW_LIST_FAILURE: 'MOBILE_REVIEW_LIST_FAILURE',

  MOBILE_TODAY_REVIEW_LIST_RESET: 'MOBILE_TODAY_REVIEW_LIST_RESET',
  MOBILE_TODAY_REVIEW_LIST_REQUEST: 'MOBILE_TODAY_REVIEW_LIST_REQUEST',
  MOBILE_TODAY_REVIEW_LIST_SUCCESS: 'MOBILE_TODAY_REVIEW_LIST_SUCCESS',
  MOBILE_TODAY_REVIEW_LIST_FAILURE: 'MOBILE_TODAY_REVIEW_LIST_FAILURE',

  MOBILE_SPECIFIC_REVIEW_LIST_RESET: 'MOBILE_SPECIFIC_REVIEW_LIST_RESET',
  MOBILE_SPECIFIC_REVIEW_LIST_REQUEST: 'MOBILE_SPECIFIC_REVIEW_LIST_REQUEST',
  MOBILE_SPECIFIC_REVIEW_LIST_SUCCESS: 'MOBILE_SPECIFIC_REVIEW_LIST_SUCCESS',
  MOBILE_SPECIFIC_REVIEW_LIST_FAILURE: 'MOBILE_SPECIFIC_REVIEW_LIST_FAILURE',

  MOBILE_TODAY_SPECIFIC_REVIEW_LIST_RESET: 'MOBILE_TODAY_SPECIFIC_REVIEW_LIST_RESET',
  MOBILE_TODAY_SPECIFIC_REVIEW_LIST_REQUEST: 'MOBILE_TODAY_SPECIFIC_REVIEW_LIST_REQUEST',
  MOBILE_TODAY_SPECIFIC_REVIEW_LIST_SUCCESS: 'MOBILE_TODAY_SPECIFIC_REVIEW_LIST_SUCCESS',
  MOBILE_TODAY_SPECIFIC_REVIEW_LIST_FAILURE: 'MOBILE_TODAY_SPECIFIC_REVIEW_LIST_FAILURE',

  INSERT_ANSWER_RESET: 'INSERT_ANSWER_RESET',
  INSERT_ANSWER_REQUEST: 'INSERT_ANSWER_REQUEST',
  INSERT_ANSWER_SUCCESS: 'INSERT_ANSWER_SUCCESS',
  INSERT_ANSWER_FAILURE: 'INSERT_ANSWER_FAILURE',

  DELETE_ANSWER_RESET: 'DELETE_ANSWER_RESET',
  DELETE_ANSWER_REQUEST: 'DELETE_ANSWER_REQUEST',
  DELETE_ANSWER_SUCCESS: 'DELETE_ANSWER_SUCCESS',
  DELETE_ANSWER_FAILURE: 'DELETE_ANSWER_FAILURE',

  AUTO_ANSWER_LIST_REQUEST: 'AUTO_ANSWER_LIST_REQUEST',
  AUTO_ANSWER_LIST_SUCCESS: 'AUTO_ANSWER_LIST_SUCCESS',
  AUTO_ANSWER_LIST_FAILURE: 'AUTO_ANSWER_LIST_FAILURE',

  UPDATE_AUTO_ANSWER_RESET: 'UPDATE_AUTO_ANSWER_RESET',
  UPDATE_AUTO_ANSWER_REQUEST: 'UPDATE_AUTO_ANSWER_REQUEST',
  UPDATE_AUTO_ANSWER_SUCCESS: 'UPDATE_AUTO_ANSWER_SUCCESS',
  UPDATE_AUTO_ANSWER_FAILURE: 'UPDATE_AUTO_ANSWER_FAILURE',

  INSERT_AUTO_ANSWER_RESET: 'INSERT_AUTO_ANSWER_RESET',
  INSERT_AUTO_ANSWER_REQUEST: 'INSERT_AUTO_ANSWER_REQUEST',
  INSERT_AUTO_ANSWER_SUCCESS: 'INSERT_AUTO_ANSWER_SUCCESS',
  INSERT_AUTO_ANSWER_FAILURE: 'INSERT_AUTO_ANSWER_FAILURE',

  DELETE_AUTO_ANSWER_RESET: 'DELETE_AUTO_ANSWER_RESET',
  DELETE_AUTO_ANSWER_REQUEST: 'DELETE_AUTO_ANSWER_REQUEST',
  DELETE_AUTO_ANSWER_SUCCESS: 'DELETE_AUTO_ANSWER_SUCCESS',
  DELETE_AUTO_ANSWER_FAILURE: 'DELETE_AUTO_ANSWER_FAILURE',

  SPECIFIC_REVIEW_RESET: 'SPECIFIC_REVIEW_RESET',
  SPECIFIC_REVIEW_REQUEST: 'SPECIFIC_REVIEW_REQUEST',
  SPECIFIC_REVIEW_SUCCESS: 'SPECIFIC_REVIEW_SUCCESS',
  SPECIFIC_REVIEW_FAILURE: 'SPECIFIC_REVIEW_FAILURE',

  SYNC_REVIEW_LIST_RESET: 'SYNC_REVIEW_LIST_RESET',
  SYNC_REVIEW_LIST_REQUEST: 'SYNC_REVIEW_LIST_REQUEST',
  SYNC_REVIEW_LIST_SUCCESS: 'SYNC_REVIEW_LIST_SUCCESS',
  SYNC_REVIEW_LIST_FAILURE: 'SYNC_REVIEW_LIST_FAILURE',

  SET_REVIEW_PREV_URL: 'SET_REVIEW_PREV_URL',
};
