import { setDownloadRequestValue } from "../constants";
import { authHeader, handleResponse } from "../helpers";

export const materialService = {
  materialList,
  useMaterialHeaderData,
  useMaterialListData,
  useMaterialDateListData,
  useMaterialTotalData,
  oneMaterialOfficeHeaderData,
  oneMaterialOfficeListData,
  oneMaterialDateListData,
  oneMaterialMenuHeaderData,
  oneMaterialMenuListData,
  useMaterialLately,
};

let abertControllers = {};

function materialList(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
    })
  };

  return fetch('/material/materialList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function useMaterialHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/pageExportUseMaterialView', requestOptions);

  return fetch('/material/useMaterialHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function useMaterialListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery, materialSnoQuery) {
  if (abertControllers['useMaterialListData']) {
    abertControllers['useMaterialListData'].abort();
  }
  abertControllers['useMaterialListData'] = new AbortController();
  let signal = abertControllers['useMaterialListData'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'limitStart': limitStart,
      'limitCount': limitCount,
      'sortKey': sortKey,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    }),
    signal
  };

  setDownloadRequestValue('/report/pageExportUseMaterialView', requestOptions);

  return fetch('/material/useMaterialListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function useMaterialDateListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialSnoQuery) {
  if (abertControllers['useMaterialDateListData']) {
    abertControllers['useMaterialDateListData'].abort();
  }
  abertControllers['useMaterialDateListData'] = new AbortController();
  let signal = abertControllers['useMaterialDateListData'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    }),
    signal
  };

  setDownloadRequestValue('/report/pageExportUseMaterialDateView', requestOptions);

  return fetch('/material/useMaterialDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function useMaterialTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
    })
  };

  return fetch('/material/useMaterialTotalData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMaterialOfficeHeaderData(userSno, brandCode, materialSno, startDate, endDate, serviceType, diffStartDate, diffEndDate, materialName, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'materialSno': materialSno,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'materialName': materialName,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/pageExportUseMaterialOfficeView', requestOptions);

  return fetch('/material/oneMaterialOfficeHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMaterialOfficeListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'materialSno': materialSno,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'officeSnoQuery': officeSnoQuery,
      'limitStart': limitStart,
      'limitCount': limitCount,
      'sortKey': sortKey,
    })
  };

  setDownloadRequestValue('/report/pageExportUseMaterialOfficeView', requestOptions);

  return fetch('/material/oneMaterialOfficeListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMaterialDateListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'materialSno': materialSno,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'officeSnoQuery': officeSnoQuery,
      'materialName': materialName,
    })
  };

  setDownloadRequestValue('/report/pageExportUseOneMaterialDateView', requestOptions);

  return fetch('/material/oneMaterialDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMaterialMenuHeaderData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, materialName, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'materialSno': materialSno,
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'materialName': materialName,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/pageExportUseOneMaterialMenuView', requestOptions);

  return fetch('/material/oneMaterialMenuHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMaterialMenuListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortName, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'materialSno': materialSno,
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'limitStart': limitStart,
      'limitCount': limitCount,
      'sortName': sortName,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/pageExportUseOneMaterialMenuView', requestOptions);

  return fetch('/material/oneMaterialMenuListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function useMaterialLately(userSno, brandCode, officeSnoQuery, materialSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    })
  };

  return fetch('/material/useMaterialLately', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}