/**
 * @module FormValidator
 */

const regexs = {
  num: /^[0-9]+$/,
  email: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
  phoneNum: /^01([0|1|6|7|8|9]?)?([0-9]{4})?([0-9]{4})$/,
  prePhoneNum: /^01([0|1|6|7|8|9]?)$/,
  engNum: /^[a-z|A-Z|0-9]/,
  koEngNum: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+$/,
  koEngNumSpace: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/,
  koEng: /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/,
  perfectKoEng: /^[가-힣|a-z|A-Z]+$/,
  businessNum: /^([0-9]{3})-([0-9]{2})-([0-9]{5})$/,
  fileName: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_\-.\s]+$/,
  creditCard: /^([34569][0-9]{3}[-][0-9]{4}[-][0-9]{4}[-][0-9]{4})+$/,
  pwd2Digit: /^([0-9]{2})$/,
  birth6Digit: /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/,
};

export const rules = {
  commonName: { min: 0, max: 20, regexp: regexs.koEngNum },
  commonNameSpace: { min: 0, max: 20, regexp: regexs.koEngNumSpace },
  phoneNum: { min: 0, max: 11, regexp: regexs.phoneNum },
  prePhoneNum: { min: 3, max: 3, regexp: regexs.prePhoneNum },
  password: { min: 5, max: 20 },
  num: { regexp: regexs.num },
  engNum: { regexp: regexs.engNum },
  koEng: { min: 0, max: 20, regexp: regexs.koEng },
  userName: { min: 1, max: 20, regexp: regexs.perfectKo },
  email: { min: 3, max: 254, regexp: regexs.email },
  businessNum: { min: 12, max: 12, regexp: regexs.businessNum },
  fileName: { min: 0, max: 100, regexp: regexs.fileName },
  creditCard: { regexp: regexs.creditCard },
  pwd2Digit: { regexp: regexs.pwd2Digit },
  birth6Digit: { regexp: regexs.birth6Digit },
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const validation = (text = '', rule) => {
  var length = text.length;
  if (rule.min && length < rule.min) {
    return false;
  }

  if (rule.max && length > rule.max) {
    return false;
  }

  if (text === '') {
    return true;
  }

  if (rule.regexp && rule.regexp.test(text) === false) {
    return false;
  }

  return true;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const isEmptyDate = date => {
  return date === '0000-00-00 00:00:00';
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const pressNumber = e => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);

  if (regexs.num.test(keyValue) === false) {
    e.preventDefault();
  }

  if (/\+|-|e|E/.test(keyValue)) {
    e.preventDefault();
  }
};
