import { getMyUserData, getUserIDAndLoginExpire, isRetail, removeLocalData } from './LoadData';

/**
 * @module AuthValidation
 */

const blockBrandKeyList = ['8baea55ff6366d8dd0604c74a50bc6d8'];

/**
 * @function
 * @description 앳트래커 trial사용에 대한 expireDate가 유효하지 않는 유저일 경우 로그인 화면으로 이동
 */
export const validLoginExpire = () => {
  var tmp = getUserIDAndLoginExpire();
  var date = tmp ? tmp['expireDate'] : undefined;
  if (!date) {
    return;
  }

  var now = new Date();
  var expire = new Date(date);

  if (now.getTime() >= expire.getTime()) {
    removeLocalData('token_' + tmp['userID']);
    window.location.href = '#/auth/login';
  }
};

/**
 * @function
 * @description 채널톡 미노출 여부 체크
 */
export const checkHiddenChannelChat = () => {
  var userData = getMyUserData(false);
  if (!userData) {
    return true;
  }

  // 특정키를 가지고있는 브랜드이며 자영업자인 경우에 노출하지 않음
  if (isRetail() && blockBrandKeyList.includes(userData['brand_key'])) {
    return false;
  }

  return true;
};
