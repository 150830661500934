import { authHeader, handleResponse } from '../helpers';

export const commonService = {
  reverseGeoCode,
  completeRequestGA,
};

function reverseGeoCode(x, y) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      x: x,
      y: y,
    }),
  };

  return fetch('/common/reverseGeoCode', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function completeRequestGA(userSno, code) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      code: code,
    }),
  };

  return fetch('/user/completeRequestGA', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
