export const paymentConstants = {
  RESETSTATE: 'RESET_STATE',
  GET_PAY_INFO_STATE: 'GET_PAY_INFO_STATE',
  GET_PAYMENT_TABLE_DATA: 'GET_PAYMENT_TABLE_DATA', // 연도별
  GET_PAYMETHOD_LIST: 'GET_PAYMETHOD_LIST',
  GET_FILTER_LIST: 'GET_FILTER_LIST',
  GET_FILTER_YEAR: 'GET_FILTER_YEAR',
  GET_FILTER_STORE_OR_PAYMENT_TEXT: 'GET_FILTER_STORE_OR_PAYMENT_TEXT',
  GET_MODILE_OWNER_INFO: 'GET_MODILE_OWNER_INFO',
  GET_MOBILE_OWNER_PAYMENT_INFO: 'GET_MOBILE_OWNER_PAYINFO',
  GET_MOBILE_OWNER_PAYBILL_LIST: 'GET_MOBILE_OWNER_PAYBILL_LIST',

  CREATE_PAYNETGID_ITEM: 'CREATE_PAYNETGID_ITEM',
  CREATE_MO_OWNER_PAYNET: 'CREATE_MO_OWNER_PAYNET',
  CREATE_MO_OWNER_PAYBILL_DATA: 'CREATE_MO_OWNER_PAYBILL_DATA',

  UPDATE_PAYMENT_TABLE_DATA: 'UPDATE_PAYMENT_TABLE_DATA',
  UPDATE_PAYMETHOD_LIST: 'UPDATE_PAYMETHOD_LIST',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD_SUCCESS: 'UPDATE_PAYMENT_METHOD_SUCCESS', // 점주, 본사 결제 방식변경
  UPDATE_PAYMENT_METHOD_INFO_SUCCESS: 'UPDATE_PAYMENT_METHOD_INFO_SUCCESS', // 결제수단 (카드정보) 변경
  UPDATE_PAYMENT_ID_SUCCESS: 'UPDATE_PAYMENT_ID_SUCCESS',
  UPDATE_STORELIST_PAYMETHOD: 'UPDATE_STORELIST_PAYMETHOD',
  UPDATE_MO_OWNER_PAYMETHOD: 'UPDATE_MO_OWNER_PAYMETHOD',
  UPDATE_STATIC_DATA: 'UPDATE_STATIC_DATA',

  SELECT_STORE_STEP: 'SELECT_STORE_STEP', //0
  SELECT_PAYMETHOD_STEP: 'SELECT_PAYMETHOD_STEP', //1
  CREATE_PAYMETHOD_STEP: 'SELECT_PAY_STEP', //2
  SELECT_PAY_STEP: 'SELECT_PAY_STEP', //3
  NEXT_PAY_STEP: 'CHANGE_PAY_STEP', // step+1
  RESET_STEP: 'RESET_STEP', // step = 0

  /* pc 검색창 reset, 검색어 입력시에 입력값 설정 */
  RESET_SEARCH_INPUT_VALUE: 'RESET_SEARCH_INPUT_VALUE',
  ON_SEARCH_INPUT_CHANGE: 'ON_SEARCH_INPUT_CHANGE',

  HANDLE_SELECT_STORE: 'HANDLE_SELECT_STORE',

  /* post success, fail */
  POST_SUCCESS: 'POST_SUCCESS',
  POST_RESET: 'POST_RESET',

  STOP_SERVICE: 'STOP_SERVICE',
  SELECT_BY_SEARCH_TEXT: 'SELECT_BY_SEARCH_TEXT',

  DELETE_PAYMETHOD_INFO: 'DELETE_PAYMETHOD_INFO',
  COUPON: 'AT6644856758',

  // payment request/response
  GET_MY_PAYMENT_REQUEST: 'GET_MY_PAYMENT_REQUEST',
  GET_MY_PAYMENT_SUCCESS: 'GET_MY_PAYMENT_SUCCESS',
  GET_MY_PAYMENT_FAILURE: 'GET_MY_PAYMENT_FAILURE',

  REGIST_PAYMENT_RESET: 'REGIST_PAYMENT_RESET',
  REGIST_PAYMENT_REQUEST: 'REGIST_PAYMENT_REQUEST',
  REGIST_PAYMENT_SUCCESS: 'REGIST_PAYMENT_SUCCESS',
  REGIST_PAYMENT_FAILURE: 'REGIST_PAYMENT_FAILURE',

  GET_MY_PAYMENT_HISTORY_REQUEST: 'GET_MY_PAYMENT_HISTORY_REQUEST',
  GET_MY_PAYMENT_HISTORY_SUCCESS: 'GET_MY_PAYMENT_HISTORY_SUCCESS',
  GET_MY_PAYMENT_HISTORY_FAILURE: 'GET_MY_PAYMENT_HISTORY_FAILURE',

  SINGLE_PAYMENT_RESET: 'SINGLE_PAYMENT_RESET',
  SINGLE_PAYMENT_REQUEST: 'SINGLE_PAYMENT_REQUEST',
  SINGLE_PAYMENT_SUCCESS: 'SINGLE_PAYMENT_SUCCESS',
  SINGLE_PAYMENT_FAILURE: 'SINGLE_PAYMENT_FAILURE',

  SET_PAYMENT_PLAN_REQUEST: 'SET_PAYMENT_PLAN_REQUEST',
  SET_PAYMENT_PLAN_SUCCESS: 'SET_PAYMENT_PLAN_SUCCESS',
  SET_PAYMENT_PLAN_FAILURE: 'SET_PAYMENT_PLAN_FAILURE',

  GET_PAYMENT_PLAN_REQUEST: 'GET_PAYMENT_PLAN_REQUEST',
  GET_PAYMENT_PLAN_SUCCESS: 'GET_PAYMENT_PLAN_SUCCESS',
  GET_PAYMENT_PLAN_FAILURE: 'GET_PAYMENT_PLAN_FAILURE',

  CHECK_USER_ORDER_APP: 'CHECK_USER_ORDER_APP',
};

// get payment info state for page
export const GET_MY_PAYMENT_NORMAL = 0; // 기본상태
export const GET_MY_PAYMENT_PROGRESS = 1; // 진행중
export const GET_MY_PAYMENT_SUCCESS = 2; // 성공
export const GET_MY_PAYMENT_FAIL = 3; // 실패
export const GET_MY_PAYMENT_FORBIDDEN = 4; // 권한 없음

// create payment info state for page
export const REGIST_PAYMENT_NORMAL = 0; // 기본상태
export const REGIST_PAYMENT_PROGRESS = 1; // 진행중
export const REGIST_PAYMENT_SUCCESS = 2; // 성공
export const REGIST_PAYMENT_FAIL = 3; // 실패

export const SINGLE_PAYMENT_NORMAL = 0; // 기본상태
export const SINGLE_PAYMENT_PROGRESS = 1; // 진행중
export const SINGLE_PAYMENT_SUCCESS = 2; // 성공
export const SINGLE_PAYMENT_FAIL = 3; // 실패

// 서비스 구분 등록 상태.
export const SET_PAYMENT_PLAN_NORMAL = 0; // 기본상태
export const SET_PAYMENT_PLAN_PROGRESS = 1; // 진행중
export const SET_PAYMENT_PLAN_SUCCESS = 2; // 성공
export const SET_PAYMENT_PLAN_FAIL = 3; // 실패
