export const menuConstants = {
  RESETSTATE: 'RESET_STATE',

  BRAND_MENU_LIST_REQUEST: 'BRAND_MENU_LIST_REQUEST',
  BRAND_MENU_LIST_SUCCESS: 'BRAND_MENU_LIST_SUCCESS',
  BRAND_MENU_LIST_FAILURE: 'BRAND_MENU_LIST_FAILURE',

  CATEGORY_LIST_REQUEST: 'CATEGORY_LIST_REQUEST',
  CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
  CATEGORY_LIST_FAILURE: 'CATEGORY_LIST_FAILURE',

  SALE_MENU_HEADER_DATA_REQUEST: 'SALE_MENU_HEADER_DATA_REQUEST',
  SALE_MENU_HEADER_DATA_SUCCESS: 'SALE_MENU_HEADER_DATA_SUCCESS',
  SALE_MENU_HEADER_DATA_FAILURE: 'SALE_MENU_HEADER_DATA_FAILURE',

  SALE_MENU_LIST_DATA_RESET: 'SALE_MENU_LIST_DATA_RESET',
  SALE_MENU_LIST_DATA_REQUEST: 'SALE_MENU_LIST_DATA_REQUEST',
  SALE_MENU_LIST_DATA_SUCCESS: 'SALE_MENU_LIST_DATA_SUCCESS',
  SALE_MENU_LIST_DATA_FAILURE: 'SALE_MENU_LIST_DATA_FAILURE',

  MENU_DATE_LIST_DATA_RESET: 'MENU_DATE_LIST_DATA_RESET',
  MENU_DATE_LIST_DATA_REQUEST: 'MENU_DATE_LIST_DATA_REQUEST',
  MENU_DATE_LIST_DATA_SUCCESS: 'MENU_DATE_LIST_DATA_SUCCESS',
  MENU_DATE_LIST_DATA_FAILURE: 'MENU_DATE_LIST_DATA_FAILURE',

  DELIVERY_MENU_HEADER_DATA_RESET: 'DELIVERY_MENU_HEADER_DATA_RESET',
  DELIVERY_MENU_HEADER_DATA_REQUEST: 'DELIVERY_MENU_HEADER_DATA_REQUEST',
  DELIVERY_MENU_HEADER_DATA_SUCCESS: 'DELIVERY_MENU_HEADER_DATA_SUCCESS',
  DELIVERY_MENU_HEADER_DATA_FAILURE: 'DELIVERY_MENU_HEADER_DATA_FAILURE',

  DELIVERY_MENU_LIST_DATA_RESET: 'DELIVERY_MENU_LIST_DATA_RESET',
  DELIVERY_MENU_LIST_DATA_REQUEST: 'DELIVERY_MENU_LIST_DATA_REQUEST',
  DELIVERY_MENU_LIST_DATA_SUCCESS: 'DELIVERY_MENU_LIST_DATA_SUCCESS',
  DELIVERY_MENU_LIST_DATA_FAILURE: 'DELIVERY_MENU_LIST_DATA_FAILURE',

  DELIVERY_MENU_OPTION_LIST_DATA_RESET: 'DELIVERY_MENU_OPTION_LIST_DATA_RESET',
  DELIVERY_MENU_OPTION_LIST_DATA_REQUEST: 'DELIVERY_MENU_OPTION_LIST_DATA_REQUEST',
  DELIVERY_MENU_OPTION_LIST_DATA_SUCCESS: 'DELIVERY_MENU_OPTION_LIST_DATA_SUCCESS',
  DELIVERY_MENU_OPTION_LIST_DATA_FAILURE: 'DELIVERY_MENU_OPTION_LIST_DATA_FAILURE',

  ALL_MENU_ENGINEERING_REQUEST: 'ALL_MENU_ENGINEERING_REQUEST',
  ALL_MENU_ENGINEERING_SUCCESS: 'ALL_MENU_ENGINEERING_SUCCESS',
  ALL_MENU_ENGINEERING_FAILURE: 'ALL_MENU_ENGINEERING_FAILURE',

  MENU_ENGINEERING_OFFICE_REQUEST: 'MENU_ENGINEERING_OFFICE_REQUEST',
  MENU_ENGINEERING_OFFICE_SUCCESS: 'MENU_ENGINEERING_OFFICE_SUCCESS',
  MENU_ENGINEERING_OFFICE_FAILURE: 'MENU_ENGINEERING_OFFICE_FAILURE',

  ONE_MENU_OFFICE_HEADER_DATA_REQUEST: 'ONE_MENU_OFFICE_HEADER_DATA_REQUEST',
  ONE_MENU_OFFICE_HEADER_DATA_SUCCESS: 'ONE_MENU_OFFICE_HEADER_DATA_SUCCESS',
  ONE_MENU_OFFICE_HEADER_DATA_FAILURE: 'ONE_MENU_OFFICE_HEADER_DATA_FAILURE',

  ONE_MENU_OFFICE_LIST_DATA_RESET: 'ONE_MENU_OFFICE_LIST_DATA_RESET',
  ONE_MENU_OFFICE_LIST_DATA_REQUEST: 'ONE_MENU_OFFICE_LIST_DATA_REQUEST',
  ONE_MENU_OFFICE_LIST_DATA_SUCCESS: 'ONE_MENU_OFFICE_LIST_DATA_SUCCESS',
  ONE_MENU_OFFICE_LIST_DATA_FAILURE: 'ONE_MENU_OFFICE_LIST_DATA_FAILURE',

  ONE_MENU_DATE_LIST_DATA_RESET: 'ONE_MENU_DATE_LIST_DATA_RESET',
  ONE_MENU_DATE_LIST_DATA_REQUEST: 'ONE_MENU_DATE_LIST_DATA_REQUEST',
  ONE_MENU_DATE_LIST_DATA_SUCCESS: 'ONE_MENU_DATE_LIST_DATA_SUCCESS',
  ONE_MENU_DATE_LIST_DATA_FAILURE: 'ONE_MENU_DATE_LIST_DATA_FAILURE',

  ONE_MENU_SALE_WEEK_DAY_LIST_DATA_RESET: 'ONE_MENU_SALE_WEEK_DAY_LIST_DATA_RESET',
  ONE_MENU_SALE_WEEK_DAY_LIST_DATA_REQUEST: 'ONE_MENU_SALE_WEEK_DAY_LIST_DATA_REQUEST',
  ONE_MENU_SALE_WEEK_DAY_LIST_DATA_SUCCESS: 'ONE_MENU_SALE_WEEK_DAY_LIST_DATA_SUCCESS',
  ONE_MENU_SALE_WEEK_DAY_LIST_DATA_FAILURE: 'ONE_MENU_SALE_WEEK_DAY_LIST_DATA_FAILURE',

  ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_RESET: 'ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_RESET',
  ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST: 'ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST',
  ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS: 'ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS',
  ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE: 'ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE',

  MAIN_MENU_DATA_REQUEST: 'MAIN_MENU_DATA_REQUEST',
  MAIN_MENU_DATA_SUCCESS: 'MAIN_MENU_DATA_SUCCESS',
  MAIN_MENU_DATA_FAILURE: 'MAIN_MENU_DATA_FAILURE',

  SALE_MENU_TOTAL_DATA_RESET: 'SALE_MENU_TOTAL_DATA_RESET',
  SALE_MENU_TOTAL_DATA_REQUEST: 'SALE_MENU_TOTAL_DATA_REQUEST',
  SALE_MENU_TOTAL_DATA_SUCCESS: 'SALE_MENU_TOTAL_DATA_SUCCESS',
  SALE_MENU_TOTAL_DATA_FAILURE: 'SALE_MENU_TOTAL_DATA_FAILURE',

  EDIT_CATEGORY_NAME_RESET: 'EDIT_CATEGORY_NAME_RESET',
  EDIT_CATEGORY_NAME_REQUEST: 'EDIT_CATEGORY_NAME_REQUEST',
  EDIT_CATEGORY_NAME_SUCCESS: 'EDIT_CATEGORY_NAME_SUCCESS',
  EDIT_CATEGORY_NAME_FAILURE: 'EDIT_CATEGORY_NAME_FAILURE',

  DISABLE_BRAND_MENU_RESET: 'DISABLE_BRAND_MENU_RESET',
  DISABLE_BRAND_MENU_REQUEST: 'DISABLE_BRAND_MENU_REQUEST',
  DISABLE_BRAND_MENU_SUCCESS: 'DISABLE_BRAND_MENU_SUCCESS',
  DISABLE_BRAND_MENU_FAILURE: 'DISABLE_BRAND_MENU_FAILURE',

  UNLINK_MENU_RESET: 'UNLINK_MENU_RESET',
  UNLINK_MENU_REQUEST: 'UNLINK_MENU_REQUEST',
  UNLINK_MENU_SUCCESS: 'UNLINK_MENU_SUCCESS',
  UNLINK_MENU_FAILURE: 'UNLINK_MENU_FAILURE',

  EDIT_BRAND_MENU_RESET: 'EDIT_BRAND_MENU_RESET',
  EDIT_BRAND_MENU_REQUEST: 'EDIT_BRAND_MENU_REQUEST',
  EDIT_BRAND_MENU_SUCCESS: 'EDIT_BRAND_MENU_SUCCESS',
  EDIT_BRAND_MENU_FAILURE: 'EDIT_BRAND_MENU_FAILURE',

  MENU_LAST_RECEIPT_RESET: 'MENU_LAST_RECEIPT_RESET',
  MENU_LAST_RECEIPT_REQUEST: 'MENU_LAST_RECEIPT_REQUEST',
  MENU_LAST_RECEIPT_SUCCESS: 'MENU_LAST_RECEIPT_SUCCESS',
  MENU_LAST_RECEIPT_FAILURE: 'MENU_LAST_RECEIPT_FAILURE',

  RECOMMEND_UNLINK_MENU_DATA_REQUEST: 'RECOMMEND_UNLINK_MENU_DATA_REQUEST',
  RECOMMEND_UNLINK_MENU_DATA_SUCCESS: 'RECOMMEND_UNLINK_MENU_DATA_SUCCESS',
  RECOMMEND_UNLINK_MENU_DATA_FAILURE: 'RECOMMEND_UNLINK_MENU_DATA_FAILURE',

  REGIST_BRAND_MENU_RESET: 'REGIST_BRAND_MENU_RESET',
  REGIST_BRAND_MENU_REQUEST: 'REGIST_BRAND_MENU_REQUEST',
  REGIST_BRAND_MENU_SUCCESS: 'REGIST_BRAND_MENU_SUCCESS',
  REGIST_BRAND_MENU_FAILURE: 'REGIST_BRAND_MENU_FAILURE',

  DELETE_CATEGORY_RESET: 'DELETE_CATEGORY_RESET',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

  DISABLE_RETAIL_MENU_RESET: 'DISABLE_RETAIL_MENU_RESET',
  DISABLE_RETAIL_MENU_REQUEST: 'DISABLE_RETAIL_MENU_REQUEST',
  DISABLE_RETAIL_MENU_SUCCESS: 'DISABLE_RETAIL_MENU_SUCCESS',
  DISABLE_RETAIL_MENU_FAILURE: 'DISABLE_RETAIL_MENU_FAILURE',

  MENU_SALES_RESET: 'MENU_SALES_RESET',
  MENU_SALES_REQUEST: 'MENU_SALES_REQUEST',
  MENU_SALES_SUCCESS: 'MENU_SALES_SUCCESS',
  MENU_SALES_FAILURE: 'MENU_SALES_FAILURE',

  DIFF_MENU_SALES_RESET: 'DIFF_MENU_SALES_RESET',
  DIFF_MENU_SALES_REQUEST: 'DIFF_MENU_SALES_REQUEST',
  DIFF_MENU_SALES_SUCCESS: 'DIFF_MENU_SALES_SUCCESS',
  DIFF_MENU_SALES_FAILURE: 'DIFF_MENU_SALES_FAILURE',

  MENU_SALES_BY_DAILY_RESET: 'MENU_SALES_BY_DAILY_RESET',
  MENU_SALES_BY_DAILY_REQUEST: 'MENU_SALES_BY_DAILY_REQUEST',
  MENU_SALES_BY_DAILY_SUCCESS: 'MENU_SALES_BY_DAILY_SUCCESS',
  MENU_SALES_BY_DAILY_FAILURE: 'MENU_SALES_BY_DAILY_FAILURE',

  OPTION_MENU_SALES_RESET: 'OPTION_MENU_SALES_RESET',
  OPTION_MENU_SALES_REQUEST: 'OPTION_MENU_SALES_REQUEST',
  OPTION_MENU_SALES_SUCCESS: 'OPTION_MENU_SALES_SUCCESS',
  OPTION_MENU_SALES_FAILURE: 'OPTION_MENU_SALES_FAILURE',

  DIFF_OPTION_MENU_SALES_RESET: 'DIFF_OPTION_MENU_SALES_RESET',
  DIFF_OPTION_MENU_SALES_REQUEST: 'DIFF_OPTION_MENU_SALES_REQUEST',
  DIFF_OPTION_MENU_SALES_SUCCESS: 'DIFF_OPTION_MENU_SALES_SUCCESS',
  DIFF_OPTION_MENU_SALES_FAILURE: 'DIFF_OPTION_MENU_SALES_FAILURE',

  ONE_MENU_OPTIONS_RESET: 'ONE_MENU_OPTIONS_RESET',
  ONE_MENU_OPTIONS_REQUEST: 'ONE_MENU_OPTIONS_REQUEST',
  ONE_MENU_OPTIONS_SUCCESS: 'ONE_MENU_OPTIONS_SUCCESS',
  ONE_MENU_OPTIONS_FAILURE: 'ONE_MENU_OPTIONS_FAILURE',

  ONE_OPTION_MENU_OPTIONS_RESET: 'ONE_OPTION_MENU_OPTIONS_RESET',
  ONE_OPTION_MENU_OPTIONS_REQUEST: 'ONE_OPTION_MENU_OPTIONS_REQUEST',
  ONE_OPTION_MENU_OPTIONS_SUCCESS: 'ONE_OPTION_MENU_OPTIONS_SUCCESS',
  ONE_OPTION_MENU_OPTIONS_FAILURE: 'ONE_OPTION_MENU_OPTIONS_FAILURE',

  ONE_MENU_OFFICES_RESET: 'ONE_MENU_OFFICES_RESET',
  ONE_MENU_OFFICES_REQUEST: 'ONE_MENU_OFFICES_REQUEST',
  ONE_MENU_OFFICES_SUCCESS: 'ONE_MENU_OFFICES_SUCCESS',
  ONE_MENU_OFFICES_FAILURE: 'ONE_MENU_OFFICES_FAILURE',

  DIFF_ONE_MENU_OFFICES_RESET: 'DIFF_ONE_MENU_OFFICES_RESET',
  DIFF_ONE_MENU_OFFICES_REQUEST: 'DIFF_ONE_MENU_OFFICES_REQUEST',
  DIFF_ONE_MENU_OFFICES_SUCCESS: 'DIFF_ONE_MENU_OFFICES_SUCCESS',
  DIFF_ONE_MENU_OFFICES_FAILURE: 'DIFF_ONE_MENU_OFFICES_FAILURE',

  MENU_SALES_SUM_BY_DAILY_RESET: 'MENU_SALES_SUM_BY_DAILY_RESET',
  MENU_SALES_SUM_BY_DAILY_REQUEST: 'MENU_SALES_SUM_BY_DAILY_REQUEST',
  MENU_SALES_SUM_BY_DAILY_SUCCESS: 'MENU_SALES_SUM_BY_DAILY_SUCCESS',
  MENU_SALES_SUM_BY_DAILY_FAILURE: 'MENU_SALES_SUM_BY_DAILY_FAILURE',

  OPTION_MENU_SALES_SUM_BY_DAILY_RESET: 'OPTION_MENU_SALES_SUM_BY_DAILY_RESET',
  OPTION_MENU_SALES_SUM_BY_DAILY_REQUEST: 'OPTION_MENU_SALES_SUM_BY_DAILY_REQUEST',
  OPTION_MENU_SALES_SUM_BY_DAILY_SUCCESS: 'OPTION_MENU_SALES_SUM_BY_DAILY_SUCCESS',
  OPTION_MENU_SALES_SUM_BY_DAILY_FAILURE: 'OPTION_MENU_SALES_SUM_BY_DAILY_FAILURE',

  CATEGORY_SALES_RESET: 'CATEGORY_SALES_RESET',
  CATEGORY_SALES_REQUEST: 'CATEGORY_SALES_REQUEST',
  CATEGORY_SALES_SUCCESS: 'CATEGORY_SALES_SUCCESS',
  CATEGORY_SALES_FAILURE: 'CATEGORY_SALES_FAILURE',

  ONE_CATEGORY_OFFICES_RESET: 'ONE_CATEGORY_OFFICES_RESET',
  ONE_CATEGORY_OFFICES_REQUEST: 'ONE_CATEGORY_OFFICES_REQUEST',
  ONE_CATEGORY_OFFICES_SUCCESS: 'ONE_CATEGORY_OFFICES_SUCCESS',
  ONE_CATEGORY_OFFICES_FAILURE: 'ONE_CATEGORY_OFFICES_FAILURE',

  SET_TAB_STATE: 'SET_TAB_STATE',
  SET_PREV_TAB_STATE: 'SET_PREV_TAB_STATE',

  GET_SUGGEST_MENUS_RESET: 'GET_SUGGEST_MENUS_RESET',
  GET_SUGGEST_MENUS_REQUEST: 'GET_SUGGEST_MENUS_REQUEST',
  GET_SUGGEST_MENUS_SUCCESS: 'GET_SUGGEST_MENUS_SUCCESS',
  GET_SUGGEST_MENUS_FAILURE: 'GET_SUGGEST_MENUS_FAILURE',
};
