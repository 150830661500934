import { sha256 } from 'js-sha256';
import querystring from 'querystring';
import React from 'react';
import { MATERIAL_ICON, MENU_ICON } from '../constants';
import { getLocale } from '../locales';
import { deepClone, groupCount, groupKeys } from './DataMapping';
import { dateDiff, dateToStr, dateToStr2 } from './DateConverter';
import { getMyUserData } from './LoadData';
import { mComma, replaceBrandName } from './StrConverter';

/**
 * @module Converter
 */

/**
 * @function
 * @description 백분율을 구하는 함수
 * @param {*} portion 분자
 * @param {*} total 분모
 * @param {*} toFixed 부동소수점 자릿수 사용 여부
 */
export const comparePercent = (portion, total, toFixed = 1) => {
  if (total === 0) {
    return 0;
  }

  var result = ((portion / total) * 100).toFixed(toFixed);
  if (isNaN(result) === true) {
    return 0;
  }

  if (result === 'Infinity') {
    return 100;
  }

  if (result === '100.0' || result === '0.0') {
    return result.replace('.0', '');
  }

  return result;
};

/**
 * @function
 * @description disable_ymd 날짜 이후부터 데이터에서 hide 데이터 체크
 * @param {*} obj office, menu, material
 * @param {*} dateCursor
 * @param {*} dateValue
 */
export const isDisableConts = (obj, dateCursor, dateValue = '') => {
  var result = false;

  if (!obj) {
    return result;
  }

  switch (dateCursor) {
    case 1:
      result = obj['disable_ymd'] && obj['disable_ymd'] < dateValue.slice(0, 10); // 2020-11-20 (0,10)?
      break;
    case 2:
      result = obj['disable_ymd'] && obj['disable_ymd'] < dateValue; // 데이터의 날짜가 param날짜보다 큰 경우
      break;
    case 3:
      result = obj['disable_week'] && obj['disable_week'] < dateValue; // 데이터의 주 수가 param날짜보다 큰 경우 - 언제 사용?
      break;
    case 4:
      result = obj['disable_ymd'] && obj['disable_ymd'].slice(0, 7) < dateValue; // 20-11-20 (0,7)?
      break;
    case 5:
      result = obj['disable_ymd'] && obj['disable_ymd'] < dateValue;
      break;
    default:
      result = obj['disable_ymd'] !== null;
      break;
  }

  return result;
};

/**
 * @function
 * @description 날짜에 따라 hide된 데이터를 제외하고 출력하는 함수
 * @param {*} list officeList, menuList, materialList
 * @param {*} boolean 데이터 모두 show/hide 대한 값
 * @return {Array}
 */
export const filterDisableConts = (list, boolean = false) => {
  if (!list) {
    return [];
  }

  return list.filter(item => isDisableConts(item) === boolean);
};

/**
 * @function
 * @description 날짜에 따라 데이터 show/hide 여부를 필터하여 리턴하는 함수
 * @param {Array} list 데이터 리스트
 * @param {String} ymd
 * @return show할 데이터 또는 주어진 날짜 이후의 값들만 출력
 */
export const filterDisableContsDate = (list, ymd) => {
  var date = dateToStr(ymd); // dateToStr: moment객체(YYYY-mm-dd) 리턴
  return list.filter(item => isDisableConts(item) === false || item['disable_ymd'] >= date);
};

/**
 * @function
 * @description 날짜에 따라 데이터 show/hide 여부를 필터하여 리턴하는 함수
 * @param {Array} list 데이터 리스트
 * @param {String} ymd
 * @return show할 데이터 또는 주어진 날짜 이후의 값들만 출력
 */
export const menuEngineeringString = (analysisData, analysisIndex) => {
  if (
    !analysisIndex.averagePopularityIndex ||
    !analysisIndex.contributionMarginIndex ||
    !analysisIndex.totalSaleCount ||
    !analysisData['cm'] ||
    !analysisData['sale_menu_cnt']
  ) {
    // 분석할 데이터가 쌓일 만큼의 데이터가 없을 경우 'Dog'으로 출력
    return 'Dog';
  }

  var cmIndex = analysisIndex.contributionMarginIndex;
  var apIndex = analysisIndex.averagePopularityIndex;
  var itemCm = analysisData['cm'];
  var itemAp = (analysisData['sale_menu_cnt'] / analysisIndex.totalSaleCount) * 100;

  switch (true) {
    case itemCm >= cmIndex && itemAp >= apIndex:
      return 'Star';
    case itemCm < cmIndex && itemAp >= apIndex:
      return 'Plowhorse';
    case itemCm >= cmIndex && itemAp < apIndex:
      return 'Puzzle';
    case itemCm < cmIndex && itemAp < apIndex:
      return 'Dog';
    default:
      break;
  }

  return '';
};

/**
 * @function
 * @description 설명추가
 * @param {Array} filterMenu
 * @param {String} analysisData
 * @return {Object}
 */
export const getAnalysisIndex = (filterMenu, analysisData) => {
  var menuCount = groupCount(filterMenu, 'brand_menu_sno');

  var averagePopularityIndex = 0;
  var contributionMarginIndex = 0;
  var totalCM = 0;
  var totalSaleCount = 0;

  if (analysisData.length > 0) {
    analysisData.map(item => {
      if (item['sale_menu_cnt'] <= 0 && item['cost_price'] === 0) {
        return null;
      }

      totalCM += item['cm'] * item['sale_menu_cnt'];
      return null;
    });

    totalSaleCount = analysisData.map(item => item['sale_menu_cnt']).reduce((a, c) => a + c);
  }

  if (menuCount > 0) {
    averagePopularityIndex = ((100 / menuCount) * 0.7).toFixed(2);
  }

  if (totalSaleCount > 0) {
    contributionMarginIndex = (totalCM / totalSaleCount).toFixed(2);
  }

  return {
    averagePopularityIndex: averagePopularityIndex,
    contributionMarginIndex: contributionMarginIndex,
    menuCount: menuCount,
    totalSaleCount: totalSaleCount,
  };
};

export const groupMenuAnalysis = data => {
  var result = [];
  var tmp = {};

  data.map(analysis => {
    var key = analysis['menu_sno'];
    if (tmp[key]) {
      tmp[key]['avg_price'] += analysis['avg_price'];
      tmp[key]['cm'] += analysis['cm'];
      tmp[key]['sale_menu_price'] += analysis['sale_menu_price'];
      tmp[key]['sale_menu_cnt'] += analysis['sale_menu_cnt'];
      tmp[key]['cost_price'] += analysis['cost_price'];
      tmp[key]['tmp_count'] += 1;
    } else {
      tmp[key] = deepClone(analysis);
      tmp[key]['tmp_count'] = 1;
    }

    return null;
  });

  result = Object.values(tmp);

  result.map(item => {
    item['cost_price'] = parseInt(item['cost_price'] / item['tmp_count']);
    item['cm'] = Math.ceil(item['sale_menu_price'] / item['sale_menu_cnt'] - item['cost_price']);

    return null;
  });

  return result;
};

/**
 * @function
 * @description 네이버지도뷰 첫 로드시 보여질 중심좌표를 '바운다리' 기준으로 계산하여 리턴하는 함수
 */
export const getInitialBounds = (list, xKey = 'map_x', yKey = 'map_y', correction = true) => {
  if (list.length === 1) {
    return { lng: list[0][xKey], lat: list[0][yKey] };
  }

  var tmpList = list.filter(item => item[xKey] && item[yKey]);
  var result = {
    east: Math.max.apply(
      Math,
      tmpList.map(item => item[xKey]),
    ),
    west: Math.min.apply(
      Math,
      tmpList.map(item => item[xKey]),
    ),
    south: Math.min.apply(
      Math,
      tmpList.map(item => item[yKey]),
    ),
    north: Math.max.apply(
      Math,
      tmpList.map(item => item[yKey]),
    ),
  };

  // 마커가 맵안에 다 들어올수 있도록 좌표에 보정값 적용
  if (correction) {
    var corrections = [1.00005, 0.99995, 0.99999, 1.00085];
    result['east'] = result['east'] * corrections[0];
    result['west'] = result['west'] * corrections[1];
    result['south'] = result['south'] * corrections[2];
    result['north'] = result['north'] * corrections[3];
  }

  return result;
};

/**
 * @function
 * @description 선택매장의 주문발생 최대거리 반경에 따른 네이버지도 zoom레벨 리턴
 */
export const radiusConvertZoomLevel = (radius = 0) => {
  let zoom = 14;

  // 13.3인치 모니터(2560*1600) 기준, 가장 먼 주문마커가 잘리면 유저가 줌아웃을 한번은 해야하므로, 조금이라도 잘리지 않게 zoom 조정
  switch (true) {
    case radius <= 0:
      zoom = 14;
      break;
    case radius <= 900:
      zoom = 16;
      break;
    case radius > 900 && radius <= 1400:
      zoom = 15;
      break;
    case radius > 1400 && radius <= 2880:
      zoom = 14;
      break;
    case radius > 2880 && radius <= 6100:
      zoom = 13;
      break;
    case radius > 6100 && radius <= 12000:
      zoom = 12;
      break;
    case radius > 12000: // 목동현대타워
      zoom = 11;
      break;
    default:
      zoom = 13;
      break;
  }

  return zoom;
};

/**
 * @function
 * @description 선택매장에서 주문발생한 최대거리 반경값 리턴
 */
export const getDistance = (lat1, lng1, lat2, lng2) => {
  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return parseInt(d * 1000 * 100) / 100;
};

/**
 *
 * @description (배달지표) 뷰타입 변경 시 해당 키에 대한 값으로 전환하여 수치 리턴
 * @param {string} viewType : 1(주문건), 2(주문고객), 3(주문액)
 * @param {string} key : total, re, new, unverified,
 * @param {string} strType : key, title, suffix
 */
export const getDataKeyByViewType = (viewType, key = '', strType = 'key', countType = 0) => {
  // keys : total, re, new, unverified
  // strType : key, title, suffix, lately, diff

  if (viewType === 1) {
    if (key === 'total') {
      switch (strType) {
        case 'key':
          return 'order_customer';
        case 'title':
          return '전체주문';
        case 'suffix':
          return countType === 0 ? '건' : '개';
        case 'lately':
          return 'lately_order';
        case 'diff':
          return 'diff_order_customer';
        default:
          return null;
      }
    }
    if (key === 're') {
      switch (strType) {
        case 'key':
          return 're_order_customer';
        case 'title':
          return '재주문';
        case 'suffix':
          return countType === 0 ? '건' : '개';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
    if (key === 'new') {
      switch (strType) {
        case 'key':
          return 'new_order_customer';
        case 'title':
          return '신규주문';
        case 'suffix':
          return countType === 0 ? '건' : '개';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
    if (key === 'unverified') {
      switch (strType) {
        case 'key':
          return 'unverified_order_customer';
        case 'title':
          return '주소없음';
        case 'suffix':
          return countType === 0 ? '건' : '개';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
  } else if (viewType === 2) {
    if (key === 'total') {
      switch (strType) {
        case 'key':
          return 'customer_cnt';
        case 'title':
          return '전체주문고객수';
        case 'suffix':
          return '명';
        case 'lately':
          return 'lately_customer';
        case 'diff':
          return 'diff_customer_cnt';
        default:
          return null;
      }
    }
    if (key === 're') {
      switch (strType) {
        case 'key':
          return 're_customer_cnt';
        case 'title':
          return '재주문고객수';
        case 'suffix':
          return '명';
        case 'lately':
          return 'lately_customer';
        default:
          return null;
      }
    }
    if (key === 'new') {
      switch (strType) {
        case 'key':
          return 'new_customer_cnt';
        case 'title':
          return '신규주문고객수';
        case 'suffix':
          return '명';
        case 'lately':
          return 'lately_customer';
        default:
          return null;
      }
    }
    if (key === 'unverified') {
      switch (strType) {
        case 'key':
          return 'unverified_customer_cnt';
        case 'title':
          return '주소없음';
        case 'suffix':
          return '명';
        case 'lately':
          return 'lately_customer';
        default:
          return null;
      }
    }
  } else if (viewType === 3) {
    if (key === 'total') {
      switch (strType) {
        case 'key':
          return 'order_sale';
        case 'title':
          return '전체주문액';
        case 'suffix':
          return '원';
        case 'lately':
          return 'lately_order';
        case 'diff':
          return 'diff_order_sale';
        default:
          return null;
      }
    }
    if (key === 're') {
      switch (strType) {
        case 'key':
          return 're_order_sale';
        case 'title':
          return '재주문액';
        case 'suffix':
          return '원';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
    if (key === 'new') {
      switch (strType) {
        case 'key':
          return 'new_order_sale';
        case 'title':
          return '신규주문액';
        case 'suffix':
          return '원';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
    if (key === 'unverified') {
      switch (strType) {
        case 'key':
          return 'unverified_order_sale';
        case 'title':
          return '주소없음';
        case 'suffix':
          return '원';
        case 'lately':
          return 'lately_order';
        default:
          return null;
      }
    }
  }

  return null;
};

export const updatePageTitle = (includeBrand = true) => {
  var title = getPageTitle(includeBrand);
  if (document.title !== title) {
    document.title = title;
  }
};

export const getPageTitle = (includeBrand = true, subTitle = true) => {
  var title = '';
  var userData = getMyUserData(false);
  if (userData && userData['brand_name'] && includeBrand === true) {
    title = userData['brand_name'] + ' - 앳트래커' + (subTitle === true ? ' :: 매장 매출 통합 관리' : '');
  } else {
    title = '앳트래커' + (subTitle === true ? ' :: 매장 매출 통합 관리' : '');
  }

  return title;
};

/**
 * @description 사이드바에서 [메뉴관리]항목 dimmed처리
 */
export const dimmedMenu = () => {
  var menuDocument = document.getElementsByClassName('metismenu-icon ' + MENU_ICON);
  if (menuDocument && menuDocument[0]) {
    menuDocument[0].parentElement.style.backgroundColor = '#ffffff';
    menuDocument[0].parentElement.parentElement.style.opacity = '0.3';
    menuDocument[0].parentElement.parentElement.style.pointerEvents = 'none';
  }
};

/**
 * @description 사이드바에서 [원부자재 관리]항목 dimmed처리
 */
export const dimmedMaterial = () => {
  var materialDocument = document.getElementsByClassName('metismenu-icon ' + MATERIAL_ICON);
  if (materialDocument && materialDocument[0]) {
    materialDocument[0].parentElement.style.backgroundColor = '#ffffff';
    materialDocument[0].parentElement.parentElement.style.opacity = '0.3';
    materialDocument[0].parentElement.parentElement.style.pointerEvents = 'none';
  }
};

/**
 * @description 마스터관리자 계정(자영업자 타입으로 가입되는 현항 이후 노출될 로직)에서 매장을 등록한 경우가 없을 때에 대한 처리 (예 - 막 가입하였음)
 */
export const dimmedStarterSideMenus = () => {
  var targetDocument = document.getElementsByClassName('metismenu-icon');

  targetDocument.forEach(element => {
    if (element.className !== 'metismenu-icon pe-7s-map-marker') {
      element.parentElement.style.backgroundColor = '#ffffff';
      element.parentElement.parentElement.style.opacity = '0.3';
      element.parentElement.parentElement.style.pointerEvents = 'none';
    }
  });
};

/**
 * @description 매장의 권역 데이터 리턴
 */
export const getAreaOfficeList = (officeList, areaList) => {
  var filterdContent = [];
  var managedOfficeList = filterDisableConts(officeList);

  var content = [];

  // 미지정 매장
  var filteredOffice = managedOfficeList.filter(item => !item['area_id']);

  filteredOffice.map(item => {
    filterdContent.push({
      name: replaceBrandName(item['partner_name']),
      officeSno: item['office_sno'],
    });
    return null;
  });

  if (filteredOffice.length > 0) {
    content.push({
      label: '미지정 매장',
      content: filterdContent,
    });
  }

  // 지점 카테고리
  areaList &&
    areaList.map(item => {
      filterdContent = [];
      filteredOffice = managedOfficeList.filter(office => office['area_id'] === item['area_code']);
      filteredOffice.map(item => {
        filterdContent.push({
          name: replaceBrandName(item['partner_name']),
          officeSno: item['office_sno'],
        });
        return null;
      });

      content.push({
        label: item['area_name'],
        content: filterdContent,
      });

      return null;
    });

  return content;
};

// months-year text를 year-months로 변경
export const updatePickerHeader = () => {
  if (getLocale() !== 'ko') {
    return;
  }

  var tags = document.getElementsByClassName('react-datepicker__current-month');
  var text = tags && tags[0] && tags[0].innerHTML;

  if (text && text.indexOf('년') === -1) {
    var textSplit = text.split(' ');
    tags[0].innerHTML = textSplit[1] + '년 ' + textSplit[0];
  }
};

export const calculateTicks = (min, max, tickCount) => {
  var span = max - min,
    step = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10)),
    err = (tickCount / span) * step;

  // Filter ticks to get closer to the desired count.
  if (err <= 0.15) step *= 10;
  else if (err <= 0.35) step *= 5;
  else if (err <= 0.75) step *= 2;

  // Round start and stop values to step interval.
  var tstart = Math.ceil(min / step) * step,
    tstop = Math.floor(max / step) * step + step * 0.5,
    ticks = [];

  // now generate ticks
  for (var i = tstart; i < tstop; i += step) {
    ticks.push(i);
  }
  return ticks;
};

// 단위(11:mg, 12:g, 13:kg, 14:t, 21:cc, 22:ml, 23:l, 24:dl)
// 수량(1:ea, 2:box, 3:pack, 4:묶음)
export const materialUnitString = material => {
  var result = {
    minValueStr: '',
    unitCount: '',
    unitPerSalePrice: '',
    unitWholeSalePrice: '',
    unitPerSalePrice2: '',
    unitWholeSalePrice2: '',
    perSalePrice: '',
    perWholeSalePrice: '',
    perRealWholeSalePrice: 0,
    perRealSalePrice: 0,
  };

  if (!material || !material['material_unit']) {
    return result;
  }

  const MG = 11;
  const G = 12;
  const KG = 13;
  const T = 14;

  const CC = 21;
  const ML = 22;
  const L = 23;
  const DL = 24;

  const COUNT = 31;

  const EA = 1;
  const BOX = 2;
  const PACK = 3;
  const COMBINE = 4;

  const unitType = {
    [MG]: 'mg',
    [G]: 'g',
    [KG]: 'kg',
    [T]: 't',
    [CC]: 'cc',
    [ML]: 'ml',
    [L]: 'l',
    [DL]: 'dl',
    [COUNT]: '',
  };

  const quantityType = {
    [EA]: 'ea',
    [BOX]: 'box',
    [PACK]: 'pack',
    [COMBINE]: '묶음',
  };

  // 수량 표시
  // ex : 5g x 100ea x 10pack
  var strCount = material['material_count'] ? String(material['material_count']) : '';
  result.unitCount = strCount + String(unitType[material['material_unit']]);

  if (material['material_quantity_count1'] && material['material_quantity_unit1']) {
    result.unitCount += result.unitCount.length <= 0 ? '' : ' x ';
    result.unitCount +=
      String(material['material_quantity_count1']) + quantityType[material['material_quantity_unit1']];
  }

  if (material['material_quantity_count2'] && material['material_quantity_unit2']) {
    result.unitCount += result.unitCount.length <= 0 ? '' : ' x ';
    result.unitCount +=
      String(material['material_quantity_count2']) + quantityType[material['material_quantity_unit2']];
  }

  if (material['material_quantity_count3'] && material['material_quantity_unit3']) {
    result.unitCount += result.unitCount.length <= 0 ? '' : ' x ';
    result.unitCount +=
      String(material['material_quantity_count3']) + quantityType[material['material_quantity_unit3']];
  }

  // 단위당 금액 계산
  var baseValue = parseInt(material['material_unit']) === COUNT ? 1 : material['material_count'];
  baseValue *= material['material_quantity_count1'] > 0 ? material['material_quantity_count1'] : 1;
  baseValue *= material['material_quantity_count2'] > 0 ? material['material_quantity_count2'] : 1;
  baseValue *= material['material_quantity_count3'] > 0 ? material['material_quantity_count3'] : 1;

  // 단위 환산
  switch (parseInt(material['material_unit'])) {
    case MG:
      baseValue /= 1000;
      break;
    case G:
    case CC:
    case ML:
      baseValue *= 1;
      break;
    case KG:
    case L:
      baseValue *= 1000;
      break;
    case T:
    case DL:
      baseValue *= 1000 * 1000;
      break;
    default:
      break;
  }

  if (baseValue <= 0) {
    return result;
  }

  // 소수점 2자리까지 표시, 그 이하 값은 0.01로 표기
  result.minValueStr = parseInt(material['material_unit'] / 10) === 1 ? 'g' : 'ml';
  result.minValueStr = parseInt(material['material_unit']) === COUNT ? '개' : result.minValueStr;

  result.minValue = parseInt(material['material_unit'] / 10) === 1 ? G : ML;
  result.minValue = parseInt(material['material_unit']) === COUNT ? COUNT : result.minValue;

  var perSalePrice = (material['material_sale_price'] / baseValue).toFixed(2);
  perSalePrice = perSalePrice < 0.01 ? 0.01 : perSalePrice;
  var perRealSalePrice = material['material_sale_price'] / baseValue;

  var perWholeSalePrice = (material['material_wholesale_price'] / baseValue).toFixed(2);
  perWholeSalePrice = perWholeSalePrice < 0.01 ? 0.01 : perWholeSalePrice;
  var perRealWholeSalePrice = material['material_wholesale_price'] / baseValue;

  result.unitPerSalePrice = perSalePrice + '원 / 1' + result.minValueStr;
  result.unitWholeSalePrice = perWholeSalePrice + '원 / 1' + result.minValueStr;

  result.unitPerSalePrice2 = '1' + result.minValueStr + ' 당 ' + perSalePrice + '원';
  result.unitWholeSalePrice2 = '1' + result.minValueStr + ' 당 ' + perWholeSalePrice + '원';

  result.perSalePrice = parseFloat(perSalePrice);
  result.perWholeSalePrice = parseFloat(perWholeSalePrice);
  result.perRealSalePrice = parseFloat(perRealSalePrice);
  result.perRealWholeSalePrice = parseFloat(perRealWholeSalePrice);

  return result;
};

/**
 * @description value 자릿수에 따른 단위 변경
 * @param {*} value
 * @param {*} unitStr
 * @param {*} needComma
 */
export const materialConvertUnit = (value = 0, unitStr = '', needComma = true) => {
  if (!value || value <= 0) {
    return 0 + unitStr;
  }

  if (unitStr === 'g') {
    if (value >= 1000) {
      value /= 1000;
      unitStr = 'Kg';
    }
  }

  if (unitStr === 'ml') {
    if (value >= 1000) {
      value /= 1000;
      unitStr = 'L';
    }
  }

  value = value.toFixed(2).replace(/\.00$/, '');

  return (needComma ? mComma(value) : value) + unitStr;
};

/**
 * @description 포스 시그널 데이터 생성
 * @param {*} brandCode
 * @param {*} signalData
 */
export const getSignalText = (brandCode, signalData, mainDotBorder = false, dotType = 0) => {
  var dotStyle = {};
  dotStyle = mainDotBorder && { border: '#f1f4f6 solid 2px', top: '2px', right: '-2px' };
  dotStyle = dotType === 1 && {
    border: '2px solid rgb(241, 244, 246)',
    top: '5px',
    right: '5px',
    width: '13px',
    height: '13px',
  };

  var dot = '';
  if (brandCode === 'BRAND00000' || brandCode === 'BRAND00001') {
    dot = (
      <span className="badge badge-dot badge-dot badge-success" style={{ ...dotStyle }}>
        {' '}
      </span>
    );
    return {
      text: '정상수신중',
      badgeText: '',
      color: '',
      dot: dot,
    };
  }

  if (!signalData || signalData.length <= 0) {
    dot = (
      <span className="badge badge-dot badge-dot badge-danger" style={{ ...dotStyle }}>
        {' '}
      </span>
    );
    return {
      text: '수신중단',
      badgeText: '',
      color: '',
      dot: dot,
    };
  }

  var posCount = signalData.length;
  var diffCount = 0;
  var isConnected = true;
  var badgeText = '';
  var color = '';

  if (posCount === 1) {
    // 단일 포스
    diffCount = dateDiff(null, dateToStr2(signalData[0]['start_time'])) + 1;
    isConnected = diffCount <= 2 && signalData[0]['pos_sale_time'] > 0;
    if (isConnected) {
      badgeText = '';
      dot = (
        <span className="badge badge-dot badge-dot badge-success" style={{ ...dotStyle }}>
          {' '}
        </span>
      );
    } else {
      if (diffCount > 7) {
        color = '#a19fa9';
        badgeText = diffCount + '일' + (diffCount >= 30 ? ' 이상' : '');
        dot = (
          <span className="badge badge-dot badge-dot badge-secondary opacity-6" style={{ ...dotStyle }}>
            {' '}
          </span>
        );
      } else {
        color = '#d54b4b';
        badgeText = diffCount + '일';
        dot = (
          <span className="badge badge-dot badge-dot badge-danger" style={{ ...dotStyle }}>
            {' '}
          </span>
        );
      }
    }
  } else {
    // 복수 포스
    var unConnectedCount = signalData.filter(item => item['pos_sale_time'] === 0).length;
    isConnected = unConnectedCount <= 0;
    badgeText = isConnected === false ? unConnectedCount + '/' + posCount + '대' : '';
    color = isConnected === false ? '#d54b4b' : '';
    dot = (
      <span
        className={'badge badge-dot badge-dot ' + (isConnected === false ? 'badge-danger' : 'badge-success')}
        style={{ ...dotStyle }}
      >
        {' '}
      </span>
    );
  }

  return {
    text: isConnected ? '정상수신중' : '수신중단',
    badgeText: badgeText,
    color: color, // #a19fa9, #d54b4b
    dot: dot, // badge-success, badge-secondary, badge-danger
  };
};

/**
 * @description sha256으로 변환
 * @param {string} brandCode
 */
export const toSha256 = value => {
  if (!value) {
    return null;
  }

  return sha256(value.toString());
};

export const getMenuSnoListByCategory = (categoryIdx, brandMenuList) => {
  var menuList = filterDisableConts(brandMenuList);
  var categoryMenus = menuList.filter(item => item['category_idx'] === categoryIdx);

  return groupKeys(categoryMenus, 'brand_menu_sno').map(Number);
};

export const getCategoryIdxByMenu = (menuSno, brandMenuList) => {
  var menuList = filterDisableConts(brandMenuList);
  var findMenu = menuList.find(item => item['brand_menu_sno'] === menuSno);

  return findMenu ? findMenu['category_idx'] : 0;
};

export const getOfficeSnoListByArea = (areaCode, managedOfficeList) => {
  var officeList = filterDisableConts(managedOfficeList);
  var areaOffices = officeList.filter(item => item['area_id'] === areaCode);

  return groupKeys(areaOffices, 'office_sno').map(Number);
};

export const getAreaCodeByOffice = (officeSno, managedOfficeList) => {
  var officeList = filterDisableConts(managedOfficeList);
  var findOffice = officeList.find(item => item['office_sno'] === officeSno);

  return findOffice ? findOffice['area_id'] : undefined;
};

export const getOfficeListBySelectedArea = (areaCode, managedOfficeList) => {
  let officeList = filterDisableConts(managedOfficeList);
  let result = officeList.filter(ele => {
    return areaCode.includes(ele.area_id);
  })
  
  return result
}

/**
 * @description css에서 px을 rem으로 변경하여 리턴
 * @param {Number} base root fontSize 16px
 */
export const pxToRem = (val, base = 16) => {
  let res = null;
  if (typeof val === 'number') {
    res = `${val / base}rem`;
  } else {
    // array
    res = val.map(v => `${v / base}rem`).join(' ');
    if (val.length === 2) {
      res = `${res} ${res}`;
    }
  }

  return res;
};

export const pxToVw = (val, base = 375) => `${(val * 100) / base}vw`;

export const getUrlValue = key => {
  var urlStr = window.location.toString().split('?');
  var query = querystring.parse(urlStr[urlStr.length - 1].slice(0));

  return query[key];
};

// TODO polygon 기준으로 더 정확하게 계산할 수 있으면 좋겠음
export const radiusConvertZoomLevelByPolygon = (radius = 0) => {
  let zoom = 14;

  switch (true) {
    case radius <= 0:
      zoom = 14;
      break;
    case radius <= 900:
      zoom = 16;
      break;
    case radius > 900 && radius <= 1400:
      zoom = 15;
      break;
    case radius > 1400 && radius <= 4200:
      zoom = 14;
      break;
    case radius > 4200 && radius <= 7500:
      zoom = 13;
      break;
    case radius > 7500 && radius <= 13000:
      zoom = 12;
      break;
    case radius > 13000: // 목동현대타워
      zoom = 11;
      break;
    default:
      zoom = 13;
      break;
  }

  return zoom;
};

export const getMaxNumber = (list, key) => {
  let max = 0;

  list.map(item => {
    max = item[key] > max ? item[key] : max;
    return null;
  });

  return max;
};

// 미지정 지점이 있는 경우 area_id를 0으로 변환 후 officeList 반환.
export const updateOfficeList = (officeList) => {
  
  let updatedOfficeList = [];
  officeList.forEach(item => {
    if (item.area_id) {
      updatedOfficeList.push(item);
    } else {
      updatedOfficeList.push({...item, ...{area_id: '0'}});
    }
  });

  return updatedOfficeList;
}