import { materialConstants } from "../constants";
import { materialService } from "../services";

export const materialActions = {
  materialList,
  useMaterialHeaderData,
  useMaterialListData,
  useMaterialDateListData,
  useMaterialTotalData,
  oneMaterialOfficeHeaderData,
  oneMaterialOfficeListData,
  oneMaterialDateListData,
  oneMaterialMenuHeaderData,
  oneMaterialMenuListData,
  useMaterialLately,
};

function materialList(brandCode) {
  return dispatch => {
    dispatch(request());

    materialService.materialList(brandCode)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.MATERIAL_LIST_REQUEST } }
  function success(result) { return { type: materialConstants.MATERIAL_LIST_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.MATERIAL_LIST_FAILURE, error } }
}

function useMaterialHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.useMaterialHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.USE_MATERIAL_HEADER_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.USE_MATERIAL_HEADER_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.USE_MATERIAL_HEADER_DATA_FAILURE, error } }
}

function useMaterialListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.useMaterialListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.USE_MATERIAL_LIST_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.USE_MATERIAL_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.USE_MATERIAL_LIST_DATA_FAILURE, error } }
}

function useMaterialDateListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.useMaterialDateListData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.USE_MATERIAL_DATE_LIST_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.USE_MATERIAL_DATE_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.USE_MATERIAL_DATE_LIST_DATA_FAILURE, error } }
}

function useMaterialTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    materialService.useMaterialTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.USE_MATERIAL_TOTAL_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.USE_MATERIAL_TOTAL_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.USE_MATERIAL_TOTAL_DATA_FAILURE, error } }
}

function oneMaterialOfficeHeaderData(userSno, brandCode, materialSno, startDate, endDate, serviceType, diffStartDate, diffEndDate, materialName, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.oneMaterialOfficeHeaderData(userSno, brandCode, materialSno, startDate, endDate, serviceType, diffStartDate, diffEndDate, materialName, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_FAILURE, error } }
}

function oneMaterialOfficeListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    materialService.oneMaterialOfficeListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortKey, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function reset() { return { type: materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_RESET } }
  function request() { return { type: materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_FAILURE, error } }
}

function oneMaterialDateListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialName) {
  return dispatch => {
    dispatch(request());

    materialService.oneMaterialDateListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery, materialName)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.ONE_MATERIAL_DATE_LIST_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.ONE_MATERIAL_DATE_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.ONE_MATERIAL_DATE_LIST_DATA_FAILURE, error } }
}

function oneMaterialMenuHeaderData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, materialName, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.oneMaterialMenuHeaderData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, materialName, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_FAILURE, error } }
}

function oneMaterialMenuListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortName, officeSnoQuery) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    materialService.oneMaterialMenuListData(materialSno, userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, limitStart, limitCount, sortName, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function reset() { return { type: materialConstants.ONE_MATERIAL_MENU_LIST_DATA_RESET } }
  function request() { return { type: materialConstants.ONE_MATERIAL_MENU_LIST_DATA_REQUEST } }
  function success(result) { return { type: materialConstants.ONE_MATERIAL_MENU_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.ONE_MATERIAL_MENU_LIST_DATA_FAILURE, error } }
}

function useMaterialLately(userSno, brandCode, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    materialService.useMaterialLately(userSno, brandCode, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: materialConstants.USE_MATERIAL_LATELY_REQUEST } }
  function success(result) { return { type: materialConstants.USE_MATERIAL_LATELY_SUCCESS, result } }
  function failure(error) { return { type: materialConstants.USE_MATERIAL_LATELY_FAILURE, error } }
}