export const authConstants = {
  RESETSTATE: 'RESETSTATE',

  LOGOUT: 'USERS_LOGOUT',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  VALID_PHONENUM_RESET: 'VALID_PHONENUM_RESET',
  VALID_PHONENUM_REQUEST: 'VALID_PHONENUM_REQUEST',
  VALID_PHONENUM_SUCCESS: 'VALID_PHONENUM_SUCCESS',
  VALID_PHONENUM_FAILURE: 'VALID_PHONENUM_FAILURE',

  PHONE_AUTH_NUMBER_REQUEST: 'PHONE_AUTH_NUMBER_REQUEST',
  PHONE_AUTH_NUMBER_SUCCESS: 'PHONE_AUTH_NUMBER_SUCCESS',
  PHONE_AUTH_NUMBER_FAILURE: 'PHONE_AUTH_NUMBER_FAILURE',

  CHECK_AUTH_NUMBER_REQUEST: 'CHECK_AUTH_NUMBER_REQUEST',
  CHECK_AUTH_NUMBER_SUCCESS: 'CHECK_AUTH_NUMBER_SUCCESS',
  CHECK_AUTH_NUMBER_FAILURE: 'CHECK_AUTH_NUMBER_FAILURE',

  RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
  RESETPASSWORD_FAILURE: 'RESETPASSWORD_FAILURE',

  CHECK_LOGIN_STATE_REQUEST: 'CHECK_LOGIN_STATE_REQUEST',
  CHECK_LOGIN_STATE_SUCCESS: 'CHECK_LOGIN_STATE_SUCCESS',
  CHECK_LOGIN_STATE_FAILURE: 'CHECK_LOGIN_STATE_FAILURE',

  SYSTEM_ERROR_LOG_RESET: 'SYSTEM_ERROR_LOG_RESET',
  SYSTEM_ERROR_LOG_REQUEST: 'SYSTEM_ERROR_LOG_REQUEST',
  SYSTEM_ERROR_LOG_SUCCESS: 'SYSTEM_ERROR_LOG_SUCCESS',
  SYSTEM_ERROR_LOG_FAILURE: 'SYSTEM_ERROR_LOG_FAILURE',

  CHANGE_EMAIL_RESET: 'CHANGE_EMAIL_RESET',
  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',

  CHANGE_MOBILE_REQUEST: 'CHANGE_MOBILE_REQUEST',
  CHANGE_MOBILE_SUCCESS: 'CHANGE_MOBILE_SUCCESS',
  CHANGE_MOBILE_FAILURE: 'CHANGE_MOBILE_FAILURE',

  BRAND_LOGIN_REQUEST: 'BRAND_LOGIN_REQUEST',
  BRAND_LOGIN_SUCCESS: 'BRAND_LOGIN_SUCCESS',
  BRAND_LOGIN_FAILURE: 'BRAND_LOGIN_FAILURE',

  GHOST_INIT_REQUEST: 'GHOST_INIT_REQUEST',
  GHOST_INIT_SUCCESS: 'GHOST_INIT_SUCCESS',
  GHOST_INIT_FAILURE: 'GHOST_INIT_FAILURE',

  CHECK_ADMIN_ACCOUNT_REQUEST: 'CHECK_ADMIN_ACCOUNT_REQUEST',
  CHECK_ADMIN_ACCOUNT_SUCCESS: 'CHECK_ADMIN_ACCOUNT_SUCCESS',
  CHECK_ADMIN_ACCOUNT_FAILURE: 'CHECK_ADMIN_ACCOUNT_FAILURE',

  CHECK_JANDI_BRAND_ACCESS_AUTH_REQUEST: 'CHECK_JANDI_BRAND_ACCESS_AUTH_REQUEST',
  CHECK_JANDI_BRAND_ACCESS_AUTH_SUCCESS: 'CHECK_JANDI_BRAND_ACCESS_AUTH_SUCCESS',
  CHECK_JANDI_BRAND_ACCESS_AUTH_FAILURE: 'CHECK_JANDI_BRAND_ACCESS_AUTH_FAILURE',

  VALID_MOBILE_AUTH_REQUEST: 'VALID_MOBILE_AUTH_REQUEST',
  VALID_MOBILE_AUTH_SUCCESS: 'VALID_MOBILE_AUTH_SUCCESS',
  VALID_MOBILE_AUTH_FAILURE: 'VALID_MOBILE_AUTH_FAILURE',

  SET_APP_INFO: 'SET_APP_INFO',
  SET_IS_ALARM_PERMISSION_DENIED: 'SET_IS_ALARM_PERMISSION_DENIED',

  GET_ORDER_ALARM_REQUEST: 'GET_ORDER_ALARM_REQUEST',
  GET_ORDER_ALARM_SUCCESS: 'GET_ORDER_ALARM_SUCCESS',
  GET_ORDER_ALARM_FAILURE: 'GET_ORDER_ALARM_FAILURE',

  SET_APP_PUSH_OPTION_REQUEST: 'SET_APP_PUSH_OPTION_REQUEST',
  SET_APP_PUSH_OPTION_SUCCESS: 'SET_APP_PUSH_OPTION_SUCCESS',
  SET_APP_PUSH_OPTION_FAILURE: 'SET_APP_PUSH_OPTION_FAILURE',

  SET_AUTH_INFO: 'SET_AUTH_INFO',
};

export const appPushOption = {
  optionName: {
    status: '상태',
    delivery: '주문',
    service: '서비스',
    sales: '매출',
    franchiseNotice: '본사 공지',
  },
  optionBit: {
    status: 0,
    delivery: 1,
    service: 2,
    sales: 3,
  },
  optionType: {
    status: 1,
    delivery: 2,
    service: 3,
    sales: 4,
  },
  optionValue: {
    1: true,
    0: false,
  },
};
