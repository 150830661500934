export const reportConstants = {
  EXCEL_DOWNLOAD_BY_SEARCH_REQUEST: 'EXCEL_DOWNLOAD_BY_SEARCH_REQUEST',
  EXCEL_DOWNLOAD_BY_SEARCH_SUCCESS: 'EXCEL_DOWNLOAD_BY_SEARCH_SUCCESS',
  EXCEL_DOWNLOAD_BY_SEARCH_FAILURE: 'EXCEL_DOWNLOAD_BY_SEARCH_FAILURE',

  EXCEL_SALE_DATE_VIEW_REQUEST: 'EXCEL_SALE_DATE_VIEW_REQUEST',
  EXCEL_SALE_DATE_VIEW_SUCCESS: 'EXCEL_SALE_DATE_VIEW_SUCCESS',
  EXCEL_SALE_DATE_VIEW_FAILURE: 'EXCEL_SALE_DATE_VIEW_FAILURE',

  EXCEL_SALE_MONTH_VIEW_REQUEST: 'EXCEL_SALE_MONTH_VIEW_REQUEST',
  EXCEL_SALE_MONTH_VIEW_SUCCESS: 'EXCEL_SALE_MONTH_VIEW_SUCCESS',
  EXCEL_SALE_MONTH_VIEW_FAILURE: 'EXCEL_SALE_MONTH_VIEW_FAILURE',

  EXCEL_SALE_TIME_VIEW_REQUEST: 'EXCEL_SALE_TIME_VIEW_REQUEST',
  EXCEL_SALE_TIME_VIEW_SUCCESS: 'EXCEL_SALE_TIME_VIEW_SUCCESS',
  EXCEL_SALE_TIME_VIEW_FAILURE: 'EXCEL_SALE_TIME_VIEW_FAILURE',

  EXCEL_SALE_DETAIL_VIEW_REQUEST: 'EXCEL_SALE_DETAIL_VIEW_REQUEST',
  EXCEL_SALE_DETAIL_VIEW_SUCCESS: 'EXCEL_SALE_DETAIL_VIEW_SUCCESS',
  EXCEL_SALE_DETAIL_VIEW_FAILURE: 'EXCEL_SALE_DETAIL_VIEW_FAILURE',

  EXCEL_MENU_DAY_VIEW_REQUEST: 'EXCEL_MENU_DAY_VIEW_REQUEST',
  EXCEL_MENU_DAY_VIEW_SUCCESS: 'EXCEL_MENU_DAY_VIEW_SUCCESS',
  EXCEL_MENU_DAY_VIEW_FAILURE: 'EXCEL_MENU_DAY_VIEW_FAILURE',

  EXCEL_MENU_MONTH_VIEW_REQUEST: 'EXCEL_MENU_MONTH_VIEW_REQUEST',
  EXCEL_MENU_MONTH_VIEW_SUCCESS: 'EXCEL_MENU_MONTH_VIEW_SUCCESS',
  EXCEL_MENU_MONTH_VIEW_FAILURE: 'EXCEL_MENU_MONTH_VIEW_FAILURE',

  EXCEL_MENU_TIME_VIEW_REQUEST: 'EXCEL_MENU_TIME_VIEW_REQUEST',
  EXCEL_MENU_TIME_VIEW_SUCCESS: 'EXCEL_MENU_TIME_VIEW_SUCCESS',
  EXCEL_MENU_TIME_VIEW_FAILURE: 'EXCEL_MENU_TIME_VIEW_FAILURE',

  EXCEL_DELIVERY_DAY_VIEW_REQUEST: 'EXCEL_DELIVERY_DAY_VIEW_REQUEST',
  EXCEL_DELIVERY_DAY_VIEW_SUCCESS: 'EXCEL_DELIVERY_DAY_VIEW_SUCCESS',
  EXCEL_DELIVERY_DAY_VIEW_FAILURE: 'EXCEL_DELIVERY_DAY_VIEW_FAILURE',

  EXCEL_DELIVERY_MONTH_VIEW_REQUEST: 'EXCEL_DELIVERY_MONTH_VIEW_REQUEST',
  EXCEL_DELIVERY_MONTH_VIEW_SUCCESS: 'EXCEL_DELIVERY_MONTH_VIEW_SUCCESS',
  EXCEL_DELIVERY_MONTH_VIEW_FAILURE: 'EXCEL_DELIVERY_MONTH_VIEW_FAILURE',

  EXCEL_DELIVERY_LOCATION_VIEW_REQUEST: 'EXCEL_DELIVERY_LOCATION_VIEW_REQUEST',
  EXCEL_DELIVERY_LOCATION_VIEW_SUCCESS: 'EXCEL_DELIVERY_LOCATION_VIEW_SUCCESS',
  EXCEL_DELIVERY_LOCATION_VIEW_FAILURE: 'EXCEL_DELIVERY_LOCATION_VIEW_FAILURE',

  EXCEL_MATERIAL_DATE_VIEW_REQUEST: 'EXCEL_MATERIAL_DATE_VIEW_REQUEST',
  EXCEL_MATERIAL_DATE_VIEW_SUCCESS: 'EXCEL_MATERIAL_DATE_VIEW_SUCCESS',
  EXCEL_MATERIAL_DATE_VIEW_FAILURE: 'EXCEL_MATERIAL_DATE_VIEW_FAILURE',

  EXCEL_MATERIAL_MONTH_VIEW_REQUEST: 'EXCEL_MATERIAL_MONTH_VIEW_REQUEST',
  EXCEL_MATERIAL_MONTH_VIEW_SUCCESS: 'EXCEL_MATERIAL_MONTH_VIEW_SUCCESS',
  EXCEL_MATERIAL_MONTH_VIEW_FAILURE: 'EXCEL_MATERIAL_MONTH_VIEW_FAILURE',

  EXCEL_MATERIAL_TIME_VIEW_REQUEST: 'EXCEL_MATERIAL_TIME_VIEW_REQUEST',
  EXCEL_MATERIAL_TIME_VIEW_SUCCESS: 'EXCEL_MATERIAL_TIME_VIEW_SUCCESS',
  EXCEL_MATERIAL_TIME_VIEW_FAILURE: 'EXCEL_MATERIAL_TIME_VIEW_FAILURE',

  MONTHLY_REPORT_HEADER_REQUEST: 'MONTHLY_REPORT_HEADER_REQUEST',
  MONTHLY_REPORT_HEADER_SUCCESS: 'MONTHLY_REPORT_HEADER_SUCCESS',
  MONTHLY_REPORT_HEADER_FAILURE: 'MONTHLY_REPORT_HEADER_FAILURE',

  MONTHLY_REPORT_SALES_REQUEST: 'MONTHLY_REPORT_SALES_REQUEST',
  MONTHLY_REPORT_SALES_SUCCESS: 'MONTHLY_REPORT_SALES_SUCCESS',
  MONTHLY_REPORT_SALES_FAILURE: 'MONTHLY_REPORT_SALES_FAILURE',

  MONTHLY_REPORT_MENU_SALES_REQUEST: 'MONTHLY_REPORT_MENU_SALES_REQUEST',
  MONTHLY_REPORT_MENU_SALES_SUCCESS: 'MONTHLY_REPORT_MENU_SALES_SUCCESS',
  MONTHLY_REPORT_MENU_SALES_FAILURE: 'MONTHLY_REPORT_MENU_SALES_FAILURE',

  MONTHLY_REPORT_DELIVERY_REQUEST: 'MONTHLY_REPORT_DELIVERY_REQUEST',
  MONTHLY_REPORT_DELIVERY_SUCCESS: 'MONTHLY_REPORT_DELIVERY_SUCCESS',
  MONTHLY_REPORT_DELIVERY_FAILURE: 'MONTHLY_REPORT_DELIVERY_FAILURE',

  MONTHLY_REPORT_DELIVERY_ORDER_APP_REQUEST: 'MONTHLY_REPORT_DELIVERY_ORDER_APP_REQUEST',
  MONTHLY_REPORT_DELIVERY_ORDER_APP_SUCCESS: 'MONTHLY_REPORT_DELIVERY_ORDER_APP_SUCCESS',
  MONTHLY_REPORT_DELIVERY_ORDER_APP_FAILURE: 'MONTHLY_REPORT_DELIVERY_ORDER_APP_FAILURE',

  REPORT_LIST_REQUEST: 'REPORT_LIST_REQUEST',
  REPORT_LIST_SUCCESS: 'REPORT_LIST_SUCCESS',
  REPORT_LIST_FAILURE: 'REPORT_LIST_FAILURE',

  REPORT_BOOKMARK_REQUEST: 'REPORT_BOOKMARK_REQUEST',
  REPORT_BOOKMARK_SUCCESS: 'REPORT_BOOKMARK_SUCCESS',
  REPORT_BOOKMARK_FAILURE: 'REPORT_BOOKMARK_FAILURE',

  REPORT_REMOVE_REQUEST: 'REPORT_REMOVE_REQUEST',
  REPORT_REMOVE_SUCCESS: 'REPORT_REMOVE_SUCCESS',
  REPORT_REMOVE_FAILURE: 'REPORT_REMOVE_FAILURE',

  MONTHLY_REPORT_UPLOAD_REQUEST: 'MONTHLY_REPORT_UPLOAD_REQUEST',
  MONTHLY_REPORT_UPLOAD_SUCCESS: 'MONTHLY_REPORT_UPLOAD_SUCCESS',
  MONTHLY_REPORT_UPLOAD_FAILURE: 'MONTHLY_REPORT_UPLOAD_FAILURE',
};