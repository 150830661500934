import { getDownloadRequestValue, setDownloadRequestValue, isIEBrowser } from "../constants";
import { authHeader, getMyUserData, handleResponse, localDataLoad } from "../helpers";

export const reportService = {
  excelDownloadBySearch,

  excelSaleDateView,
  excelSaleMonthView,
  excelSaleTimeView,
  excelSaleDetailView,

  excelMenuDayView,
  excelMenuMonthView,
  excelMenuTimeView,

  excelDeliveryDayView,
  excelDeliveryMonthView,
  excelDeliveryLocationView,

  excelMaterialDateView,
  excelMaterialMonthView,
  excelMaterialTimeView,

  monthlyReportHeaderData,
  monthlyReportSalesData,
  monthlyReportMenuSalesData,
  monthlyReportDeliveryData,
  monthlyReportDeliveryOrderAppData,
  monthlyReportPDFUpload,

  reportList,
  reportBookmark,
  reportRemove,
};

function excelDownloadBySearch(type) {
  var params = getDownloadRequestValue();

  var parseBody = JSON.parse(params[1]['body']);
  var userData = getMyUserData();
  var viewOptionList = localDataLoad('viewOptionList');
  var downloadParams = localDataLoad('downloadParams');
  var searchParams = localDataLoad('searchParams');

  parseBody['downloadParams'] = {
    type: type,
    routeName: params[0].replace('/report/', ''),
    userSno: userData['user_sno'],
    brandCode: userData['brand_code'],
    brandName: userData['brand_name'],
    viewOptionList: viewOptionList,
    includeOption: parseBody['includeOption'],
    startDate: parseBody['startDate'],
    endDate: parseBody['endDate'],
    officeSnoQuery: parseBody['officeSnoQuery'],
    menuSnoQuery: parseBody['menuSnoQuery'],
    materialSnoQuery: parseBody['materialSnoQuery'],
    officeName: parseBody['officeName'],
    menuName: parseBody['menuName'],
    materialName: parseBody['materialName'],
    noReCustomer: parseBody['noReCustomer'],
    dlType: parseBody['dlType'],
    ...downloadParams,
    ...searchParams,
  }

  params[1]['body'] = JSON.stringify(parseBody);
  if (type === 1) {
    return fetch(params[0], params[1])
      .then(res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let fileName = '앳트래커_다운로드.xlsx';

        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename\*?="?(.+?)"?($|;)/);
          if (matches && matches[1]) {
            fileName = decodeURIComponent(matches[1]);
          }
        }

        return res.blob().then((blob) => {
          if (isIEBrowser()) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            link.href = '';
          }
        })
      })
  }

  if (type === 2) {
    return fetch(params[0], params[1])
      .then(handleResponse)
      .then(result => {
        return result;
      });
  }
}

function excelSaleDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelSaleDateView', requestOptions);

  return fetch('/report/excelSaleDateView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelSaleMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelSaleMonthView', requestOptions);

  return fetch('/report/excelSaleMonthView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelSaleTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelSaleTimeView', requestOptions);

  return fetch('/report/excelSaleTimeView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelSaleDetailView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelSaleDetailView', requestOptions);

  return fetch('/report/excelSaleDetailView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMenuDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMenuDayView', requestOptions);

  return fetch('/report/excelMenuDayView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMenuMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMenuMonthView', requestOptions);

  return fetch('/report/excelMenuMonthView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMenuTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMenuTimeView', requestOptions);

  return fetch('/report/excelMenuTimeView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMaterialDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMaterialDateView', requestOptions);


  return fetch('/report/excelMaterialDateView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMaterialMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMaterialMonthView', requestOptions);

  return fetch('/report/excelMaterialMonthView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelMaterialTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'materialSnoQuery': materialSnoQuery,
    })
  };

  setDownloadRequestValue('/report/excelMaterialTimeView', requestOptions);

  return fetch('/report/excelMaterialTimeView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelDeliveryDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
      'dlType': dlType,
    })
  };

  setDownloadRequestValue('/report/excelDeliveryDayView', requestOptions);

  return fetch('/report/excelDeliveryDayView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelDeliveryMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
      'dlType': dlType,
    })
  };

  setDownloadRequestValue('/report/excelDeliveryMonthView', requestOptions);

  return fetch('/report/excelDeliveryMonthView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function excelDeliveryLocationView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'sortType': sortType,
      'officeSnoQuery': officeSnoQuery,
      'menuSnoQuery': menuSnoQuery,
      'dlType': dlType
    })
  };

  setDownloadRequestValue('/report/excelDeliveryLocationView', requestOptions);

  return fetch('/report/excelDeliveryLocationView', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function reportList(userSno, brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
    })
  };

  return fetch('/report/reportList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function reportBookmark(userSno, reportSno, flag) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'reportSno': reportSno,
      'flag': flag,
    })
  };

  return fetch('/report/reportBookmark', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function reportRemove(brandCode, reportSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'reportSnoQuery': reportSnoQuery,
    })
  };

  return fetch('/report/reportRemove', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportHeaderData(brandCode, serviceType, searchYear, searchMonth) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'serviceType': serviceType,
      'searchYear': searchYear,
      'searchMonth': searchMonth,
    })
  };

  return fetch('/report/monthlyReportHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportSalesData(brandCode, serviceType, searchYear, searchMonth) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'serviceType': serviceType,
      'searchYear': searchYear,
      'searchMonth': searchMonth,
    })
  };

  return fetch('/report/monthlyReportSalesData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportMenuSalesData(brandCode, serviceType, searchYear, searchMonth) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'serviceType': serviceType,
      'searchYear': searchYear,
      'searchMonth': searchMonth,
    })
  };

  return fetch('/report/monthlyReportMenuSalesData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportDeliveryData(brandCode, serviceType, searchYear, searchMonth) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'serviceType': serviceType,
      'searchYear': searchYear,
      'searchMonth': searchMonth,
    })
  };

  return fetch('/report/monthlyReportDeliveryData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportDeliveryOrderAppData(brandCode, searchYear, searchMonth) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'brandCode': brandCode,
      'searchYear': searchYear,
      'searchMonth': searchMonth,
    })
  };

  return fetch('/report/monthlyReportDeliveryOrderAppData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlyReportPDFUpload(title, fileType, brandCode, userSno, uploadData, fileName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'title': title,
      'fileType': fileType,
      'brandCode': brandCode,
      'userSno': userSno,
      'uploadData': uploadData,
      'fileName': fileName
    })
  };

  return fetch('/report/monthlyReportPDFUpload', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}