export const advertisementConstants = {
  SET_OFFICE_INFO: 'SET_OFFICE_INFO',
  SET_DISTANCE_TOGGLE: 'SET_DISTANCE_TOGGLE',
  SET_ACTIVE_MARKER_INFO: 'SET_ACTIVE_MARKER_INFO',
  RESET_ACTIVE_MARKER_INFO: 'RESET_ACTIVE_MARKER_INFO',
  SET_DIMMED_OFFICE_FILTER: 'SET_DIMMED_OFFICE_FILTER',
  SET_OFFICE_VIEW: 'SET_OFFICE_VIEW',
  SET_PIN_INFO: 'SET_PIN_INFO',
  SET_ACTIVE_CHANNELS: 'SET_ACTIVE_CHANNELS',
  SET_MAX_DELIVERY_BOUNDS: 'SET_MAX_DELIVERY_BOUNDS',
  SET_ADVERTISEMENT_FILTER: 'SET_ADVERTISEMENT_FILTER',

  ADVERTISEMENT_AREA_LIST_REQUEST: 'ADVERTISEMENT_AREA_LIST_REQUEST',
  ADVERTISEMENT_AREA_LIST_SUCCESS: 'ADVERTISEMENT_AREA_LIST_SUCCESS',
  ADVERTISEMENT_AREA_LIST_FAILURE: 'ADVERTISEMENT_AREA_LIST_FAILURE',

  ADVERTISEMENT_OFFICE_LIST_REQUEST: 'ADVERTISEMENT_OFFICE_LIST_REQUEST',
  ADVERTISEMENT_OFFICE_LIST_SUCCESS: 'ADVERTISEMENT_OFFICE_LIST_SUCCESS',
  ADVERTISEMENT_OFFICE_LIST_FAILURE: 'ADVERTISEMENT_OFFICE_LIST_FAILURE',

  ADVERTISEMENT_MARKER_LIST_REQUEST: 'ADVERTISEMENT_MARKER_LIST_REQUEST',
  ADVERTISEMENT_MARKER_LIST_SUCCESS: 'ADVERTISEMENT_MARKER_LIST_SUCCESS',
  ADVERTISEMENT_MARKER_LIST_FAILURE: 'ADVERTISEMENT_MARKER_LIST_FAILURE',
};

export const advertisementContentWidth = 480;

export const advertisementProperty = {
  Ratio: { key: 'officeCntRatio', averageKey: 'totalRatioAvg', suffix: '배', pin: 'pinRatio' },
  Amount: { key: 'officeCntAmount', averageKey: 'totalAmountAvg', suffix: '원', pin: 'pinAmount' },
  PinCnt: { key: 'officeCnt', averageKey: 'totalCnt', suffix: '개' },
  DisplayCnt: { key: 'officeCntDisplayCnt', averageKey: 'totalDisplayCntAvg', suffix: '건', pin: 'pinDisplayCnt' },
  ClickCnt: { key: 'officeCntClickCnt', averageKey: 'totalClickCntAvg', suffix: '건', pin: 'pinClickCnt' },
  ClickPer: { key: 'officeCntClickPer', averageKey: 'totalClickPerAvg', suffix: '%', pin: 'pinClickPer' },
  OrderCnt: { key: 'officeCntOrderCnt', averageKey: 'totalOrderCntAvg', suffix: '건', pin: 'pinOrderCnt' },
};

export const advertisementActiveMarkerInfo = {
  activeInteractionType: null,
  activeMarkerId: null,
};
