import { authHeader, handleResponse } from '../helpers';

export const advertisementService = {
  getAreaLocationPinListData,
  getOfficeLocationPinListData,
};

function getAreaLocationPinListData(userSno, brandCode, officeSnoList, startDate, endDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      officeSnoList: officeSnoList,
      startDate: startDate,
      endDate: endDate,
    }),
  };

  return fetch('/pin/getAreaLocationPinListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getOfficeLocationPinListData(userSno, brandCode, officeSno, startDate, endDate, pinIdListStr) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      pinIdListStr: pinIdListStr,
    }),
  };

  return fetch('/pin/getOfficeLocationPinListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
