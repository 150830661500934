import { useEffect, useRef, useState } from 'react';

/**
 * @function
 * @description 리액트 state에 업데이트가 일어날 때, 업데이트 되기 이전의 값을 리턴
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useScrollPosition = elementRef => {
  function getScroll() {
    return {
      y: elementRef.current ? elementRef.current.scrollTop : undefined,
      x: elementRef.current ? elementRef.current.scrollLeft : undefined,
    };
  }

  const [scrollPosition, setScrollPosition] = useState(getScroll);

  useEffect(() => {
    if (!elementRef.current) {
      return false;
    }

    function handleScroll() {
      setScrollPosition(getScroll());
    }

    elementRef.current.addEventListener('scroll', handleScroll);
    return () => elementRef.current.removeEventListener('scroll', handleScroll);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return scrollPosition;
};

/**
 * @function
 * @description 브라우저 window width height 가져오는 custom hook
 */
export function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  return windowDimensions;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();
 
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
 
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
 