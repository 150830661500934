import { userService } from '../services';

export const checkReactNativeWebView = () => {
  return window.ReactNativeWebView ? true : false;
};

export const setUserId = async (userSno, init = false) => {
  if (!window.ReactNativeWebView) {
    return;
  }

  if (userSno) {
    await userService.setAppUserID(userSno, init);
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'userId', data: userSno ? userSno.toString() : '' }));
};

export const setCanPageRefresh = (canRefresh = true) => {
  if (!window.ReactNativeWebView) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'canRefresh', data: canRefresh }));
};

export const setAppHeaderColor = color => {
  if (!window.ReactNativeWebView) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'statusColor', data: color }));
};

export const setAppPopupPage = path => {
  if (!window.ReactNativeWebView) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'popupPage', data: path }));
};

export const setAppSocialEvent = (eventName, eventCategory, eventLabel) => {
  if (!window.ReactNativeWebView) {
    return;
  }

  const eventObj = {
    eventName: eventName,
    eventCategory: eventCategory,
    eventLabel: eventLabel,
  };

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'fbCustomEvent', data: eventObj }));
  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'gaCustomEvent', data: eventObj }));
};

export const setMoveAppSetting = () => {
  if (!checkReactNativeWebView()) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'moveAppSetting' }));
};

export const setFocusAlarmSetting = () => {
  if (!checkReactNativeWebView()) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'focusAlarmSetting' }));
};

export const setNotifyBadgeCount = count => {
  if (!checkReactNativeWebView()) {
    return;
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'setNotifyBadgeCount', data: count }));
};

export const moveMarketbom = path => {
  if (!checkReactNativeWebView()) {
    window.open(path, '_self');
    return;
  }

  window.location.href = '#/';
  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'moveMarketbom', data: path }));
};

export const fileDownload = (url, fileName) => {
  if (!checkReactNativeWebView()) {
    return;
  }

  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: 'fileDownload', data: { url: url, fileName: fileName } }),
  );
};
