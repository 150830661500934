import { PATHS } from './Routes';

export const PATH_MAP = {
  '#/': '메인 페이지',
  '#/auth/login': '로그인',
  '#/dashboard/sales': '홀 지표',
  '#/dashboard/menu': '홀메뉴 지표',
  '#/dashboard/delivery': '배달 지표',
  '#/dashboard/review': '리뷰 지표',
  '#/dashboard/material': '원부자재 지표',
  '#/report/excel': '리포트 생성',
  '#/report/download': '리포트 다운로드',
  '#/elements/buttons-standard': '공지사항',
  '#/elements/buttons-square': '1:1 문의하기',
  '#/components/tooltips-popovers': '매장/지점 관리',
  '#/components/accordions': '메뉴 관리',
  '#/components/notifications': '원부자재 관리',
  '#/components/loading-blocks': '레시피 관리',
  '#/components/progress-bar': '사용자 관리',
  '#/officeDetail/sales': '매장 상세 정보',
  '#/menuDetail/sales': '메뉴 상세 정보',
  '#/materialDetail/use': '원부자재 상세 정보',
  '#/Members/signup': '회원가입',
};

/**
 * 관리 구글시트
 * https://docs.google.com/spreadsheets/d/1T4QW9NQHX1F0MKVpQ7nR3NeOOOrR0ikSOjKAXov063I/edit#gid=813660977
 */

export const GA_EVENT_CONST = {
  //기간별 매출
  C0025: { eventName: '일별', category: '기간별 매출' },
  C0026: { eventName: '시간대별', category: '기간별 매출' },
  C0027: { eventName: '요일별', category: '기간별 매출' },
  C0028: { eventName: '월별', category: '기간별 매출' },
  C0029: { eventName: '분기별', category: '기간별 매출' },
  C0030: { eventName: '연도별', category: '기간별 매출' },
  C0037: { eventName: '소비원가 높은순', category: '기간별 매출' },
  C0038: { eventName: '소비원가 낮은순', category: '기간별 매출' },
  C0039: { eventName: '시간순 선택', category: '기간별 매출' },
  C0040: { eventName: '최근 시간순', category: '기간별 매출' },
  C0041: { eventName: '과거 시간순', category: '기간별 매출' },
  C0042: { eventName: '요일순', category: '기간별 매출' },
  C0043: { eventName: '최근 월순', category: '기간별 매출' },
  C0044: { eventName: '과거 월순', category: '기간별 매출' },
  C0045: { eventName: '최근 분기순', category: '기간별 매출' },
  C0046: { eventName: '과거 분기순', category: '기간별 매출' },
  C0047: { eventName: '최근 연도순', category: '기간별 매출' },
  C0048: { eventName: '과거 연도순', category: '기간별 매출' },

  //채널별 매출 C0201 ~ C0228
  C0201: { eventName: '홀 + 배달전체', category: '채널별 매출' },
  C0202: { eventName: '홀 전체', category: '채널별 매출' },
  C0203: { eventName: '배달앱 전체', category: '채널별 매출' },
  C0204: { eventName: '홀/배달 선택', category: '채널별 매출' },
  C0205: { eventName: '채널 전체', category: '채널별 매출' },
  C0206: { eventName: '카드(홀)', category: '채널별 매출' },
  C0207: { eventName: '배달의 민족', category: '채널별 매출' },
  C0208: { eventName: '쿠팡이츠', category: '채널별 매출' },
  C0209: { eventName: '요기요', category: '채널별 매출' },
  C0210: { eventName: '전화주문', category: '채널별 매출' },
  C0211: { eventName: '배민1', category: '채널별 매출' },
  C0212: { eventName: '위메프오', category: '채널별 매출' },
  C0213: { eventName: '현금(홀)', category: '채널별 매출' },
  C0214: { eventName: '기타(홀)', category: '채널별 매출' },

  //메뉴 판매현황 C0301 ~ C0332
  C0301: { eventName: '메인 메뉴', category: '메뉴 판매현황' },
  C0302: { eventName: '단품 메뉴', category: '메뉴 판매현황' },
  C0303: { eventName: '카테고리별 메뉴', category: '메뉴 판매현황' },
  C0305: { eventName: '주문수량 기준', category: '메뉴 판매현황' },
  C0306: { eventName: '결제건 높은순', category: '메뉴 판매현황' },
  C0307: { eventName: '결제건 낮은순', category: '메뉴 판매현황' },

  //배달지/고객분석 C0401 ~ C0429
  C0401: { eventName: '매장~배달범위', category: '배달지/고객분석' },

  //동단위 주문/배달비 C0501 ~ C0518
  C0501: { eventName: '행정동', category: '동단위 주문/베달비' },

  //광고핀 분석 C0601 ~ C0632
  C0601: { eventName: '1.5km', category: '광고핀 분석' },
  C0602: { eventName: '2km', category: '광고핀 분석' },
  C0603: { eventName: '3km', category: '광고핀 분석' },
  C0604: { eventName: '-km (광고핀 분석)', category: '광고핀 분석' },

  //원부자재 분석 C0701 ~ C0719
  C0701: { eventName: '원부자재 선택', category: '원부자재 분석' },
  C0710: { eventName: '사용량 분석', category: '원부자재 분석' },
  C0711: { eventName: '자재별 소비량', category: '원부자재 분석' },

  //매장 관리/현황  C0801 ~ C0816
  C0802: { eventName: '매장 등록용 파일', category: '매장관리/현황' },
  C0803: { eventName: '한번에 매장등록', category: '매장관리/현황' },
  C0804: { eventName: '등록된 매장순', category: '매장관리/현황' },
  C0805: { eventName: '진행률 낮은순', category: '매장관리/현황' },
  C0806: { eventName: '진행률 높은순', category: '매장관리/현황' },
  C0807: { eventName: 'ㄱ~ㅎ순', category: '매장관리/현황' },
  C0808: { eventName: '초대/가입중 매장만', category: '매장관리/현황' },
  C0809: { eventName: '설치/대기중 매장만', category: '매장관리/현황' },
  C0810: { eventName: '수집/분석중 매장만', category: '매장관리/현황' },
  C0811: { eventName: 'DATA수신오류 매장만', category: '매장관리/현황' },
  C0812: { eventName: '미납정지 매장만', category: '매장관리/현황' },
  C0813: { eventName: '정상분석 매장만', category: '매장관리/현황' },
  C0814: { eventName: '분석제외 매장만', category: '매장관리/현황' },
  C0815: { eventName: '매장추가등록', category: '매장관리/현황' },
  C0816: { eventName: '분석제외/휴페업 처리', category: '매장관리/현황' },

  //관리자 현황 C0901 ~ C0908
  C0902: { eventName: '엑셀포맷 다운로드', category: '관리자 현황' },
  C0903: { eventName: '엑셀 업로드', category: '관리자 현황' },
  C0904: { eventName: '순서', category: '관리자 현황' },
  C0905: { eventName: '등록 순', category: '관리자 현황' },
  C0906: { eventName: 'ㄱ~ㅎ 순', category: '관리자 현황' },
  C0907: { eventName: '사용자 추가등록', category: '관리자 현황' },
  C0908: { eventName: '관리제외 처리', category: '관리자 현황' },

  //계정 연동 현황 C1001 ~ C1005
  C1001: { eventName: '계정 연동 현황', category: '가맹 관리' },
  C1002: { eventName: '배달의민족', category: '계정 연동 현황' },
  C1003: { eventName: '쿠팡이츠', category: '계정 연동 현황' },
  C1004: { eventName: '요기요', category: '계정 연동 현황' },
  C1005: { eventName: '배달특급 (계정 연동 현황)', category: '계정 연동 현황' },

  //앳트래커 고객센터 C1101 ~ C1104
  C1101: { eventName: '앳트래커 고객센터', category: '앳트래커 고객센터' },
  C1102: { eventName: '공지사항', category: '앳트래커 고객센터' },
  C1103: { eventName: 'FAQ', category: '앳트래커 고객센터' },
  C1104: { eventName: '앳트래커 활용가이드', category: '앳트래커 고객센터' },
  C1105: { eventName: '본사 공지', category: '앳트래커 고객센터' },

  //앳트래커 고객센터 C1201 ~ C1204
  C1201: { eventName: '서비스안내', category: '서비스안내' },
  C1202: { eventName: '메뉴관리', category: '메뉴관리' },
  C1203: { eventName: '내정보', category: '내정보' },
  C1204: { eventName: '실시간 상담', category: '실시간 상담' },

  //고객리뷰 현황 C1301 ~ C1320
  C1301: { eventName: '고객리뷰 현황', category: '실시간 분석 현황' },
  C1305: { eventName: '배달앱별', category: '고객리뷰 현황' },
  C1306: { eventName: '매장 만족도', category: '고객리뷰 현황' },
  C1307: { eventName: '만족도순', category: '고객리뷰 현황' },
  C1308: { eventName: '별점 전체', category: '고객리뷰 현황' },
  C1312: { eventName: '만족도 낮은순', category: '고객리뷰 현황' },
  C1313: { eventName: '만족도 높은순', category: '고객리뷰 현황' },
  C1314: { eventName: '리뷰 적은순 ', category: '고객리뷰 현황' },
  C1315: { eventName: '리뷰 많은순 ', category: '고객리뷰 현황' },
  C1316: { eventName: '1점 리뷰만 ', category: '고객리뷰 현황' },
  C1317: { eventName: '2점 리뷰만 ', category: '고객리뷰 현황' },
  C1318: { eventName: '3점 리뷰만 ', category: '고객리뷰 현황' },
  C1319: { eventName: '4점 리뷰만 ', category: '고객리뷰 현황' },
  C1320: { eventName: '5점 리뷰만 ', category: '고객리뷰 현황' },
  C1321: { eventName: '1점', category: '고객리뷰 현황' },
  C1322: { eventName: '2점', category: '고객리뷰 현황' },
  C1323: { eventName: '3점', category: '고객리뷰 현황' },
  C1324: { eventName: '4점', category: '고객리뷰 현황' },
  C1325: { eventName: '5점', category: '고객리뷰 현황' },

  C2007: { eventName: '결제정보입력', category: 'Funnel5', sendApp: true, sendAppName: 'REGIST_PAYMENT_INFO' },
  C2008: { eventName: '상담(채널톡)', category: '상담(채널톡)', sendApp: true, sendAppName: 'OPEN_CHANNEL_CHAT' },
  C2009: {
    eventName: '회원가입완료',
    category: 'Funnel1',
    sendApp: true,
    sendAppName: 'COMPLETE_SIGNUP',
    kakao: true,
    facebook: true,
  },

  C2100: { eventName: '회원가입시작 (프랜차이즈-마스터)', category: 'Funnel' },
  C2101: { eventName: '회원가입시작 (프랜차이즈-본사)', category: 'Funnel' },
  C2102: { eventName: '회원가입시작 (프랜차이즈-가맹점주)', category: 'Funnel' },
  C2103: { eventName: '회원가입시작 (자영업자-점주)', category: 'Funnel' },

  // C2000 이후는 공통 로직
  C2000: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '엑셀 다운로드', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '엑셀 다운로드', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '엑셀 다운로드', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '엑셀 다운로드', category: '메뉴 판매현황' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '엑셀리포트', category: '고객리뷰 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '엑셀다운로드', category: '원부자재 분석' },
    },
  },

  C2001: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '지점 및 매장 선택1', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '지점 및 매장 선택1', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '지점 및 매장선택1', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '지점 및 매장선택1', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '지점 및 매장선택1', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '지점 및 매장선택1', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '지점 및 매장선택1', category: '광고핀 분석' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '지점 및 매장선택1', category: '원부자재 분석' },
    },
  },

  C2002: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '기간 선택', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '기간 선택', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '기간 선택', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '기간 선택', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '기간 선택', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '기간 선택', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '기간 선택', category: '광고핀 분석' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '기간 선택', category: '고객리뷰 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '기간 선택', category: '원부자재 분석' },
    },
  },

  C2003: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '오늘', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '오늘', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '오늘', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '오늘', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '오늘', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '오늘', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '오늘', category: '광고핀 분석' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '오늘', category: '고객리뷰 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '오늘', category: '원부자재 분석' },
    },
  },

  C2004: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '어제', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '어제', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '어제', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '어제', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '어제', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '어제', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '어제', category: '광고핀 분석' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '어제', category: '고객리뷰 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '어제', category: '원부자재 분석' },
    },
  },

  C2005: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '지난달', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '지난달', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '지난달', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '지난달', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '지난달', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '지난달', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '지난달', category: '광고핀 분석' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '지난달', category: '고객리뷰 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '지난달', category: '원부자재 분석' },
    },
  },

  C2010: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '홀 및 배달 선택', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '홀 및 배달 선택', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '홀 및 배달 선택', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '홀 및 배달 선택', category: '메뉴 판매현황' },
    },
  },

  C2011: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '홀 + 배달 전체', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '홀 + 배달 전체', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '홀 + 배달 전체', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '홀 + 배달 전체', category: '메뉴 판매현황' },
    },
  },

  C2012: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '홀 전체', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '홀 전체', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '홀 전체', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '홀 전체', category: '메뉴 판매현황' },
    },
  },

  C2013: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '카드', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '카드', category: '매장별 매출' },
    },
  },

  C2014: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '현금', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '현금', category: '매장별 매출' },
    },
  },

  C2015: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '기타', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '기타', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '기타', category: '채널별 매출' },
    },
  },

  C2016: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배달앱 전체', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배달앱 전체', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '배달앱 전체', category: '매장별 매출' },
    },
  },

  C2017: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배달의 민족', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배달의 민족', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '배달의 민족', category: '매장별 매출' },
    },
  },

  C2018: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배민1', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배민1', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '배민1', category: '매장별 매출' },
    },
  },

  C2019: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '쿠팡이츠', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '쿠팡이츠', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '쿠팡이츠', category: '매장별 매출' },
    },
  },

  C2020: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '요기요', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '요기요', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '요기요', category: '매장별 매출' },
    },
  },

  C2021: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '위메프오', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '위메프오', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '위메프오', category: '매장별 매출' },
    },
  },

  C2022: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '전화주문', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '전화주문', category: '매장별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '전화주문', category: '매장별 매출' },
    },
  },

  C2023: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배달비 유뮤 선택', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배달비 유뮤 선택', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '배달비 유뮤 선택', category: '채널별 매출' },
      [PATHS['analysisLocation']['url']]: { eventName: '배달비 유뮤 선택', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배달비 유뮤 선택', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배달비 유뮤 선택', category: '광고핀 분석' },
    },
  },

  C2024: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배달비 포함', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배달비 포함', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '배달비 포함', category: '채널별 매출' },
      [PATHS['analysisLocation']['url']]: { eventName: '배달비 포함', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배달비 포함', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배달비 포함', category: '광고핀 분석' },
    },
  },

  C2025: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '배달비 제외', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '배달비 제외', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '배달비 제외', category: '채널별 매출' },
      [PATHS['analysisLocation']['url']]: { eventName: '배달비 제외', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배달비 제외', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배달비 제외', category: '광고핀 분석' },
    },
  },

  C2026: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '지점 및 매장선택2', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '지점 및 매장선택2', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '지점 및 매장선택2', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '지점 및 매장선택2', category: '메뉴 판매현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '지점 및 매장선택2', category: '원부자재 분석' },
    },
  },

  C2027: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '주문 기준 선택', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '주문 기준 선택', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '주문 기준 선택', category: '체널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '주문 기준 선택', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '주문 기준 선택', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '주문 기준 선택', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '주문 기준 선택', category: '광고핀 분석' },
    },
  },

  C2028: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '주문액 기준', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '주문액 기준', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '주문액 기준', category: '체널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '주문액 기준', category: '매뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '주문액 기준', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '주문액 기준', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '주문액 기준', category: '광고핀 분석' },
    },
  },

  C2029: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '주문건 기준', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '주문건 기준', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '주문건 기준', category: '체널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '주문수량 기준', category: '매뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '주문건 기준', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '주문건 기준', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '주문건 기준', category: '광고핀 분석' },
    },
  },

  C2030: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '날짜 및 소비원가 선택', category: '기간별 매출' },
      [PATHS['analysisTime']['url']]: { eventName: '시간순 선택', category: '기간별 매출' },
      [PATHS['analysisWeekday']['url']]: { eventName: '요일/매출액/결제건 선택', category: '기간별 매출' },
      [PATHS['analysisMonth']['url']]: { eventName: '월순 선택', category: '기간별 매출' },
      [PATHS['analysisQuarter']['url']]: { eventName: '분기순 선택', category: '기간별 매출' },
      [PATHS['analysisYear']['url']]: { eventName: '연도순 선택', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '매출액/결제건 선택', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '매출액/결제건 선택', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '결제건 선택', category: '메뉴 판매현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '날짜순 선택 선택', category: '원부자재 분석' },
      [PATHS['analysisMaterialItem']['url']]: { eventName: '소비원가 순', category: '원부자재 분석' },
    },
  },

  C2031: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '최근 날짜순', category: '기간별 매출' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '최근 날짜순', category: '원부자재 분석' },
    },
  },

  C2032: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '과거 날짜순', category: '기간별 매출' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '과거 날짜순', category: '원부자재 분석' },
    },
  },

  C2033: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '매출액 높은순', category: '기간별 매출' },
      [PATHS['analysisWeekday']['url']]: { eventName: '매출액 높은순', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '매출액 높은순', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '매출액 높은순', category: '채널별 매출' },
    },
  },

  C2034: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '매출액 낮은순', category: '기간별 매출' },
      [PATHS['analysisWeekday']['url']]: { eventName: '매출액 낮은순', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '매출액 낮은순', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '매출액 낮은순', category: '채널별 매출' },
    },
  },

  C2035: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '일별_결제건 많은순', category: '기간별 매출' },
      [PATHS['analysisWeekday']['url']]: { eventName: '요일별_결제건 많은순', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '결제건 많은순', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '결제건 많은순', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '결제건 많은순', category: '메뉴 판매현황' },
    },
  },

  C2036: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '일별_결제건 적은순', category: '기간별 매출' },
      [PATHS['analysisWeekday']['url']]: { eventName: '요일별_결제건 적은순', category: '기간별 매출' },
      [PATHS['analysisOffice']['url']]: { eventName: '결제건 적은순', category: '매장별 매출' },
      [PATHS['analysisChannel']['url']]: { eventName: '결제건 적은순', category: '채널별 매출' },
      [PATHS['analysisMenu']['url']]: { eventName: '결제건 적은순', category: '메뉴 판매현황' },
    },
  },

  C2037: {
    childs: {
      [PATHS['analysisMaterialDate']['url']]: { eventName: '사용량분석_소비원가 높은순', category: '원부자재 분석' },
      [PATHS['analysisMaterialItem']['url']]: { eventName: '자재별소비량_소비원가 높은순', category: '원부자재 분석' },
    },
  },

  C2038: {
    childs: {
      [PATHS['analysisMaterialDate']['url']]: { eventName: '사용량분석_소비원가 낮은순', category: '원부자재 분석' },
      [PATHS['analysisMaterialItem']['url']]: { eventName: '자재별소비량_소비원가 낮은순', category: '원부자재 분석' },
    },
  },

  C2046: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '주문고객 기준', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '주문고객 기준', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '주문고객 기준', category: '광고핀 분석' },
    },
  },

  C2047: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '고객 분류', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '고객 분류', category: '광고핀 분석' },
    },
  },

  C2048: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '단골유형', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '단골유형', category: '광고핀 분석' },
    },
  },

  C2049: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '신규', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '신규', category: '광고핀 분석' },
    },
  },

  C2050: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '재주문', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '재주문', category: '광고핀 분석' },
    },
  },

  C2051: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '주소없음', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '주소없음', category: '광고핀 분석' },
    },
  },

  C2052: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '건 수', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '건 수', category: '광고핀 분석' },
    },
  },

  C2053: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '1건', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '1건', category: '광고핀 분석' },
    },
  },

  C2054: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '1건 이상', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '1건 이상', category: '광고핀 분석' },
    },
  },

  C2055: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '2건 이상', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '2건 이상', category: '광고핀 분석' },
    },
  },

  C2056: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '3건 이상', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '3건 이상', category: '광고핀 분석' },
    },
  },

  C2057: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '5건 이상', category: '배달지/고객분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '5건 이상', category: '광고핀 분석' },
    },
  },

  C2058: {
    childs: {
      [PATHS['analysisMenu']['url']]: { eventName: '메뉴 선택', category: '메뉴 판매현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '메뉴전체', category: '배달지/고객 분석' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '메뉴전체', category: '광고핀 분석' },
    },
  },

  C2059: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '배달의민족', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배달의민족', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배달의민족', category: '광고핀 분석' },
    },
  },

  C2060: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '배민1', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배민1', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배민1', category: '광고핀 분석' },
    },
  },

  C2061: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '쿠팡이츠', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '쿠팡이츠', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '쿠팡이츠', category: '광고핀 분석' },
    },
  },

  C2062: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '요기요', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '요기요', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '요기요', category: '광고핀 분석' },
    },
  },

  C2063: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '위메프오', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '위메프오', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '위메프오', category: '광고핀 분석' },
    },
  },

  C2064: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '배달특급', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '배달특급', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '배달특급', category: '광고핀 분석' },
    },
  },

  C2065: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '기타', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '기타', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '기타', category: '광고핀 분석' },
    },
  },

  C2066: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '전화주문', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '전화주문', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '전화주문', category: '광고핀 분석' },
    },
  },

  C2067: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '스마트플레이스', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '스마트플레이스', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '스마트플레이스', category: '광고핀 분석' },
    },
  },

  C2068: {
    childs: {
      [PATHS['analysisLocation']['url']]: { eventName: '땡겨요', category: '배달지/고객 분석' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '땡겨요', category: '동단위 주문/배달비' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '땡겨요', category: '광고핀 분석' },
    },
  },

  // C3000 이후는 Newnav
  C3002: {
    childs: {
      [PATHS['analysisDate']['url']]: { eventName: '기간별 매출', category: '매출분석' },
      [PATHS['analysisOffice']['url']]: { eventName: '매장별 매출', category: '매출분석' },
      [PATHS['analysisChannel']['url']]: { eventName: '채널별 매출', category: '매출분석' },
      [PATHS['analysisMenu']['url']]: { eventName: '메뉴 판매 현황', category: '실시간 분석 현황' },
      [PATHS['analysisLocation']['url']]: { eventName: '배달지/고객분석', category: '실시간 분석 현황' },
      [PATHS['analysisLocationDong']['url']]: { eventName: '동단위 주문/배달비', category: '실시간 분석 현황' },
      [PATHS['analysisLocationPin']['url']]: { eventName: '광고핀 분석', category: '실시간 분석 현황' },
      [PATHS['analysisCustomerReviewChannel']['url']]: { eventName: '고객리뷰 현황', category: '실시간 분석 현황' },
      [PATHS['analysisMaterialDate']['url']]: { eventName: '원부자재 분석', category: '원부자재 현황' },
      [PATHS['manageOffice']['url']]: { eventName: '매장 관리/현황', category: '가맹 관리' },
      [PATHS['manageUser']['url']]: { eventName: '관리자 현황', category: '가맹 관리' },
    },
  },

  // 모바일 앱,웹 M으로 시작
  M0001: { eventName: '기간별 매출', category: '메인' },
  M0002: { eventName: '일별', category: '기간별 매출' },
  M0003: { eventName: '시간대별', category: '기간별 매출' },
  M0004: { eventName: '요일별', category: '기간별 매출' },
  M0005: { eventName: '월별', category: '기간별 매출' },
  M0006: { eventName: '분기별', category: '기간별 매출' },
  M0007: { eventName: '연도별', category: '기간별 매출' },
  M0008: { eventName: '전체매장', category: '메인' },
  M0009: { eventName: '지도', category: '메인' },
  M0010: { eventName: '마이페이지', category: '메인' },

  M0101: { eventName: '매장별 매출', category: '메인' },

  M0201: { eventName: '채널별 매출', category: '메인' },
  M0202: { eventName: '전체', category: '채널별 매출' },
  M0203: { eventName: '배달의 민족', category: '채널별 매출' },
  M0204: { eventName: '카드(홀)', category: '채널별 매출' },
  M0205: { eventName: '쿠팡이츠', category: '채널별 매출' },
  M0206: { eventName: '요기요', category: '채널별 매출' },
  M0207: { eventName: '위메프오', category: '채널별 매출' },
  M0208: { eventName: '배민1', category: '채널별 매출' },
  M0209: { eventName: '전화주문', category: '채널별 매출' },
  M0210: { eventName: '기타(배달)', category: '채널별 매출' },
  M0211: { eventName: '땡겨요', category: '채널별 매출' },
  M0212: { eventName: '배달특급', category: '채널별 매출' },
  M0213: { eventName: '전화주문', category: '채널별 매출' },

  M0301: { eventName: '메뉴판매현황', category: '메인' },
  M0302: { eventName: '메인메뉴', category: '메뉴 판매현황' },
  M0303: { eventName: '단품메뉴', category: '메뉴 판매현황' },
  M0304: { eventName: '카테고리별', category: '메뉴 판매현황' },
  M0305: { eventName: '홀판매', category: '메뉴 판매현황' },
  M0306: { eventName: '재주문', category: '메뉴 판매현황' },
  M0307: { eventName: '신규주문', category: '메뉴 판매현황' },
  M0308: { eventName: '주소지미상', category: '메뉴 판매현황' },

  // 모바일 공통
  M1001: { eventName: '기간 선택', category: '기간별' },
  M1002: { eventName: '오늘', category: '기간별' },
  M1003: { eventName: '어제', category: '기간별' },
  M1004: { eventName: '지난달', category: '기간별' },

  M2001: { eventName: '기간 선택', category: '매장별' },
  M2002: { eventName: '오늘', category: '매장별' },
  M2003: { eventName: '어제', category: '매장별' },
  M2004: { eventName: '지난달', category: '매장별' },

  M3001: { eventName: '기간 선택', category: '채널별' },
  M3002: { eventName: '오늘', category: '채널별' },
  M3003: { eventName: '어제', category: '채널별' },
  M3004: { eventName: '지난달', category: '채널별' },

  M4001: { eventName: '기간 선택', category: '메뉴별' },
  M4002: { eventName: '오늘', category: '메뉴별' },
  M4003: { eventName: '어제', category: '메뉴별' },
  M4004: { eventName: '지난달', category: '메뉴별' },

  M0702: { eventName: '로그인완료', category: 'Funnel2', kakao: true, facebook: true, sendApp: true, sendAppName: 'SIGN_IN' },
  M0703: { eventName: '계정연동완료', category: 'Funnel3' },
  M0704: { eventName: '유/무료선택완료', category: 'Funnel4' },
  M0706: { eventName: '설치완료', category: 'Funnel6' },
  M0707: { eventName: '데이터검증완료', category: 'Funnel7' },
  M0708: { eventName: 'purchase', category: '', kakao: true, facebook: true },
  M0709: { eventName: '라이브', category: 'Funnel9' },
  M0710: { eventName: '미납중지', category: 'Funnel10' },
  M0711: { eventName: '해지', category: 'Funnel11' },
  M0712: { eventName: 'Error_Dvs', category: 'Funnel' },
  M0713: { eventName: 'Error_Dc', category: 'Funnel' },

  M0801: { eventName: '구글 스토어 설치', category: '구글애즈 이벤트페이지' },
  M0802: { eventName: '앱 스토어 설치', category: '구글애즈 이벤트페이지' },
  M0803: { eventName: '앱설치하고 이벤트 참여하기 완료', category: '구글애즈 이벤트페이지' },
  M0804: { eventName: '1개월 더! 이벤트 참여하기 완료', category: '구글애즈 이벤트페이지' },

  M0901: { eventName: '구글 스토어 설치', category: '아프니까사장이다 이벤트페이지' },
  M0902: { eventName: '앱 스토어 설치', category: '아프니까사장이다 이벤트페이지' },
  M0903: { eventName: '앱설치하고 이벤트 참여하기 완료', category: '아프니까사장이다 이벤트페이지' },
  M0904: { eventName: '1개월 더! 이벤트 참여하기 완료', category: '아프니까사장이다 이벤트페이지' },
  M0905: { eventName: '1개월 + 1개월 더! 이벤트 참여하기 완료', category: '아프니까사장이다 이벤트페이지' },

  // 자영업자 프로모션 랜딩 페이지
  Event_SM_G_Install_path: { eventName: '앱설치-구글 PC', category: '목표달성', kakao: 'Event_SM_G_Install_path', naver: true },
  Event_SM_A_Install_path: { eventName: '앱설치-애플 PC', category: '목표달성', kakao: 'Event_SM_A_Install_path', naver: true },
  Event_SM_Install: { eventName: '앱설치-이벤트참여 PC', category: '목표달성', kakao: 'Event_SM_Install', naver: true },
  Event_SM_Review: { eventName: '이벤트 참여-스토어리뷰 PC', category: '튜토리얼', kakao: 'Event_SM_Review', naver: true },
  Event_SM_Friend: { eventName: '이벤트 참여-친구초대 PC', category: '튜토리얼', kakao: 'Event_SM_Friend', naver: true },

  MO_Event_SM_G_Install_path: {
    eventName: '앱설치-구글 M',
    category: '목표달성',
    kakao: 'MO_Event_SM_G_Install_path',
    naver: true,
  },
  MO_Event_SM_A_Install_path: {
    eventName: '앱설치-애플 M',
    category: '목표달성',
    kakao: 'MO_Event_SM_A_Install_path',
    naver: true,
  },
  MO_Event_SM_Install: {
    eventName: '앱설치 이벤트 참여하기 ',
    category: '목표달성',
    kakao: 'MO_Event_SM_Install',
    naver: true,
  },
  MO_Event_SM_Review: { 
    eventName: '이벤트 참여-스토어리뷰 M',
    category: '튜토리얼', 
    kakao: 'MO_Event_SM_Review', 
    naver: true 
  },
  MO_Event_SM_Friend: {
    eventName: '이벤트 참여-친구초대 M',
    category: '튜토리얼',
    kakao: 'MO_Event_SM_Friend',
    naver: true,
  },
};
