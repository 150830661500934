import { userConstants } from '../constants';
import { userService } from '../services';

export const userActions = {
  companyUserList,
  franchiseBrandList,
  userProfileChange,
  myUserData,
  serviceNoticeList,
  getFaqList,
  completeTutorial,
  registUser,

  // 사용자관리
  getUserList,
  createMultiUser,
  createSingleUser,
  inviteSingleUser,
  inviteMultiUser,
  editUserInfo,
  disableUser,
  editUserAuth,
  editUserAuthInfo,
  withdrawalRequest,
  withdrawalCancel,
};

function companyUserList(companyCode) {
  return dispatch => {
    dispatch(request());

    userService.companyUserList(companyCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.COMPANY_USER_LIST_REQUEST };
  }
  function success(result) {
    return { type: userConstants.COMPANY_USER_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.COMPANY_USER_LIST_FAILURE, error };
  }
}

function franchiseBrandList(userSno, serviceType) {
  return dispatch => {
    dispatch(request());

    userService.franchiseBrandList(userSno, serviceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.FRANCHISE_BRAND_LIST_REQUEST };
  }
  function success(result) {
    return { type: userConstants.FRANCHISE_BRAND_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.FRANCHISE_BRAND_LIST_FAILURE, error };
  }
}

function userProfileChange(userSno, brandCode, officeSno, serviceType, isLogout = false) {
  return dispatch => {
    dispatch(request());

    userService.userProfileChange(userSno, brandCode, officeSno, serviceType, isLogout).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.USER_PROFILE_CHANGE_REQUEST };
  }
  function success(result) {
    return { type: userConstants.USER_PROFILE_CHANGE_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.USER_PROFILE_CHANGE_FAILURE, error };
  }
}

function myUserData(userSno, serviceType) {
  return dispatch => {
    dispatch(request());

    if (!userSno) {
      dispatch(failure());
      return;
    }

    userService.myUserData(userSno, serviceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.GET_MY_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.GET_MY_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.GET_MY_USER_FAILURE, error };
  }
}

function serviceNoticeList(companyCode, brandCode, areaCode) {
  return dispatch => {
    dispatch(request());

    userService.serviceNoticeList(companyCode, brandCode, areaCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.SERVICE_NOTICE_LIST_REQUEST };
  }
  function success(result) {
    return { type: userConstants.SERVICE_NOTICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.SERVICE_NOTICE_LIST_FAILURE, error };
  }
}

function getFaqList() {
  return dispatch => {
    dispatch(request());

    userService.getFaqList().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.GET_FAQ_LIST_REQUEST };
  }
  function success(result) {
    return { type: userConstants.GET_FAQ_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.GET_FAQ_LIST_FAILURE, error };
  }
}

function completeTutorial(userSno, tutorialBit) {
  return dispatch => {
    dispatch(request());

    userService.completeTutorial(userSno, tutorialBit).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.COMPLETE_TUTORIAL_REQUEST };
  }
  function success(result) {
    return { type: userConstants.COMPLETE_TUTORIAL_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.COMPLETE_TUTORIAL_FAILURE, error };
  }
}

function registUser(
  registType,
  phoneNumber,
  userName,
  email,
  password,
  companyName,
  brandName,
  officeCount,
  officeName,
  ceoName,
  businessNum,
  addressData,
  detailAddress,
  buinessFilePath,
  brandKey,
  memo,
  userKey,
  promotionCode,
  promotionType,
  checkList,
) {
  return dispatch => {
    dispatch(request());

    userService
      .registUser(
        registType,
        phoneNumber,
        userName,
        email,
        password,
        companyName,
        brandName,
        officeCount,
        officeName,
        ceoName,
        businessNum,
        addressData,
        detailAddress,
        buinessFilePath,
        brandKey,
        memo,
        userKey,
        promotionCode,
        promotionType,
        checkList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: userConstants.REGIST_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.REGIST_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.REGIST_USER_FAILURE, error };
  }
}

function getUserList(brandCode, areaList ) {
  return dispatch => {
    dispatch(request());

    userService.getUserList(brandCode, areaList ).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.GET_USER_LIST_REQUEST };
  }
  function success(result) {
    return { type: userConstants.GET_USER_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_LIST_FAILURE, error };
  }
}

function createMultiUser(brandCode, list) {
  return dispatch => {
    dispatch(request());

    userService.createMultiUser(brandCode, list).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.CREATE_MULTI_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.CREATE_MULTI_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.CREATE_MULTI_USER_FAILURE, error };
  }
}

function createSingleUser(userName, phoneNumber, email, brandCode, areaList, authCode, managementList) {

  return dispatch => {
    dispatch(request());

    userService.createSingleUser(userName, phoneNumber, email, brandCode, areaList, authCode, managementList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.CREATE_SINGLE_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.CREATE_SINGLE_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.CREATE_SINGLE_USER_FAILURE, error };
  }
}

function inviteSingleUser(idx, userSno) {
  return dispatch => {
    dispatch(request());

    userService.inviteSingleUser(idx, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.INVITE_SINGLE_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.INVITE_SINGLE_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.INVITE_SINGLE_USER_FAILURE, error };
  }
}

function inviteMultiUser(brandCode, userSno) {
  return dispatch => {
    dispatch(request());

    userService.inviteMultiUser(brandCode, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.INVITE_MULTI_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.INVITE_MULTI_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.INVITE_MULTI_USER_REQUEST, error };
  }
}

function editUserInfo(idx, userName, email, phoneNumber) {
  return dispatch => {
    dispatch(request());

    userService.editUserInfo(idx, userName, email, phoneNumber).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.EDIT_USER_INFO_REQUEST };
  }
  function success(result) {
    return { type: userConstants.EDIT_USER_INFO_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.INVITE_MULTI_USER_FAILURE, error };
  }
}

function disableUser(idx) {
  return dispatch => {
    dispatch(request());

    userService.disableUser(idx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.DISABLE_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.DISABLE_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.DISABLE_USER_FAILURE, error };
  }
}

function editUserAuth(idx, authCode, areaLit = []) {
  return dispatch => {
    dispatch(request());

    userService.editUserAuth(idx, authCode, areaLit).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.EDIT_USER_AUTH_REQUEST };
  }
  function success(result) {
    return { type: userConstants.EDIT_USER_AUTH_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.EDIT_USER_AUTH_FAILURE, error };
  }
}


function editUserAuthInfo(idx, AccessFuncBit, areaList, authCode) {

  return dispatch => {
    dispatch(request());

    userService.editUserAuthInfo(idx ,AccessFuncBit, areaList, authCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.EDIT_USER_ACCESS_AUTH_REQUEST };
  }
  function success(result) {
    return { type: userConstants.EDIT_USER_ACCESS_AUTH_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.EDIT_USER_ACCESS_AUTH_FAILURE, error };
  }
}

function withdrawalRequest(userSno, serviceType, withdrawalReasonCode, withdrawalOtherReason) {
  return dispatch => {
    dispatch(request());

    userService.withdrawalRequest(userSno, serviceType, withdrawalReasonCode, withdrawalOtherReason).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.WITHDRAWL_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.WITHDRAWL_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.WITHDRAWL_USER_FAILURE, error };
  }
}

function withdrawalCancel(userSno) {
  return dispatch => {
    dispatch(request());

    userService.withdrawalCancel(userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userConstants.WITHDRAWL_CANCEL_USER_REQUEST };
  }
  function success(result) {
    return { type: userConstants.WITHDRAWL_CANCEL_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: userConstants.WITHDRAWL_CANCEL_USER_FAILURE, error };
  }
}
