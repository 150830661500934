import './polyfills';

import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { store } from './helpers/Store';
import './assets/base.scss';
import { RecoilRoot } from 'recoil';
import App from './App';

const history = createBrowserHistory();

const handleChangePage = () => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  let container = document.getElementById('container');
  if (container) {
    container.scrollTo(0, 0);
  }
}

history.listen(location => {
  handleChangePage(location);
});

const rootElement = document.getElementById('root');
const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <RecoilRoot>
        <HashRouter history={history}>
          <Component />
        </HashRouter>
      </RecoilRoot>
    </Provider>,
    rootElement,
  );
};

renderApp(App);