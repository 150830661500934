import { useTranslation } from '../locales';
import { ASC_KEY, DESC_KEY } from './Common';

export const sortConstants = {
  SalesOffice: [
    { ['tot_sale' + DESC_KEY]: '매출액 높은순' },
    { ['tot_sale' + ASC_KEY]: '매출액 낮은순' },
    { ['total_sale_count' + DESC_KEY]: '결제건 많은순' },
    { ['total_sale_count' + ASC_KEY]: '결제건 적은순' },
  ],
  SalesDate: [
    { ['date_ymd' + DESC_KEY]: '최근 날짜순' },
    { ['date_ymd' + ASC_KEY]: '과거 날짜순' },
    { ['tot_sale' + DESC_KEY]: '매출액 높은순' },
    { ['tot_sale' + ASC_KEY]: '매출액 낮은순' },
    { ['total_sale_count' + DESC_KEY]: '결제건 많은순' },
    { ['total_sale_count' + ASC_KEY]: '결제건 적은순' },
  ],
  SalesMenu: [
    { ['sale_menu_cnt' + DESC_KEY]: '판매수량 높은순' },
    { ['sale_menu_cnt' + ASC_KEY]: '판매수량 낮은순' },
    { ['sale_menu_price' + DESC_KEY]: '판매액 높은순' },
    { ['sale_menu_price' + ASC_KEY]: '판매액 낮은순' },
  ],
  SalesWeekDay: [
    // 홀_전체지표, 홀_상세매장, 홀_상세메뉴(뷰타입2가지) 모두 동일
    { ['week_day' + ASC_KEY]: '요일순' },
    { ['avg_sale' + DESC_KEY]: '매출액 높은순(평균)' },
    { ['avg_sale' + ASC_KEY]: '매출액 낮은순(평균)' },
    { ['tot_sale' + DESC_KEY]: '매출액 높은순(전체)' },
    { ['tot_sale' + ASC_KEY]: '매출액 낮은순(전체)' },
    { ['avg_sale_count' + DESC_KEY]: '결제건 높은순(평균)' },
    { ['avg_sale_count' + ASC_KEY]: '결제건 낮은순(평균)' },
    { ['total_sale_count' + DESC_KEY]: '결제건 높은순(전체)' },
    { ['total_sale_count' + ASC_KEY]: '결제건 낮은순(전체)' },
  ],
  DetailMenuSalesWeekDay: [
    // 홀_전체지표, 홀_상세매장, 홀_상세메뉴(뷰타입2가지) 모두 동일
    { ['week_day' + ASC_KEY]: '요일순' },
    { ['avg_sale_count' + DESC_KEY]: '판매수량 높은순(평균)' },
    { ['avg_sale_count' + ASC_KEY]: '판매수량 낮은순(평균)' },
    { ['total_sale_count' + DESC_KEY]: '판매수량 높은순(전체)' },
    { ['total_sale_count' + ASC_KEY]: '판매수량 낮은순(전체)' },
    { ['avg_sale' + DESC_KEY]: '판매액 높은순(평균)' },
    { ['avg_sale' + ASC_KEY]: '판매액 낮은순(평균)' },
    { ['tot_sale' + DESC_KEY]: '판매액 높은순(전체)' },
    { ['tot_sale' + ASC_KEY]: '판매액 낮은순(전체)' },
  ],
  DeliveryWeekDay: [
    { ['week_day' + ASC_KEY]: '요일순' },
    { ['avg_order_sale' + DESC_KEY]: '주문액 높은순(평균)' },
    { ['avg_order_sale' + ASC_KEY]: '주문액 낮은순(평균)' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순(전체)' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순(전체)' },
    { ['avg_order_count' + DESC_KEY]: '주문건 높은순(평균)' },
    { ['avg_order_count' + ASC_KEY]: '주문건 낮은순(평균)' },
    { ['order_count' + DESC_KEY]: '주문건 높은순(전체)' },
    { ['order_count' + ASC_KEY]: '주문건 낮은순(전체)' },
    { ['avg_customer_count' + DESC_KEY]: '주문고객 높은순(평균)' },
    { ['avg_customer_count' + ASC_KEY]: '주문고객 낮은순(평균)' },
    { ['customer_count' + DESC_KEY]: '주문고객 높은순(전체)' },
    { ['customer_count' + ASC_KEY]: '주문고객 낮은순(전체)' },
  ],
  DetailMenuDeliveryWeekDay: [
    // 전체 배달지표 모두 동일 (뷰타입 : 액, 건, 주문고객)
    { ['week_day' + ASC_KEY]: '요일순' },
    { ['avg_order_count' + DESC_KEY]: '주문수량 높은순(평균)' },
    { ['avg_order_count' + ASC_KEY]: '주문수량 낮은순(평균)' },
    { ['order_count' + DESC_KEY]: '주문수량 높은순(전체)' },
    { ['order_count' + ASC_KEY]: '주문수량 낮은순(전체)' },
    { ['avg_order_sale' + DESC_KEY]: '주문액 높은순(평균)' },
    { ['avg_order_sale' + ASC_KEY]: '주문액 낮은순(평균)' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순(전체)' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순(전체)' },
    { ['avg_customer_count' + DESC_KEY]: '주문고객 높은순(평균)' },
    { ['avg_customer_count' + ASC_KEY]: '주문고객 낮은순(평균)' },
    { ['customer_count' + DESC_KEY]: '주문고객 높은순(전체)' },
    { ['customer_count' + ASC_KEY]: '주문고객 낮은순(전체)' },
  ],
  MenuDate: [
    { ['date_ymd' + DESC_KEY]: '최근 날짜순' },
    { ['date_ymd' + ASC_KEY]: '과거 날짜순' },
    { ['sale_menu_cnt' + DESC_KEY]: '판매수량 높은순' },
    { ['sale_menu_cnt' + ASC_KEY]: '판매수량 낮은순' },
    { ['sale_menu_price' + DESC_KEY]: '판매액 높은순' },
    { ['sale_menu_price' + ASC_KEY]: '판매액 낮은순' },
  ],
  MenuTime: [],
  DeliveryOffice: [
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['re_order_percent' + DESC_KEY]: '재주문율 높은순' },
    { ['re_order_percent' + ASC_KEY]: '재주문율 낮은순' },
    { ['re_order_sale' + DESC_KEY]: '재주문액 높은순' },
    { ['re_order_sale' + ASC_KEY]: '재주문액 낮은순' },
    { ['new_order_sale' + DESC_KEY]: '신규주문액 높은순' },
    { ['new_order_sale' + ASC_KEY]: '신규주문액 낮은순' },
    { ['order_customer' + DESC_KEY]: '주문건 많은순' },
    { ['order_customer' + ASC_KEY]: '주문건 적은순' },
    { ['re_order_customer' + DESC_KEY]: '재주문건 많은순' },
    { ['re_order_customer' + ASC_KEY]: '재주문건 적은순' },
    { ['new_order_customer' + DESC_KEY]: '신규주문건 많은순' },
    { ['new_order_customer' + ASC_KEY]: '신규주문건 적은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
    { ['re_customer_cnt' + DESC_KEY]: '재주문고객 많은순' },
    { ['re_customer_cnt' + ASC_KEY]: '재주문고객 적은순' },
    { ['new_customer_cnt' + DESC_KEY]: '신규주문고객 많은순' },
    { ['new_customer_cnt' + ASC_KEY]: '신규주문고객 적은순' },
  ],
  DeliveryMenu: [
    { ['order_customer' + DESC_KEY]: '주문수량 많은순' },
    { ['order_customer' + ASC_KEY]: '주문수량 적은순' },
    { ['re_order_customer' + DESC_KEY]: '재주문수량 많은순' },
    { ['re_order_customer' + ASC_KEY]: '재주문수량 적은순' },
    { ['new_order_customer' + DESC_KEY]: '신규주문수량 많은순' },
    { ['new_order_customer' + ASC_KEY]: '신규주문수량 적은순' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['re_order_sale' + DESC_KEY]: '재주문액 높은순' },
    { ['re_order_sale' + ASC_KEY]: '재주문액 낮은순' },
    { ['new_order_sale' + DESC_KEY]: '신규주문액 높은순' },
    { ['new_order_sale' + ASC_KEY]: '신규주문액 낮은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
    { ['re_customer_cnt' + DESC_KEY]: '재주문고객 많은순' },
    { ['re_customer_cnt' + ASC_KEY]: '재주문고객 적은순' },
    { ['new_customer_cnt' + DESC_KEY]: '신규주문고객 많은순' },
    { ['new_customer_cnt' + ASC_KEY]: '신규주문고객 적은순' },
  ],
  DeliveryMenuOption: [
    { ['order_customer' + DESC_KEY]: '주문수량 많은순' },
    { ['order_customer' + ASC_KEY]: '주문수량 적은순' },
    { ['re_order_customer' + DESC_KEY]: '재주문수량 많은순' },
    { ['re_order_customer' + ASC_KEY]: '재주문수량 적은순' },
    { ['new_order_customer' + DESC_KEY]: '신규주문수량 많은순' },
    { ['new_order_customer' + ASC_KEY]: '신규주문수량 적은순' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['re_order_sale' + DESC_KEY]: '재주문액 높은순' },
    { ['re_order_sale' + ASC_KEY]: '재주문액 낮은순' },
    { ['new_order_sale' + DESC_KEY]: '신규주문액 높은순' },
    { ['new_order_sale' + ASC_KEY]: '신규주문액 낮은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
    { ['re_customer_cnt' + DESC_KEY]: '재주문고객 많은순' },
    { ['re_customer_cnt' + ASC_KEY]: '재주문고객 적은순' },
    { ['new_customer_cnt' + DESC_KEY]: '신규주문고객 많은순' },
    { ['new_customer_cnt' + ASC_KEY]: '신규주문고객 적은순' },
  ],
  DeliveryDate: [
    { ['pay_date' + DESC_KEY]: '최근 날짜순' },
    { ['pay_date' + ASC_KEY]: '과거 날짜순' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['re_order_sale' + DESC_KEY]: '재주문액 높은순' },
    { ['re_order_sale' + ASC_KEY]: '재주문액 낮은순' },
    { ['new_order_sale' + DESC_KEY]: '신규주문액 높은순' },
    { ['new_order_sale' + ASC_KEY]: '신규주문액 낮은순' },
    { ['order_customer' + DESC_KEY]: '주문건 많은순' },
    { ['order_customer' + ASC_KEY]: '주문건 적은순' },
    { ['re_order_customer' + DESC_KEY]: '재주문건 많은순' },
    { ['re_order_customer' + ASC_KEY]: '재주문건 적은순' },
    { ['new_order_customer' + DESC_KEY]: '신규주문건 많은순' },
    { ['new_order_customer' + ASC_KEY]: '신규주문건 적은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
    { ['re_customer_cnt' + DESC_KEY]: '재주문고객 많은순' },
    { ['re_customer_cnt' + ASC_KEY]: '재주문고객 적은순' },
    { ['new_customer_cnt' + DESC_KEY]: '신규주문고객 많은순' },
    { ['new_customer_cnt' + ASC_KEY]: '신규주문고객 적은순' },
  ],
  DetailMenuDeliveryDate: [
    { ['pay_date' + DESC_KEY]: '최근 날짜순' },
    { ['pay_date' + ASC_KEY]: '과거 날짜순' },
    { ['order_customer' + DESC_KEY]: '주문수량 많은순' },
    { ['order_customer' + ASC_KEY]: '주문수량 적은순' },
    { ['re_order_customer' + DESC_KEY]: '재주문수량 많은순' },
    { ['re_order_customer' + ASC_KEY]: '재주문수량 적은순' },
    { ['new_order_customer' + DESC_KEY]: '신규주문수량 많은순' },
    { ['new_order_customer' + ASC_KEY]: '신규주문수량 적은순' },
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['re_order_sale' + DESC_KEY]: '재주문액 높은순' },
    { ['re_order_sale' + ASC_KEY]: '재주문액 낮은순' },
    { ['new_order_sale' + DESC_KEY]: '신규주문액 높은순' },
    { ['new_order_sale' + ASC_KEY]: '신규주문액 낮은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
    { ['re_customer_cnt' + DESC_KEY]: '재주문고객 많은순' },
    { ['re_customer_cnt' + ASC_KEY]: '재주문고객 적은순' },
    { ['new_customer_cnt' + DESC_KEY]: '신규주문고객 많은순' },
    { ['new_customer_cnt' + ASC_KEY]: '신규주문고객 적은순' },
  ],
  DeliveryOrderApp: [
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['order_customer' + DESC_KEY]: '주문건 많은순' },
    { ['order_customer' + ASC_KEY]: '주문건 적은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
  ],

  DetailOfficeDeliveryOrderApp: [
    { ['order_sale' + DESC_KEY]: '주문액 높은순' },
    { ['order_sale' + ASC_KEY]: '주문액 낮은순' },
    { ['order_customer' + DESC_KEY]: '주문건 많은순' },
    { ['order_customer' + ASC_KEY]: '주문건 적은순' },
    { ['customer_cnt' + DESC_KEY]: '주문고객 많은순' },
    { ['customer_cnt' + ASC_KEY]: '주문고객 적은순' },
  ],
  // tmp 데이터값
  DeliveryLocation: [{ '': undefined }],
  ReviewOffice: [{ ['review_date' + DESC_KEY]: '최근 날짜순' }, { ['review_date' + ASC_KEY]: '과거 날짜순' }],
  ReviewBrand: [{ ['review_date' + DESC_KEY]: '최근 날짜순' }, { ['review_date' + ASC_KEY]: '과거 날짜순' }],
  RSSOffice: [{ ['rss_date' + DESC_KEY]: '최근 날짜순' }, { ['rss_date' + ASC_KEY]: '과거 날짜순' }],
  RSSBrand: [{ ['rss_date' + DESC_KEY]: '최근 날짜순' }, { ['rss_date' + ASC_KEY]: '과거 날짜순' }],
  MenuAnalysis: [
    { ['x' + DESC_KEY]: '공헌이익 높은순' },
    { ['x' + ASC_KEY]: '공헌이익 낮은순' },
    { ['y' + DESC_KEY]: '판매비중 높은순' },
    { ['y' + ASC_KEY]: '판매비중 낮은순' },
  ],
  UseMaterial: [
    { ['use_brand_cost' + DESC_KEY]: '소비원가 높은순' },
    { ['use_brand_cost' + ASC_KEY]: '소비원가 낮은순' },
    { ['material_used' + DESC_KEY]: '소비량 많은순' },
    { ['material_used' + ASC_KEY]: ' 소비량 적은순' },
  ],
  MaterialOffice: [
    { ['use_brand_cost' + DESC_KEY]: '소비원가 높은순' },
    { ['use_brand_cost' + ASC_KEY]: '소비원가 낮은순' },
    { ['material_used' + DESC_KEY]: '소비량 많은순' },
    { ['material_used' + ASC_KEY]: ' 소비량 적은순' },
  ],
  MaterialDate: [
    { ['default_date' + DESC_KEY]: '최근 날짜순' },
    { ['default_date' + ASC_KEY]: '과거 날짜순' },
    { ['use_brand_cost' + DESC_KEY]: '소비원가 높은순' },
    { ['use_brand_cost' + ASC_KEY]: '소비원가 낮은순' },
  ],
  MaterialMenu: [
    { ['use_brand_cost' + DESC_KEY]: '소비원가 높은순' },
    { ['use_brand_cost' + ASC_KEY]: '소비원가 낮은순' },
    { ['material_used' + DESC_KEY]: '소비량 많은순' },
    { ['material_used' + ASC_KEY]: ' 소비량 적은순' },
  ],
  MenuManagement: [
    { ['brand_menu_name' + ASC_KEY]: '메뉴명 ㄱ~ㅎ' },
    { ['brand_menu_name' + DESC_KEY]: '메뉴명 ㅎ~ㄱ' },
    { ['brand_menu_price' + DESC_KEY]: '판매액 높은순' },
    { ['brand_menu_price' + ASC_KEY]: '판매액 낮은순' },
  ],
};

export const getSortConstants = sortType => {
  const t = useTranslation();

  const sortConstantsNew = {
    DailySales: {
      ['payYmd' + DESC_KEY]: { name: t['소팅-최근날짜순'] },
      ['payYmd' + ASC_KEY]: { name: t['소팅-과거날짜순'] },
      ['sumPayAmount' + DESC_KEY]: { name: t['소팅-매출액높은순'] },
      ['sumPayAmount' + ASC_KEY]: { name: t['소팅-매출액낮은순'] },
      ['sumOrderCnt' + DESC_KEY]: { name: t['소팅-결제건많은순'] },
      ['sumOrderCnt' + ASC_KEY]: { name: t['소팅-결제건적은순'] },
    },
    TimeSales: {
      ['payTime' + ASC_KEY]: { name: t['소팅-최근시간순'] },
      ['payTime' + DESC_KEY]: { name: t['소팅-과거시간순'] },
    },
    WeekDaySales: {
      ['dayCode' + ASC_KEY]: { name: t['소팅-요일순'] },
      ['sumPayAmount' + DESC_KEY]: { name: t['소팅-매출액높은순'] },
      ['sumPayAmount' + ASC_KEY]: { name: t['소팅-매출액낮은순'] },
      ['sumOrderCnt' + DESC_KEY]: { name: t['소팅-결제건많은순'] },
      ['sumOrderCnt' + ASC_KEY]: { name: t['소팅-결제건적은순'] },
    },
    officeSales: {
      ['sumPayAmount' + DESC_KEY]: { name: t['소팅-매출액높은순'], viewTypeFrom: 3 },
      ['sumPayAmount' + ASC_KEY]: { name: t['소팅-매출액낮은순'] },
      ['sumOrderCnt' + DESC_KEY]: { name: t['소팅-결제건많은순'], viewTypeFrom: 1 },
      ['sumOrderCnt' + ASC_KEY]: { name: t['소팅-결제건적은순'] },
    },
    OrderAppSales: {
      ['totPayAmount' + DESC_KEY]: { name: t['소팅-매출액높은순'], viewTypeFrom: 3 },
      ['totPayAmount' + ASC_KEY]: { name: t['소팅-매출액낮은순'] },
      ['totOrderCnt' + DESC_KEY]: { name: t['소팅-결제건많은순'], viewTypeFrom: 1 },
      ['totOrderCnt' + ASC_KEY]: { name: t['소팅-결제건적은순'] },
    },
    OrderAppSalesDetail: {
      ['name' + DESC_KEY]: { name: t['소팅-최근날짜순'] },
      ['name' + ASC_KEY]: { name: t['소팅-과거날짜순'] },
      ['totPayAmount' + DESC_KEY]: { name: t['소팅-매출액높은순'] },
      ['totPayAmount' + ASC_KEY]: { name: t['소팅-매출액낮은순'] },
      ['totOrderCnt' + DESC_KEY]: { name: t['소팅-결제건많은순'] },
      ['totOrderCnt' + ASC_KEY]: { name: t['소팅-결제건적은순'] },
    },
    MenuSales: {
      ['totOrderCnt' + DESC_KEY]: { name: t['소팅-주문수량많은순'], viewTypeFrom: 1 },
      ['totOrderCnt' + ASC_KEY]: { name: t['소팅-주문수량적은순'] },
      ['totPayAmount' + DESC_KEY]: { name: t['소팅-주문액많은순'], viewTypeFrom: 3 },
      ['totPayAmount' + ASC_KEY]: { name: t['소팅-주문액낮은순'] },
    },
    MaterialDate: {
      ['default_date' + DESC_KEY]: { name: t['소팅-최근날짜순'] },
      ['default_date' + ASC_KEY]: { name: t['소팅-과거날짜순'] },
      ['use_brand_cost' + DESC_KEY]: { name: '소비원가 높은순' },
      ['use_brand_cost' + ASC_KEY]: { name: '소비원가 낮은순' },
    },
    MaterialItem: {
      ['use_brand_cost' + DESC_KEY]: { name: '소비원가 높은순' },
      ['use_brand_cost' + ASC_KEY]: { name: '소비원가 낮은순' },
    },
    officeReviewList: {
      ['discontentPercent' + DESC_KEY]: { name: '만족도 낮은순' },
      ['discontentPercent' + ASC_KEY]: { name: '만족도 높은순' },
      ['totalReviewCnt' + ASC_KEY]: { name: '리뷰 적은순' },
      ['totalReviewCnt' + DESC_KEY]: { name: '리뷰 많은순' },
    },
    MonthlySales: {
      ['dateYm' + DESC_KEY]: { name: t['소팅-최근월순'] },
      ['dateYm' + ASC_KEY]: { name: t['소팅-과거월순'] },
    },
    QuarterlySales: {
      ['dateYq' + DESC_KEY]: { name: t['소팅-최근분기순'] },
      ['dateYq' + ASC_KEY]: { name: t['소팅-과거분기순'] },
    },
    YearlySales: {
      ['year' + DESC_KEY]: { name: t['소팅-최근연도순'] },
      ['year' + ASC_KEY]: { name: t['소팅-과거연도순'] },
    },
    OrderApp: {
      ['regDate' + DESC_KEY]: { name: t['소팅-매장등록순'] },
      ['normalCnt' + DESC_KEY]: { name: t['소팅-정상연동많은순'] },
      ['errorCnt' + DESC_KEY]: { name: t['소팅-오류많은순'] },
      ['unlinkCnt' + DESC_KEY]: { name: t['소팅-미연동많은순'] },
      ['accountCnt' + DESC_KEY]: { name: t['소팅-계정오류많은순'] },
      ['matchCnt' + DESC_KEY]: { name: t['소팅-매칭오류많은순'] },
      ['inactiveCnt' + DESC_KEY]: { name: t['소팅-휴면오류많은순'] },
      ['exceptCnt' + DESC_KEY]: { name: t['소팅-연동제외많은순'] },
    },
  };

  return sortConstantsNew[sortType];
};
