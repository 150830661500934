export const userConstants = {
  RESETSTATE: 'RESET_STATE',

  COMPANY_USER_LIST_REQUEST: 'COMPANY_USER_LIST_REQUEST',
  COMPANY_USER_LIST_SUCCESS: 'COMPANY_USER_LIST_SUCCESS',
  COMPANY_USER_LIST_FAILURE: 'COMPANY_USER_LIST_FAILURE',

  FRANCHISE_BRAND_LIST_REQUEST: 'FRANCHISE_BRAND_LIST_REQUEST',
  FRANCHISE_BRAND_LIST_SUCCESS: 'FRANCHISE_BRAND_LIST_SUCCESS',
  FRANCHISE_BRAND_LIST_FAILURE: 'FRANCHISE_BRAND_LIST_FAILURE',

  USER_PROFILE_CHANGE_REQUEST: 'USER_PROFILE_CHANGE_REQUEST',
  USER_PROFILE_CHANGE_SUCCESS: 'USER_PROFILE_CHANGE_SUCCESS',
  USER_PROFILE_CHANGE_FAILURE: 'USER_PROFILE_CHANGE_FAILURE',

  GET_MY_USER_REQUEST: 'GET_MY_USER_REQUEST',
  GET_MY_USER_SUCCESS: 'GET_MY_USER_SUCCESS',
  GET_MY_USER_FAILURE: 'GET_MY_USER_FAILURE',

  SERVICE_NOTICE_LIST_REQUEST: 'SERVICE_NOTICE_LIST_REQUEST',
  SERVICE_NOTICE_LIST_SUCCESS: 'SERVICE_NOTICE_LIST_SUCCESS',
  SERVICE_NOTICE_LIST_FAILURE: 'SERVICE_NOTICE_LIST_FAILURE',

  GET_FAQ_LIST_REQUEST: 'GET_FAQ_LIST_REQUEST',
  GET_FAQ_LIST_SUCCESS: 'GET_FAQ_LIST_SUCCESS',
  GET_FAQ_LIST_FAILURE: 'GET_FAQ_LIST_FAILURE',

  COMPLETE_TUTORIAL_REQUEST: 'COMPLETE_TUTORIAL_REQUEST',
  COMPLETE_TUTORIAL_SUCCESS: 'COMPLETE_TUTORIAL_SUCCESS',
  COMPLETE_TUTORIAL_FAILURE: 'COMPLETE_TUTORIAL_FAILURE',

  REGIST_USER_RESET: 'REGIST_USER_RESET',
  REGIST_USER_REQUEST: 'REGIST_USER_REQUEST',
  REGIST_USER_SUCCESS: 'REGIST_USER_SUCCESS',
  REGIST_USER_FAILURE: 'REGIST_USER_FAILURE',

  GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

  CREATE_MULTI_USER_RESET: 'CREATE_MULTI_USER_RESET',
  CREATE_MULTI_USER_REQUEST: 'CREATE_MULTI_USER_REQUEST',
  CREATE_MULTI_USER_SUCCESS: 'CREATE_MULTI_USER_SUCCESS',
  CREATE_MULTI_USER_FAILURE: 'CREATE_MULTI_USER_FAILURE',

  CREATE_SINGLE_USER_RESET: 'CREATE_SINGLE_USER_RESET',
  CREATE_SINGLE_USER_REQUEST: 'CREATE_SINGLE_USER_REQUEST',
  CREATE_SINGLE_USER_SUCCESS: 'CREATE_SINGLE_USER_SUCCESS',
  CREATE_SINGLE_USER_FAILURE: 'CREATE_SINGLE_USER_FAILURE',

  INVITE_SINGLE_USER_RESET: 'INVITE_SINGLE_USER_RESET',
  INVITE_SINGLE_USER_REQUEST: 'INVITE_SINGLE_USER_REQUEST',
  INVITE_SINGLE_USER_SUCCESS: 'INVITE_SINGLE_USER_SUCCESS',
  INVITE_SINGLE_USER_FAILURE: 'INVITE_SINGLE_USER_FAILURE',

  INVITE_MULTI_USER_RESET: 'INVITE_MULTI_USER_RESET',
  INVITE_MULTI_USER_REQUEST: 'INVITE_MULTI_USER_REQUEST',
  INVITE_MULTI_USER_SUCCESS: 'INVITE_MULTI_USER_SUCCESS',
  INVITE_MULTI_USER_FAILURE: 'INVITE_MULTI_USER_FAILURE',

  EDIT_USER_INFO_RESET: 'EDIT_USER_INFO_RESET',
  EDIT_USER_INFO_REQUEST: 'EDIT_USER_INFO_REQUEST',
  EDIT_USER_INFO_SUCCESS: 'EDIT_USER_INFO_SUCCESS',
  EDIT_USER_INFO_FAILURE: 'EDIT_USER_INFO_FAILURE',

  DISABLE_USER_RESET: 'DISABLE_USER_RESET',
  DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
  DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
  DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',

  EDIT_USER_AUTH_RESET: 'EDIT_USER_AUTH_RESET',
  EDIT_USER_AUTH_REQUEST: 'EDIT_USER_AUTH_REQUEST',
  EDIT_USER_AUTH_SUCCESS: 'EDIT_USER_AUTH_SUCCESS',
  EDIT_USER_AUTH_FAILURE: 'EDIT_USER_AUTH_FAILURE',

  EDIT_USER_ACCESS_AUTH_RESET: 'EDIT_USER_ACCESS_AUTH_RESET',
  EDIT_USER_ACCESS_AUTH_REQUEST: 'EDIT_USER_ACCESS_AUTH_REQUEST',
  EDIT_USER_ACCESS_AUTH_SUCCESS: 'EDIT_USER_ACCESS_AUTH_SUCCESS',
  EDIT_USER_ACCESS_AUTH_FAILURE: 'EDIT_USER_ACCESS_AUTH_FAILURE',

  WITHDRAWL_USER_REQUEST: 'WITHDRAWL_USER_REQUEST',
  WITHDRAWL_USER_SUCCESS: 'WITHDRAWL_USER_SUCCESS',
  WITHDRAWL_USER_FAILURE: 'WITHDRAWL_USER_FAILURE',

  WITHDRAWL_CANCEL_USER_REQUEST: 'WITHDRAWL_CANCEL_USER_REQUEST',
  WITHDRAWL_CANCEL_USER_SUCCESS: 'WITHDRAWL_CANCEL_USER_SUCCESS',
  WITHDRAWL_CANCEL_USER_FAILURE: 'WITHDRAWL_CANCEL_USER_FAILURE'
};
