import { getMyUserData } from './LoadData';

/**
 * @module StrConverter
 */

/**
 * @function
 * @description 휴대폰번호 형식 유효성 검사 (0으로 시작 - 3자리 내지 4자리 - 4자리)
 * @param {String} val
 * @param {String} use
 * @return {Object} moment객체
 */
export const testPhoneRegex = (val, useHypen = true) => {
  if (useHypen === false) {
    return val.length >= 10 && val.length <= 11;
  }

  let regex = /^\d{2,3}-\d{3,4}-\d{4}$/;

  return regex.test(String(val));
};

/**
 * @description 휴대폰번호에 하이픈 추가
 * @param {*} input
 */
export const setHypenPhoneNum = input => {
  if (!input) {
    return input;
  }

  input = input.replace(/[^0-9]/g, '');

  var tmp = '';

  if (input.length < 4) {
    return input;
  } else if (input.length < 8) {
    tmp += input.substr(0, 3);
    tmp += '-';
    tmp += input.substr(3);
    return tmp;
  } else if (input.length === 10) {
    tmp += input.substr(0, 3);
    tmp += '-';
    tmp += input.substr(3, 3);
    tmp += '-';
    tmp += input.substr(6, 10);
    return tmp;
  } else {
    tmp += input.substr(0, 3);
    tmp += '-';
    tmp += input.substr(3, 4);
    tmp += '-';
    tmp += input.substr(7);
    return tmp.substr(0, 13);
  }
};

/**
 * @description 사업자번호 하이픈 추가
 * @param {*} input
 */
export const setHypenBusinessNum = input => {
  if (!input) {
    return input;
  }

  input = input.replace(/[^0-9]/g, '');

  var tmp = '';
  if (input.length < 4) {
    return input;
  } else if (input.length < 7) {
    tmp += input.substr(0, 3);
    tmp += '-';
    tmp += input.substr(3);
    return tmp;
  } else {
    tmp += input.substr(0, 3);
    tmp += '-';
    tmp += input.substr(3, 2);
    tmp += '-';
    tmp += input.substr(5);

    return tmp.substr(0, 12);
  }
};

/**
 * @description 카드번호 하이픈 추가
 * @param {*} input
 */
export const setHypenCreditCardNumber = (input, withSpace) => {
  if (!input) {
    return input;
  }

  const regexChar = withSpace ? ' - ' : '-';

  input = input.split('-').join(''); // remove hyphens

  return input.match(new RegExp('.{1,4}', 'g')).join(regexChar);
};

/**
 * @description 휴대폰번호 마스킹
 * @param {*} input
 */
export const maskingPhoneNum = input => {
  if (!input) {
    return input;
  }

  input = setHypenPhoneNum(input);

  return input.replace(/-[0-9]{3,5}-/g, '-****-');
};

/**
 * @description 하이픈 제거
 * @param {*} input
 */
export const replaceHypen = input => {
  if (!input) {
    return;
  }

  return input.replace(/[-]/g, '');
};

export const hypenToDot = input => {
  if (!input) {
    return input;
  }

  return input.replace(/[-]/g, '.');
};

/**
 * @description 금액 표시
 * @param {*} input
 */
export const mComma = input => {
  if (!input || input === 'NaN') {
    return 0;
  }

  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * @description 매장명에서 브랜드명 제외
 * @param {*} officeName
 */
export const replaceBrandName = officeName => {
  var userData = getMyUserData();

  if (!officeName || !userData || !userData['brand_name']) {
    return officeName;
  }

  let name = officeName.replace(userData['brand_name'], '').replace(/ /gi, '');
  if (name.trim().length <= 0) {
    return officeName;
  }

  return name;
};

/**
 * @description Text 말줄임 처리 (line)
 * @param {*} input
 */
export const textLineEllipsis = (showLine, text) => {
  if (!text) {
    return text;
  }

  var lineSlpit = text.split('\n');

  if (lineSlpit.length <= showLine) {
    return text;
  }

  var result = '';
  var addCount = 0;
  for (var i = 0; i < lineSlpit.length; ++i) {
    if (addCount >= showLine) break;
    if (lineSlpit[i].length <= 0) continue;

    result += lineSlpit[i];
    result += '\n';
    ++addCount;
  }

  result += '...';
  return result;
};

/**
 * @description Text 말줄임 처리
 * @param {*} input
 */
export const textEllipsis = (showLength, text, ellipsis = '...') => {
  if (!text || text.length <= showLength) {
    return text;
  }

  var result = text.substr(0, showLength);
  result += ellipsis;
  return result;
};

export const divideStr = (input, length) => {
  if (!input) {
    return input;
  }
  const size = Math.ceil(input.length / length);
  const r = Array(size);
  let offset = 0;

  for (let i = 0; i < size; i++) {
    r[i] = input.substr(offset, length);
    offset += length;
  }

  return r;
};

/**
 * @description Text 줄바꿈 처리
 * @param {*} input
 */
export const textLineSplit = (showLineLength, text) => {
  if (!text || text.length <= showLineLength) {
    return text;
  }

  var lineSlpit = text.split('\n');

  var result = '';
  for (var i = 0; i < lineSlpit.length; ++i) {
    var lineText = lineSlpit[i];

    if (lineText.length <= 0) continue;

    if (lineText.length <= showLineLength) {
      result += lineText;
      result += '\n';
      continue;
    }

    var loopCount = lineText.length / showLineLength;

    for (var j = 0; j < loopCount; ++j) {
      result += lineText.substr(0, showLineLength) + '\n';
      lineText = lineText.substr(showLineLength);

      if (lineText.length <= showLineLength) {
        result += lineText + '\n';
        break;
      }
    }
  }
  return result;
};

/**
 * @description 공백 제거
 */
export const removeSpace = input => {
  if (!input) {
    return input;
  }

  return input.replace(/\s/g, '');
};

/**
 * @description URL 주소 마지막 경로 가져오기
 */
export const getLastSegmentOfURL = input => {
  if (!input) {
    return input;
  }

  return input.substring(input.lastIndexOf('/') + 1);
};

/**
 * @description 숫자가 아닌 문자 제거 (-제외)
 * @param {*} input
 */
export const removeNonNumeric = input => {
  if (!input) {
    return input;
  }

  return input.toString().replace(/[^\-0-9]/g, '');
};

/**
 * @description 특정 문자인덱스 치환
 * @param {*} input
 */
export const replaceAt = (text, index, replacement) => {
  return text.substr(0, index) + replacement + text.substr(index + replacement.length);
};

/**
 * @description 파일명으로 사용가능하게 특수문자 제거
 */
export const removeForFileName = input => {
  if (!input) {
    return input;
  }

  return input.replace(/[|&;$%@"<>()+,.]/g, '');
};

/**
 * @description 주소지에 있는 고유키정보 제거
 */
export const removeAddressKey = input => {
  if (!input) {
    return input;
  }

  return input.toString().replace(/#{3}-?(\d+)#{3}/g, '');
};

export const getAverage = (portion, total) => {
  if (total === 0) {
    return 0;
  } else {
    return Math.round(portion/total) || 0;
  }
};

export const getTotalSum = (list, key) => {
  return list.reduce((prev, current) => {
    return prev + +current[key];
  }, 0);
};
