import { reviewConstants } from "../constants";
import { reviewService } from "../services";

export const reviewActions = {
  brandReviewHeaderData,
  brandReviewListData,
  brandRssHeaderData,
  brandRssListData,
  brandRssKeywordList,
  brandRssKeywordEdit,
  brandRemoveContents,
  mainReviewData,
};

function brandReviewHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    reviewService.brandReviewHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.BRAND_REVIEW_HEADER_DATA_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_REVIEW_HEADER_DATA_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_REVIEW_HEADER_DATA_FAILURE, error } }
}

function brandReviewListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey, officeSnoQuery) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    reviewService.brandReviewListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function reset() { return { type: reviewConstants.BRAND_REVIEW_LIST_DATA_RESET } }
  function request() { return { type: reviewConstants.BRAND_REVIEW_LIST_DATA_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_REVIEW_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_REVIEW_LIST_DATA_FAILURE, error } }
}

function brandRssHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    reviewService.brandRssHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.BRAND_RSS_HEADER_DATA_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_RSS_HEADER_DATA_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_RSS_HEADER_DATA_FAILURE, error } }
}

function brandRssListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    reviewService.brandRssListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function reset() { return { type: reviewConstants.BRAND_RSS_LIST_DATA_RESET } }
  function request() { return { type: reviewConstants.BRAND_RSS_LIST_DATA_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_RSS_LIST_DATA_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_RSS_LIST_DATA_FAILURE, error } }
}

function brandRssKeywordList(userSno, brandCode) {
  return dispatch => {
    dispatch(request());

    reviewService.brandRssKeywordList(userSno, brandCode)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.BRAND_RSS_KEYWORD_LIST_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_RSS_KEYWORD_LIST_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_RSS_KEYWORD_LIST_FAILURE, error } }
}

function brandRssKeywordEdit(userSno, brandCode, keywordList) {
  return dispatch => {
    dispatch(request());

    reviewService.brandRssKeywordEdit(userSno, brandCode, keywordList)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.BRAND_RSS_KEYWORD_EDIT_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_RSS_KEYWORD_EDIT_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_RSS_KEYWORD_EDIT_FAILURE, error } }
}

function brandRemoveContents(type, idx) {
  return dispatch => {
    dispatch(request());

    reviewService.brandRemoveContents(type, idx)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.BRAND_REMOVE_CONTENTS_REQUEST } }
  function success(result) { return { type: reviewConstants.BRAND_REMOVE_CONTENTS_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.BRAND_REMOVE_CONTENTS_FAILURE, error } }
}

function mainReviewData(userSno, brandCode, serviceType) {
  return dispatch => {
    dispatch(request());

    reviewService.mainReviewData(userSno, brandCode, serviceType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reviewConstants.MAIN_REVIEW_DATA_REQUEST } }
  function success(result) { return { type: reviewConstants.MAIN_REVIEW_DATA_SUCCESS, result } }
  function failure(error) { return { type: reviewConstants.MAIN_REVIEW_DATA_FAILURE, error } }
}