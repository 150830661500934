import { getMyUserData } from './LoadData';
import { PRODUCT_CODE } from '../constants';

export const isSelfEmployed = () => {
  const userData = getMyUserData(false);

  return userData && userData.company_auth_type === 2;
};

export const isFranchiseMaster = () => {
  const userData = getMyUserData(false);

  if (userData && userData.company_type === '2') {
    if (userData.auth_type === 'A0') {
      return true;
    } else if (userData.auth_type !== 'A0') {
      return false;
    }
  }
};

export const isBrandNoticeUseAble = () => {
  const userData = getMyUserData(false);

  const serviceContentsJson = userData['service_contents_json'];
  const brandNotice = serviceContentsJson.find(content => content.key === 'BRAND_NOTICE');

  return brandNotice && brandNotice.useable;
};

export const isFreePlan = () => {
  const userData = getMyUserData(false);

  return userData && userData.product_code === 'P000F1';
};

export const isLocalhost = () => {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  );
};

export const isDevMode = () => {
  if (window.location.host === 'dev.attracker.shop') {
    return true;
  }
  if (window.location.host === 'stage.attracker.shop') {
    return true;
  }

  if (
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  ) {
    return true;
  }

  return false;
};

export const setMobileViewPort = () => {
  const viewport = document.querySelector('meta[name=viewport]');

  viewport.setAttribute(
    'content',
    'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover',
  );

  window.dispatchEvent(new Event('resize'));
};

export const getProductCodeName = () => {
  const userData = getMyUserData(false);
  const productCodeType = isSelfEmployed() ? 'product_code' : 'brand_product_code';
  const productCodeName = PRODUCT_CODE[userData[productCodeType]];

  return productCodeName ? productCodeName : '없음';
};

export const isWmpoSm = () => {
  const userData = getMyUserData(false);

  if (!userData) {
    return;
  }

  return (
    userData['display_message'] && userData['display_message'].find(message => message.key === 'PROMOTION_WMPO_SM')
  );
};

// 본사 공지 N 아이콘 노출여부
export const isShowNewIcon = (value) => {
  let regDate = new Date(value);
  let currentDate = new Date();

  // 1주일 후의 날짜 계산
  let oneWeekLater = new Date(regDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  if (oneWeekLater > currentDate) {
    return true;
  } else {
    // N 아이콘 hide
    return false;
  }
}