import { getMyUserData } from '../helpers';
import KO from './ko/common.json';
import EN from './en/common.json';

const DEFAULT_LOCALE = 'ko';

export const getLocale = () => {
  let userData = getMyUserData(false);
  return userData && userData['language'] ? userData['language'] : DEFAULT_LOCALE;
};

export const useTranslation = () => {
  switch (getLocale()) {
    case 'ko':
      return KO;
    case 'en':
      return EN;
    default:
      return KO;
  }
};

export const convertNumToMon = input => {
  if (getLocale() !== 'en') {
    return input;
  }

  switch (parseInt(input)) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
    default:
      return input;
  }
};

export const convertNumToWeekday = (input, fullStr = false) => {
  let locale = getLocale();

  const obj = {
    en: {
      0: 'Sun',
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun',
    },
    ko: {
      0: '일',
      1: '월',
      2: '화',
      3: '수',
      4: '목',
      5: '금',
      6: '토',
      7: '일',
    },
  };

  const objFull = {
    en: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    },
    ko: {
      0: '일요일',
      1: '월요일',
      2: '화요일',
      3: '수요일',
      4: '목요일',
      5: '금요일',
      6: '토요일',
      7: '일요일',
    },
  };

  if (fullStr === true) {
    return objFull[locale] ? objFull[locale][input] : objFull['ko'][input];
  }

  return obj[locale] ? obj[locale][input] : obj['ko'][input];
};
