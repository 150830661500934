import io from 'socket.io-client';
import { commonActions } from '../actions';

const socketUrl = 'https://push.attracker.shop';
const socketOption = {
  reconnection: true,
  reconnectionAttempts: 10,
};

let instance;

class WebSocket {
  init = dispatch => {
    if (window.location.hostname === 'localhost') {
      return;
    }

    if (!instance) {
      return;
    }

    instance = io(socketUrl, socketOption);
    instance.on('connect', () => {
      let user = JSON.parse(localStorage.getItem('user'));

      if (user && user.token) {
        instance.emit('setUserId', user.token);
      }
    });

    instance.on('sendData', data => {
      if (data) {
        dispatch(commonActions.setIsRealTime(data));
      }
    });
  };

  destory = () => {
    instance && instance.disconnect();
  };

  checkConnected = () => {
    return instance && instance.connected;
  };

  checkUserToken = userToken => {
    if (userToken) {
      instance && instance.emit('setUserId', userToken);
    }
  };

  reTry = () => {
    if (window.location.hostname === 'localhost') {
      return;
    }

    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
      instance && instance.connect();
      this.checkUserToken(user.token);
    }
  };
}

export default new WebSocket();
