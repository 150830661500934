import { authHeader, handleResponse } from "../helpers";

export const reviewService = {
  brandReviewHeaderData,
  brandReviewListData,
  brandRssHeaderData,
  brandRssListData,
  brandRssKeywordList,
  brandRssKeywordEdit,
  brandRemoveContents,
  mainReviewData,
};

function brandReviewHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  return fetch('/review/brandReviewHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandReviewListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'limitStart': limitStart,
      'limitCount': limitCount,
      'sortKey': sortKey,
      'officeSnoQuery': officeSnoQuery,
    })
  };

  return fetch('/review/brandReviewListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandRssHeaderData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'diffStartDate': diffStartDate,
      'diffEndDate': diffEndDate,
    })
  };

  return fetch('/review/brandRssHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandRssListData(userSno, brandCode, serviceType, startDate, endDate, limitStart, limitCount, sortKey) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
      'startDate': startDate,
      'endDate': endDate,
      'limitStart': limitStart,
      'limitCount': limitCount,
      'sortKey': sortKey,
    })
  };

  return fetch('/review/brandRssListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandRssKeywordList(userSno, brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
    })
  };

  return fetch('/review/brandRssKeywordList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandRssKeywordEdit(userSno, brandCode, keywordList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'keywordList': keywordList,
    })
  };

  return fetch('/review/brandRssKeywordEdit', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function brandRemoveContents(type, idx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'type': type,
      'idx': idx,
    })
  };

  return fetch('/review/brandRemoveContents', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mainReviewData(userSno, brandCode, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'userSno': userSno,
      'brandCode': brandCode,
      'serviceType': serviceType,
    })
  };

  return fetch('/review/mainReviewData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}