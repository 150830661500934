import { setDownloadRequestValue } from '../constants';
import { authHeader, handleResponse } from '../helpers';

export const menuService = {
  brandMenuList,
  categoryList,
  saleMenuHeaderData,
  saleMenuListData,
  menuDateListData,
  deliveryMenuOptionListData,
  deliveryMenuHeaderData,
  deliveryMenuListData,
  allMenuEngineering,
  menuEngineeringOffice,
  oneMenuOfficeHeaderData,
  oneMenuOfficeListData,
  oneMenuDateListData,
  oneMenuSaleWeekDayListData,
  oneMenuDeliveryWeekDayListData,
  mainMenuData,
  saleMenuTotalData,
  editCategoryName,
  disableBrandMenu,
  unlinkMenu,
  editBrandMenu,
  menuLastReceipt,
  recommendUnlinkMenuData,
  registBrandMenu,
  deleteCategory,
  disableRetailMenu,

  // v2
  menuSales,
  diffMenuSales,
  menuSalesByDaily,
  optionMenuSales,
  diffOptionMenuSales,
  oneMenuOptions,
  oneOptionMenuMains,
  oneMenuOffices,
  diffOneMenuOffices,
  menuSalesSumByDaily,
  optionMenuSalesSumByDaily,
  categorySales,
  oneCategoryOffices,
  getSuggestMenus,
  linkMenu,
};

let abertControllers = {};

function brandMenuList(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
    }),
  };

  return fetch('/menu/brandMenuList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function categoryList(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
    }),
  };

  return fetch('/menu/categoryList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleMenuHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  return fetch('/menu/saleMenuHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleMenuListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  officeName,
  menuSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
      officeName: officeName,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportSaleMenuView', requestOptions);

  return fetch('/menu/saleMenuListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  officeName,
  menuSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      officeName: officeName,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportMenuDateView', requestOptions);

  return fetch('/menu/menuDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryMenuHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  sortKey,
  officeSnoQuery,
  dlType,
  menuSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
      dlType: dlType,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  return fetch('/menu/deliveryMenuHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryMenuListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
      dlType: dlType,
      officeName: officeName,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportDeliveryMenuView', requestOptions);

  return fetch('/menu/deliveryMenuListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryMenuOptionListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
  optionType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
      dlType: dlType,
      officeName: officeName,
      menuSnoQuery: menuSnoQuery,
      optionType: optionType,
    }),
  };

  // TOOD
  setDownloadRequestValue('/report/pageExportDeliveryMenuView', requestOptions);

  return fetch('/menu/deliveryMenuOptionListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function allMenuEngineering(userSno, brandCode, serviceType, startDate, endDate, officeSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  return fetch('/menu/allMenuEngineering', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuEngineeringOffice(userSno, categoryIdx, brandCode, choiceAreaCode, startDate, endDate, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      categoryIdx: categoryIdx,
      brandCode: brandCode,
      choiceAreaCode: choiceAreaCode,
      startDate: startDate,
      endDate: endDate,
      serviceType: serviceType,
    }),
  };

  return fetch('/menu/menuEngineeringOffice', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuOfficeHeaderData(
  userSno,
  brandCode,
  menuSno,
  startDate,
  endDate,
  serviceType,
  diffStartDate,
  diffEndDate,
  menuName,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      menuSno: menuSno,
      startDate: startDate,
      endDate: endDate,
      serviceType: serviceType,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      menuName: menuName,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportOneMenuOfficeView', requestOptions);

  return fetch('/menu/oneMenuOfficeHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuOfficeListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      menuSno: menuSno,
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  return fetch('/menu/oneMenuOfficeListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuDateListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuName,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      menuSno: menuSno,
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuName: menuName,
    }),
  };

  setDownloadRequestValue('/report/pageExportOneMenuDateView', requestOptions);

  return fetch('/menu/oneMenuDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuSaleWeekDayListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuName,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      menuSno: menuSno,
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuName: menuName,
    }),
  };

  setDownloadRequestValue('/report/pageExportSaleWeekDayView', requestOptions);

  return fetch('/menu/oneMenuSaleWeekDayListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuDeliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
    }),
  };

  setDownloadRequestValue('/report/pageExportDeliveryWeekDayView', requestOptions);

  return fetch('/office/deliveryWeekdayListData', requestOptions) // 전체 배달지표 요일별과 공유하는 API
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mainMenuData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
    }),
  };

  return fetch('/menu/mainMenuData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleMenuTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
    }),
  };

  return fetch('/menu/saleMenuTotalData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editCategoryName(brandCode, categoryIdx, categoryName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      categoryIdx: categoryIdx,
      categoryName: categoryName,
    }),
  };

  return fetch('/menu/editCategoryName', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function disableBrandMenu(brandMenuSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandMenuSno: brandMenuSno,
    }),
  };

  return fetch('/menu/disableBrandMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function unlinkMenu(brandMenuSno, inMenuSnoQuery, outMenuSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandMenuSno: brandMenuSno,
      inMenuSnoQuery: inMenuSnoQuery,
      outMenuSnoQuery: outMenuSnoQuery,
    }),
  };

  return fetch('/menu/unlinkMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editBrandMenu(
  brandMenuSno,
  brandMenuName,
  brandMenuPrice,
  categoryIdx,
  menuType,
  brandType,
  inMenuSnoQuery,
  outMenuSnoQuery,
  menuImgPath,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandMenuSno: brandMenuSno,
      brandMenuName: brandMenuName,
      brandMenuPrice: brandMenuPrice,
      categoryIdx: categoryIdx,
      menuType: menuType,
      brandType: brandType,
      inMenuSnoQuery: inMenuSnoQuery,
      outMenuSnoQuery: outMenuSnoQuery,
      menuImgPath: menuImgPath,
    }),
  };

  return fetch('/menu/editBrandMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuLastReceipt(brandCode, menuSno, srcType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      menuSno: menuSno,
      srcType: srcType,
    }),
  };

  return fetch('/menu/menuLastReceipt', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function recommendUnlinkMenuData(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
    }),
  };

  return fetch('/menu/recommendUnlinkMenuData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function registBrandMenu(brandCode, menuName, menuPrice, categoryIdx, menuSnoQuery, menuImgPath) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      menuName: menuName,
      menuPrice: menuPrice,
      categoryIdx: categoryIdx,
      menuSnoQuery: menuSnoQuery,
      menuImgPath: menuImgPath,
    }),
  };

  return fetch('/menu/registBrandMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deleteCategory(categoryIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      categoryIdx: categoryIdx,
    }),
  };

  return fetch('/menu/deleteCategory', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function disableRetailMenu(brandCode, menuSnoQuery) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      menuSnoQuery: menuSnoQuery,
    }),
  };

  return fetch('/menu/disableRetailMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['menuSales']) {
    abertControllers['menuSales'].abort();
  }
  abertControllers['menuSales'] = new AbortController();
  let signal = abertControllers['menuSales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      siteType: siteType,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportMenuSales', requestOptions);

  return fetch('/v2/menu/menuSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function diffMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      siteType: siteType,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/menu/menuSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuSalesByDaily(
  userSno,
  brandCode,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  orderChannelList,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
  };

  setDownloadRequestValue('/report/pageExportMenuSalesByDaily', requestOptions);

  return fetch('/v2/menu/menuSalesByDaily', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function optionMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['optionMenuSales']) {
    abertControllers['optionMenuSales'].abort();
  }
  abertControllers['optionMenuSales'] = new AbortController();
  let signal = abertControllers['optionMenuSales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      siteType: siteType,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportOptionMenuSales', requestOptions);

  return fetch('/v2/menu/optionMenuSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function diffOptionMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      siteType: siteType,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
    }),
  };

  return fetch('/v2/menu/optionMenuSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuOptions(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  orderChannelList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType,
      userSno,
      brandCode,
      orderApp,
      startDate,
      endDate,
      officeSnoList,
      menuSno,
      orderChannelList,
    }),
  };

  return fetch('/v2/menu/oneMenuOptions', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneOptionMenuMains(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  orderChannelList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType,
      userSno,
      brandCode,
      orderApp,
      startDate,
      endDate,
      officeSnoList,
      menuSno,
      orderChannelList,
    }),
  };

  return fetch('/v2/menu/oneOptionMenuMains', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneMenuOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  includeOption,
  orderChannelList,
  standardSaleTime,
  onlyOption,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType,
      userSno,
      brandCode,
      orderApp,
      startDate,
      endDate,
      officeSnoList,
      menuSno,
      includeOption,
      orderChannelList,
      standardSaleTime,
      onlyOption,
    }),
  };

  return fetch('/v2/menu/oneMenuOffices', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function diffOneMenuOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  includeOption,
  orderChannelList,
  standardSaleTime,
  onlyOption,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType,
      userSno,
      brandCode,
      orderApp,
      startDate,
      endDate,
      officeSnoList,
      menuSno,
      includeOption,
      orderChannelList,
      standardSaleTime,
      onlyOption,
    }),
  };

  return fetch('/v2/menu/oneMenuOffices', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function menuSalesSumByDaily(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/menu/menuSalesSumByDaily', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function optionMenuSalesSumByDaily(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      includeOption: includeOption,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/menu/optionMenuSalesSumByDaily', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function categorySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['categorySales']) {
    abertControllers['categorySales'].abort();
  }
  abertControllers['categorySales'] = new AbortController();
  let signal = abertControllers['categorySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      menuSnoList: menuSnoList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportMenuSales', requestOptions);

  return fetch('/v2/menu/categorySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneCategoryOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  categoryIdx,
  orderChannelList,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      officeSnoList: officeSnoList,
      categoryIdx: categoryIdx,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/menu/oneCategoryOffices', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getSuggestMenus(brandCode, menuSno, menuName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      menuSno: menuSno,
      menuName: menuName,
    }),
  };

  return fetch('/v2/menu/getSuggestMenus', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function linkMenu(brandCode, brandMenuSno, delegateMenuSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      brandMenuSno: brandMenuSno,
      delegateMenuSno: delegateMenuSno,
    }),
  };

  return fetch('/v2/menu/linkMenu', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
