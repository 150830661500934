import { getOrderAppFilter2, getSiteTypes } from '../constants';
import { useTranslation } from '../locales';
import { getMyUserData, isRetail } from './LoadData';

/**
 * @module DataMapping
 */

var DATE_DIFF_KEY = {};

/**
 * @function
 * @description setDateDiff를 통해 저장된 DATE_DIFF_KEY 중 파라미터 key에 해당되는 값 리턴하는 함수
 * @param {String}
 * @return {*}
 */
export const getDateDiff = key => {
  return DATE_DIFF_KEY[key];
};

/**
 * @function
 * @description 전역변수 DATE_DIFF_KEY의 값을 셋하는 함수
 * @param {*}
 * @return {*}
 */
export const setDateDiff = (key, value) => {
  DATE_DIFF_KEY[key] = value;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const findByName = (list, key, listKeyName, findName) => {
  if (!list || list.length <= 0) {
    return '';
  }

  var find = list.find(item => {
    return item[listKeyName] === key;
  });

  if (find === undefined) {
    return '';
  }

  return find[findName];
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getFieldsByName = (list, key) => {
  var output = {};
  if (list.length <= 0) {
    return output;
  }

  list.forEach(item => {
    var newKey = item[key] ? item[key] : '';

    if (output[newKey] === undefined) {
      output[newKey] = [item];
    } else {
      output[newKey].push(item);
    }
  });

  return output;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const changeObjectName = (obj, oldKey, newKey) => {
  if (oldKey !== newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  return obj;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getConcatQuery = (data, keyName = null, unique = true) => {
  if (!data || data.length <= 0) {
    return null;
  }

  var resultArr = [];

  if (keyName) {
    for (var i = 0; i < data.length; i++) {
      if (!data[i] || !data[i][keyName]) {
        continue;
      }

      resultArr.push(data[i][keyName]);
    }
  } else {
    resultArr = data;
  }

  if (unique === true) {
    resultArr = Array.from(new Set(resultArr));
  }

  resultArr = resultArr.join(',');

  return resultArr;
};

/**
 * @description 내림차순 정렬
 * @param {*} list
 * @param {*} key
 */
export const sortDesc = (list, key, useDeepClone = true) => {
  var tmp = useDeepClone ? deepClone(list) : list;

  tmp.sort((a, b) => (a[key] > b[key] ? -1 : 1));

  return tmp;
};

/**
 * @description 오름차순 정렬
 * @param {*} list
 * @param {*} key
 */
export const sortAsc = (list, key, useDeepClone = true) => {
  var tmp = useDeepClone ? deepClone(list) : list;

  tmp.sort((a, b) => (a[key] < b[key] ? -1 : 1));

  return tmp;
};

/**
 * @param {Array} list
 * @param {*} key
 * @returns {Object}
 */
export const groupBy = (list, key) => {
  if (!list) {
    return null;
  }

  var result = list.reduce((groups, item) => {
    const val = item[key];

    if (val !== null) {
      groups[val] = groups[val] || [];
      groups[val].push(item);
    }

    return groups;
  }, {});

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const groupBySum = (list, groupKey, sumKey) => {
  var result = {};
  var tmp = groupBy(list, groupKey);

  Object.keys(tmp).map(key => {
    result[key] = sumItems(tmp[key], sumKey);
    return null;
  });

  return result;
};

/**
 * @param {Array} list
 * @param {*} key
 */
export const groupCount = (list, key) => {
  return Object.keys(groupBy(list, key)).length;
};

/**
 * @param {Array} list
 * @param {*} key
 */
export const groupKeys = (list, key) => {
  return Object.keys(groupBy(list, key));
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayUnique = arr => {
  return [...new Set(arr)];
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const deepClone = input => {
  if (!input) {
    return [];
  }

  return JSON.parse(JSON.stringify(input));
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const sumItems = (data, key) => {
  if (!data || data.length <= 0) {
    return 0;
  }

  return data.map(item => (item[key] ? Number(item[key]) : 0)).reduce((a, c) => a + c);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrsEachSum = arrs => {
  return arrs.reduce((r, a) => {
    a.forEach((b, i) => {
      r[i] = (r[i] || 0) + b;
    });
    return r;
  }, []);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const sum = data => {
  if (!data || data.length <= 0) {
    return 0;
  }

  return data.reduce((a, b) => a + b, 0);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayInjection = (arr, value = '', count = 1) => {
  var result = [];

  for (var i = 0; i < arr.length; i++) {
    result.push(arr[i]);

    for (var j = 0; j < count; j++) {
      result.push(value);
    }
  }

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const convertForFindName = (list, keyName, valueName) => {
  var result = {};

  if (!list || list.length <= 0) {
    return result;
  }

  list.map(item => {
    if (!item[valueName]) {
      return null;
    }

    result[item[keyName]] = item[valueName];

    return null;
  });

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const objectDesc = obj => {
  var result = {};

  Object.keys(obj)
    .sort()
    .reverse()
    .map(key => {
      result[key] = obj[key];
      return null;
    });

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const objectAsc = obj => {
  var result = {};

  Object.keys(obj)
    .sort()
    .map(key => {
      result[key] = obj[key];
      return null;
    });

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const objectDesc2 = obj => {
  var arr = [];
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      arr.push({
        key: prop,
        value: obj[prop],
      });
    }
  }
  arr.sort((a, b) => {
    return b.value - a.value;
  });
  return arr;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayContainsArray = (arr1, arr2) => {
  return arr1.every(value => {
    return arr2.indexOf(value) >= 0;
  });
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayObjectMaxMin = (arr, key, onlyPositive = true) => {
  if (!arr) {
    return {
      min: null,
      max: null,
    };
  }

  var minObject = null;
  var maxObject = null;
  var positiveMinObject = null;
  var minNum = Number.POSITIVE_INFINITY;
  var maxNum = Number.NEGATIVE_INFINITY;
  var positiveMinNum = Number.POSITIVE_INFINITY;
  var tmp;
  for (var i = 0; i < arr.length; i++) {
    tmp = arr[i][key];
    if (tmp < minNum) {
      minNum = tmp;
      minObject = arr[i];
    }
    if (tmp > maxNum) {
      maxNum = tmp;
      maxObject = arr[i];
    }

    if (tmp < positiveMinNum && tmp > 0) {
      positiveMinNum = tmp;
      positiveMinObject = arr[i];
    }
  }

  return {
    // 최소값이 0보다 커야한다는 요청에 의해 수정
    min: onlyPositive ? positiveMinObject : minObject,
    max: maxObject,
    positiveMin: positiveMinObject,
  };
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayToObject = (array, keyField) => {
  if (!array && array.length <= 0) {
    return {};
  }

  return array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getFields = (input, field) => {
  var output = [];

  if (!input) {
    return output;
  }

  for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
  return output;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const extractArrayKey = (key, values, arrObj, extractKey) => {
  var result = [];
  var grouped = groupBy(arrObj, key);
  values.map(value => {
    result.push(grouped && grouped[value] ? grouped[value][extractKey] : null);
    return null;
  });

  return result;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const arrayFillValue = (arr, count, value) => {
  var result = new Array(count).fill([value, 0, -1]);

  result.map((_item, i) => {
    result[i] = arr[i] === null || arr[i] === undefined ? value : arr[i];
    return null;
  });

  return result;
};

// DB상의 배달 채널 값과 프론트 배달채널 값이 상이하여 값들을 일치시켜주기 위한 함수.
// constants/common.js ORDER_APPS 와 ORDER_APP_FILTER 참조.
/**
 * @function
 * @description  설명추가
 * @param {*} channel
 * @return {number}
 */
export const getDlChannel = channel => {
  if (channel === null || channel === undefined) {
    channel = -1;
  }
  return channel > -1 ? channel : '0,1,2,3,4,5,6,7,8,9';
};

/**
 * @param  order true : 순서대로 자름 / false : 교차로 자름
 * @param {*} arr
 * @param {*} n
 * @param {*} order
 */
export const arrayDivision = (arr, n, order = true) => {
  var clone = deepClone(arr);

  var len = clone.length;
  var tmp = [];

  var i = 0;
  if (order) {
    for (i = 0; i < len; i++) {
      var slice = clone.splice(0, n);
      if (slice.length > 0) {
        tmp.push(slice);
      }
    }
  } else {
    for (i = 0; i < n; i++) {
      tmp[i] = [];
    }
    i = 0;

    var cursor = 0;
    for (i = 0; i < len; i++) {
      tmp[cursor].push(clone[i]);

      cursor = cursor < n - 1 ? cursor + 1 : 0;
    }
  }

  return tmp;
};

/**
 *
 * @param {*} before
 * @param {*} after
 * @param {*} diffKey
 */
export const getArrayChangedByKey = (before = [], after = [], diffKey) => {
  if (!before || !after) {
    return {
      in: [],
      out: [],
    };
  }

  return {
    in: after.filter(o => !before.some(v => v[diffKey] === o[diffKey])),
    out: before.filter(o => !after.some(v => v[diffKey] === o[diffKey])),
  };
};

/**
 * @description 검색어에 따른 필터링
 * @param {*} list
 * @param {*} key
 * @param {*} searchText
 */
export const filterSearchText = (list = [], key, searchText = '') => {
  if (!list || !searchText) {
    return list;
  }

  return list.filter(item => {
    return item[key] && item[key].toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
  });
};

/**
 * @description 동일한 value를 카운팅하여 가장 많은 값을 리턴
 * @param {*} arr
 */
export const maxRepeatValue = (arr, key) => {
  var counter = {};
  arr.map(item => {
    if (counter[item[key]]) {
      counter[item[key]] = 1;
    } else {
      counter[item[key]]++;
    }

    return null;
  });

  return Object.keys(counter).reduce((a, b) => (counter[a] > counter[b] ? a : b));
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const DISABLE_LINK = 'disable_link_';
/**
 * 클릭이벤트가 적용된 element 안에 다른 클릭이벤트가 적용되어있을때,
 * 상위 클릭이벤트를 무시하도록 id값을 주거나, className으로 강제지정함
 * @param {*} event
 */
export const isContainDisableLink = event => {
  if (!event || !event.target) {
    return false;
  }

  if (event.target.className === 'slick-arrow slick-prev') {
    return true;
  }

  if (event.target.className === 'people') {
    return true;
  }

  if (event.target.className === 'slick-arrow slick-next') {
    return true;
  }

  if (event.target.className === 'select-icon2') {
    return true;
  }

  return event.target.id.indexOf(DISABLE_LINK) !== -1;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const isContentsAble = contentsIndex => {
  var userData = getMyUserData(false);

  if (!userData) {
    return false;
  }

  // 브랜드소속계정일 경우 (tb_brand_info.contents_menu_able_bit)
  // 점주일 경우 (tb_user_profile.contents_menu_able_bit)
  var contentsAbleKey = isRetail() ? 'contents_menu_able_bit' : 'brand_contents_menu_able_bit';
  if (!userData[contentsAbleKey]) {
    return false;
  }

  return parseInt(userData[contentsAbleKey][contentsIndex]) === 1;
};

export const isUseableContents = contents => {
  let userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  const contentsObj = userData['service_contents_json'] ? arrayToObject(userData['service_contents_json'], 'key') : {};
  const contentsInfo = contentsObj[contents];

  if (!contentsInfo || !contentsInfo['useable']) {
    return false;
  }

  return true;
};

export const isLockedContents = contents => {
  let userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  const contentsObj = userData['service_contents_json'] ? arrayToObject(userData['service_contents_json'], 'key') : {};
  const contentsInfo = contentsObj[contents];

  if (!contentsInfo || !contentsInfo['useable']) {
    return false;
  }

  return contentsInfo['option'] && contentsInfo['option'].includes('locked');
};

export const isNewContents = contents => {
  let userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  const contentsObj = userData['service_contents_json'] ? arrayToObject(userData['service_contents_json'], 'key') : {};
  const contentsInfo = contentsObj[contents];

  return contentsInfo['option'] && contentsInfo['option'].includes('new');
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const isCompleteTutorial = contentsIndex => {
  var userData = getMyUserData(false);

  if (!userData || !userData['tutorial_able_bit']) {
    return false;
  }

  return parseInt(userData['tutorial_able_bit'][contentsIndex]) === 1;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getOrderAppTitle = channel => {
  // Front Const ORDER_APP_FILTER 와 동일.
  const orderAppList = [
    { 배달전체: -1 },
    { 전화주문: 4 },
    { 배달의민족: 1 },
    { 배민1: 2 },
    { 요기요: 3 },
    { 쿠팡이츠: 5 },
    { 배달특급: 7 },
    { 기타: 0 },
    { 전화배달: 9 },
  ];

  if (channel === null || channel === undefined) {
    channel = -1;
  }

  const appTitle = orderAppList.find(title => {
    const appName = Object.keys(title)[0];
    const appCode = Object.values(title)[0];
    const result = appName ? channel === appCode : false;
    return result;
  });

  return Object.keys(appTitle)[0];
};

export const getOrderAppConvertedName = orderApp => {
  const t = useTranslation();

  let labelNames = {
    0: t['필터-기타'] + '(' + t['필터-배달'] + ')',
    100: t['필터-카드'] + '(' + t['필터-홀'] + ')',
    101: t['필터-현금'] + '(' + t['필터-홀'] + ')',
    102: t['필터-기타'] + '(' + t['필터-홀'] + ')',
  };

  return labelNames[orderApp] ? labelNames[orderApp] : '';
};

export const getOrderAppName = orderApp => {
  let labelNames = {
    0: '배달(기타)',
    100: '홀(카드)',
    101: '홀(현금)',
    102: '홀(기타)',
  };

  return labelNames[orderApp] ? labelNames[orderApp] : '';
};

/**
 * @function
 * @description 주문이 이력이 있는 배달채널만 필터링
 * @param {*}
 * @return {*}
 */
export const getOrderAppFilter = (needTotal = true) => {
  let result = [];
  let userData = getMyUserData(false);
  const t = useTranslation();

  if (needTotal) {
    result.push({ name: t['필터-배달앱전체'], value: -1, color: '#ddd' });
  }

  if (userData && userData['base_order_channel_json']) {
    getOrderAppFilter2().map(c => {
      if (userData['base_order_channel_json'][c['value']]) {
        result.push(c);
      }

      return null;
    });
  } else {
    result = result.concat(getOrderAppFilter2());
  }

  return result;
};

/**
 * @function
 * @description 주문이 이력이 있는 결제수단만 필터링
 * @param {*}
 * @return {*}
 */
export const getPayTypeFilter = (needTotal = true) => {
  let result = [];
  let userData = getMyUserData(false);

  if (needTotal) {
    result.push({ name: '홀 결제 전체', value: -2, orderApp: -2, color: '#ddd' });
  }

  if (userData && userData['base_pay_type_json']) {
    getSiteTypes().map(c => {
      if (userData['base_pay_type_json'][c['orderApp']]) {
        result.push(c);
      }

      return null;
    });
  } else {
    result = result.concat(getSiteTypes());
  }

  return result;
};

/**
 * @function
 * @description 신규버전 or 구버전 URL
 * @param {*}
 * @return {*}
 */
export const getServiceUrl = () => {
  switch (window.location.host) {
    case 'service-dev.attracker.shop':
      return 'https://data-dev.attracker.shop/#/';
    case 'service.attracker.shop':
      return 'https://data.attracker.shop/#/';
    default:
      return 'https://data-dev.attracker.shop/#/';
  }
};

export const getMaxValueIndex = (items, fieldName) => {
  const maxValue = Math.max(...items.map(item => item[fieldName]));
  return items.findIndex(item => item[fieldName] === maxValue);
};

export const findsortedOrderIdx = (orderList, dates, sortType) => {
  let latestOrder = null;
  if (orderList.length !== 0) {
    orderList.forEach(order => {
      if (!latestOrder) {
        latestOrder = order;
      } else {
        if (sortType === 'asc' && order[dates] < latestOrder[dates]) {
          latestOrder = order;
        } else if (sortType === 'desc' && order[dates] > latestOrder[dates]) {
          latestOrder = order;
        }
      }
    });
  } else {
    return;
  }
  return latestOrder;
};

export const getAuthName = authCode => {
  let authName = '';
  switch (authCode) {
    case 'A0':
      authName = '고스트관리자';
      break;

    case 'A1':
      authName = '마스터관리자';
      break;

    case 'B1':
      authName = '본점관리자';
      break;

    case 'C1':
      authName = '지점관리자';
      break;

    case 'D1':
      authName = '점주';
      break;

    default:
      break;
  }

  return authName;
};