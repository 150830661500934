import React, { Suspense, useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { Redirect, Route } from 'react-router-dom';
import { userService } from '../services';
import { getPageTitle } from './Converter';
import { usePrevious } from './CustomHooks';
import { getMyUserData, setMyUserData } from './LoadData';

/**
 * @module PrivateRoute
 */

/**
 *
 * @description 상태값이 수정된 이후에 페이지 랜더가 필요할 경우 사용. delayPaths에 경로 추가.
 *
 */
const Delay = ({ location, children, waitBeforeShow = 10 }) => {
  const [isShow, setIsShow] = useState(false);
  const [path, setPath] = useState(location.pathname);

  const prevPath = usePrevious(path);
  const delayPaths = ['/analysis/location', '/analysis/locationPin'];

  useEffect(() => { 
    setIsShow(false);
    setPath(location.pathname);

    setTimeout(() => {
      setIsShow(true);
    }, waitBeforeShow);
  }, [location]);

  if (delayPaths.includes(prevPath)) {
    return location.pathname === path && isShow ? children : null;
  }
  
  return children;
};

const fallbackLoading = (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse" color="#4d5eff" />
      </div>
      <h6 className="mt-5">
        로딩중입니다. 잠시만 기다려 주십시오.
        <small>{getPageTitle(true, false)} :: 문의사항이 있으실 경우, 앳트래커 고객센터로 문의주시기 바랍니다.</small>
        <small className="opacity-5 mt-4">고객센터 : 1899-8351 | 이메일 : support@nuvent.co.kr</small>
      </h6>
    </div>
  </div>
);

export const PublicRoute = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={props => (
      <Suspense fallback={fallbackLoading}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let userData = getMyUserData(false);
      
      if (!userData) {
        return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />;
      }

      if (userData['redirect_to']) {
        let redirectTo = userData['redirect_to'];
        userData['redirect_to'] = null;
        setMyUserData(userData);
        userService.setRedirectTo(userData['user_sno'], null);
        return <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />;
      }

      return (
        <Suspense fallback={fallbackLoading}>
          <Delay location={props.location} children={<Component {...props} />}></Delay>
        </Suspense>
      );
    }}
  />
);
