import gtag from 'ga-gtag';
import { GA_EVENT_CONST, PATH_MAP } from '../constants/GAConst';
import { getMyUserData } from './LoadData';
import { checkReactNativeWebView, setAppSocialEvent } from './AppWebView';
import { commonService } from '../services';

/**
 * @module GAFunction
 */

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
const getRoute = path => {
  const len = path.length;
  if (!isNaN(parseInt(path[len - 1]))) {
    for (let i = len - 1; i >= 0; i--) {
      if (path[i] === '/') {
        return path.slice(0, i);
      }
    }
  } else {
    return path;
  }
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
const pathToStr = path => {
  const prevPath = getRoute(path);
  return PATH_MAP[prevPath] ? PATH_MAP[prevPath] : path;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const pushGAEvent = (action = '') => {
  const userData = getMyUserData(false);

  gtag('event', action, {
    event_category: pathToStr(window.location.hash),
    event_label: userData ? String(userData['user_sno']) : 'Anonymous user',
  });
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const trackEvent = (eventCode = '') => {
  if (!eventCode) {
    return;
  }

  const userData = getMyUserData(false);
  const param = window.location.hash.replace('#', '');
  const commonAction = eventCode.childs && eventCode.childs[param];

  const eventName = commonAction ? commonAction['eventName'] : eventCode['eventName'];
  const eventCategory = commonAction ? commonAction['category'] : eventCode['category'];
  const eventKakao = commonAction ? commonAction['kakao'] : eventCode['kakao'];
  const eventNaver = commonAction ? commonAction['naver'] : eventCode['naver'];
  const eventFacebook = commonAction ? commonAction['facebook'] : eventCode['facebook'];
  const eventLabel = userData ? String(userData['user_sno']) : 'Anonymous user';

  if (!eventName) {
    return;
  }

  if (checkReactNativeWebView()) {
    const sendApp = commonAction ? commonAction['sendApp'] : eventCode['sendApp'];
    const sendAppName = commonAction ? commonAction['sendAppName'] : eventCode['sendAppName'];

    if (sendApp && sendAppName) {
      setAppSocialEvent(sendAppName, sendAppName, eventLabel);
    }
  }

  if (eventKakao) {
    trackKaKao(eventCategory, eventName, eventKakao);
  }

  if (eventNaver) {
    trackNaver(eventCategory, eventName);
  }

  if (eventFacebook) {
    trackFacebook(eventCategory, eventName);
  }

  const requestGaEventValue = userData ? userData['request_ga_event_value'] : null;
  const gaEventName = (eventCategory ? `${eventCategory}_` : '') + eventName;
  const gaEventValue = {
    event_category: eventCategory,
    event_label: eventLabel,
    ...requestGaEventValue,
  };

  // GA 이벤트 호출
  gtag('event', gaEventName, gaEventValue);
};

export const trackKaKao = (eventCategory, eventName, eventKakao) => {
  const userData = getMyUserData(false);
  const trackId = '3986195606675212038';

  if (window.kakaoPixel) {
    const userSno = userData && userData['user_sno'];
    const requestGaEventValue = userData && userData['request_ga_event_value'];

    if (eventCategory === 'Funnel1') {
      window.kakaoPixel(trackId).completeRegistration(userSno);
    } else if (eventCategory === 'Funnel2') {
      window.kakaoPixel(trackId).login(userSno);
    } else if (eventKakao === 'Event_SM_G_Install_path' || eventKakao === 'MO_Event_SM_G_Install_path' || eventKakao === 'Event_SM_A_Install_path' || eventKakao === 'MO_Event_SM_A_Install_path' ) {
      window.kakaoPixel(trackId).missionComplete(eventKakao);
    } else if (eventKakao === 'Event_SM_Install' || eventKakao === 'MO_Event_SM_Install') {
      window.kakaoPixel(trackId).missionComplete(eventKakao);
    } else if (eventKakao === 'Event_SM_Review' || eventKakao === 'MO_Event_SM_Review') {
      window.kakaoPixel(trackId).tutorial(eventKakao);
    } else if (eventKakao === 'Event_SM_Friend' || eventKakao === 'MO_Event_SM_Friend') {
      window.kakaoPixel(trackId).tutorial(eventKakao);
    } else if (eventName === 'purchase') {
      window.kakaoPixel(trackId).purchase(requestGaEventValue);
    }
  }
};

export const trackNaver = (eventCategory, eventName) => {
  if (window.wcs) {
    window.wcs.event(eventCategory, eventName);
  }
};

export const trackFacebook = (eventCategory, eventName) => {
  const userData = getMyUserData(false);

  if (window.fbq && userData) {
    const requestGaEventValue = userData['request_ga_event_value'];

    if (eventCategory === 'Funnel1') {
      window.fbq('track', 'CompleteRegistration');
    } else if (eventCategory === 'Funnel2') {
      window.fbq('track', 'ViewContent');
    } else if (eventName === 'purchase') {
      window.fbq('track', 'Purchase', requestGaEventValue);
    }
  }
};

export const requestGA = userData => {
  const code = userData['request_ga_event_code'];

  if (code) {
    trackEvent(GA_EVENT_CONST[code]);
    commonService.completeRequestGA(userData['user_sno'], code);
  }
};

/**
 * @function
 * @description 설명추가
 * @return {*}
 * @param item
 */
export const pushGAEventByItem = item => {
  if (item) {
    gtag('event', item['action'], {
      event_category: item['category'] ? item['category'] : pathToStr(window.location.hash),
      event_label: item['label'] ? item['label'] : '',
    });
  }
};
