export const materialConstants = {
  MATERIAL_LIST_REQUEST: 'MATERIAL_LIST_REQUEST',
  MATERIAL_LIST_SUCCESS: 'MATERIAL_LIST_SUCCESS',
  MATERIAL_LIST_FAILURE: 'MATERIAL_LIST_FAILURE',

  USE_MATERIAL_HEADER_DATA_REQUEST: 'USE_MATERIAL_HEADER_DATA_REQUEST',
  USE_MATERIAL_HEADER_DATA_SUCCESS: 'USE_MATERIAL_HEADER_DATA_SUCCESS',
  USE_MATERIAL_HEADER_DATA_FAILURE: 'USE_MATERIAL_HEADER_DATA_FAILURE',

  USE_MATERIAL_LIST_DATA_RESET: 'USE_MATERIAL_LIST_DATA_RESET',
  USE_MATERIAL_LIST_DATA_REQUEST: 'USE_MATERIAL_LIST_DATA_REQUEST',
  USE_MATERIAL_LIST_DATA_SUCCESS: 'USE_MATERIAL_LIST_DATA_SUCCESS',
  USE_MATERIAL_LIST_DATA_FAILURE: 'USE_MATERIAL_LIST_DATA_FAILURE',

  USE_MATERIAL_DATE_LIST_DATA_RESET: 'USE_MATERIAL_DATE_LIST_DATA_RESET',
  USE_MATERIAL_DATE_LIST_DATA_REQUEST: 'USE_MATERIAL_DATE_LIST_DATA_REQUEST',
  USE_MATERIAL_DATE_LIST_DATA_SUCCESS: 'USE_MATERIAL_DATE_LIST_DATA_SUCCESS',
  USE_MATERIAL_DATE_LIST_DATA_FAILURE: 'USE_MATERIAL_DATE_LIST_DATA_FAILURE',

  USE_MATERIAL_TOTAL_DATA_RESET: 'USE_MATERIAL_TOTAL_DATA_RESET',
  USE_MATERIAL_TOTAL_DATA_REQUEST: 'USE_MATERIAL_TOTAL_DATA_REQUEST',
  USE_MATERIAL_TOTAL_DATA_SUCCESS: 'USE_MATERIAL_TOTAL_DATA_SUCCESS',
  USE_MATERIAL_TOTAL_DATA_FAILURE: 'USE_MATERIAL_TOTAL_DATA_FAILURE',

  ONE_MATERIAL_OFFICE_HEADER_DATA_REQUEST: 'ONE_MATERIAL_OFFICE_HEADER_DATA_REQUEST',
  ONE_MATERIAL_OFFICE_HEADER_DATA_SUCCESS: 'ONE_MATERIAL_OFFICE_HEADER_DATA_SUCCESS',
  ONE_MATERIAL_OFFICE_HEADER_DATA_FAILURE: 'ONE_MATERIAL_OFFICE_HEADER_DATA_FAILURE',

  ONE_MATERIAL_OFFICE_LIST_DATA_RESET: 'ONE_MATERIAL_OFFICE_LIST_DATA_RESET',
  ONE_MATERIAL_OFFICE_LIST_DATA_REQUEST: 'ONE_MATERIAL_OFFICE_LIST_DATA_REQUEST',
  ONE_MATERIAL_OFFICE_LIST_DATA_SUCCESS: 'ONE_MATERIAL_OFFICE_LIST_DATA_SUCCESS',
  ONE_MATERIAL_OFFICE_LIST_DATA_FAILURE: 'ONE_MATERIAL_OFFICE_LIST_DATA_FAILURE',

  ONE_MATERIAL_DATE_LIST_DATA_RESET: 'ONE_MATERIAL_DATE_LIST_DATA_RESET',
  ONE_MATERIAL_DATE_LIST_DATA_REQUEST: 'ONE_MATERIAL_DATE_LIST_DATA_REQUEST',
  ONE_MATERIAL_DATE_LIST_DATA_SUCCESS: 'ONE_MATERIAL_DATE_LIST_DATA_SUCCESS',
  ONE_MATERIAL_DATE_LIST_DATA_FAILURE: 'ONE_MATERIAL_DATE_LIST_DATA_FAILURE',

  ONE_MATERIAL_MENU_HEADER_DATA_RESET: 'ONE_MATERIAL_MENU_HEADER_DATA_RESET',
  ONE_MATERIAL_MENU_HEADER_DATA_REQUEST: 'ONE_MATERIAL_MENU_HEADER_DATA_REQUEST',
  ONE_MATERIAL_MENU_HEADER_DATA_SUCCESS: 'ONE_MATERIAL_MENU_HEADER_DATA_SUCCESS',
  ONE_MATERIAL_MENU_HEADER_DATA_FAILURE: 'ONE_MATERIAL_MENU_HEADER_DATA_FAILURE',

  ONE_MATERIAL_MENU_LIST_DATA_RESET: 'ONE_MATERIAL_MENU_LIST_DATA_RESET',
  ONE_MATERIAL_MENU_LIST_DATA_REQUEST: 'ONE_MATERIAL_MENU_LIST_DATA_REQUEST',
  ONE_MATERIAL_MENU_LIST_DATA_SUCCESS: 'ONE_MATERIAL_MENU_LIST_DATA_SUCCESS',
  ONE_MATERIAL_MENU_LIST_DATA_FAILURE: 'ONE_MATERIAL_MENU_LIST_DATA_FAILURE',

  USE_MATERIAL_LATELY_RESET: 'USE_MATERIAL_LATELY_RESET',
  USE_MATERIAL_LATELY_REQUEST: 'USE_MATERIAL_LATELY_REQUEST',
  USE_MATERIAL_LATELY_SUCCESS: 'USE_MATERIAL_LATELY_SUCCESS',
  USE_MATERIAL_LATELY_FAILURE: 'USE_MATERIAL_LATELY_FAILURE',
};
