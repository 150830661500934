import { customerReviewConstants } from '../constants';
import { customerReviewService } from '../services';
// import { paymentPlanProcess } from '../helpers/PaymentPlanProcess';
export const customerReviewActions = {
  officeReviewList,
  orderAppReviewList,
  specificReviewList,
  mobileReviewList,
  mobileTodayReviewList,
  mobileTodaySpecificReviewList,
  mobileSpecificReviewList,
  insertAnswer,
  deleteAnswer,
  autoAnswerList,
  updateAutoAnswer,
  insertAutoAnswer,
  deleteAutoAnswer,
  specificReview,
  reviewPrevUrl,
  syncReviewList,
};

function officeReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList) {
  return dispatch => {
    dispatch(request());

    customerReviewService
      .officeReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: customerReviewConstants.OFFICE_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.OFFICE_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.OFFICE_REVIEW_LIST_FAILURE, error };
  }
}

function orderAppReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList) {
  return dispatch => {
    dispatch(request());

    customerReviewService
      .orderAppReviewList(userSno, brandCode, startDate, endDate, diffStartDate, diffEndDate, officeSnoList)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: customerReviewConstants.ORDER_APP_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.ORDER_APP_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.ORDER_APP_REVIEW_LIST_FAILURE, error };
  }
}

function specificReviewList(
  userSno,
  brandCode,
  startDate,
  endDate,
  officeSnoList,
  size,
  page,
  point,
  orderApp,
  officeSno,
  reviewOption,
) {
  return dispatch => {
    dispatch(request());

    customerReviewService
      .specificReviewList(
        userSno,
        brandCode,
        startDate,
        endDate,
        officeSnoList,
        size,
        page,
        point,
        orderApp,
        officeSno,
        reviewOption,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_LIST_FAILURE, error };
  }
}

function mobileReviewList(brandCode, officeSno, startDate, endDate) {
  return dispatch => {
    dispatch(request());

    customerReviewService.mobileReviewList(brandCode, officeSno, startDate, endDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.MOBILE_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.MOBILE_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.MOBILE_REVIEW_LIST_FAILURE, error };
  }
}

function mobileTodayReviewList(brandCode, officeSno, startDate, endDate) {
  return dispatch => {
    dispatch(request());

    customerReviewService.mobileTodayReviewList(brandCode, officeSno, startDate, endDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_FAILURE, error };
  }
}

function mobileTodaySpecificReviewList(
  brandCode,
  officeSno,
  startDate,
  endDate,
  size,
  page,
  point,
  orderApp,
  answerYn,
) {
  return dispatch => {
    dispatch(request());

    customerReviewService
      .mobileTodaySpecificReviewList(brandCode, officeSno, startDate, endDate, size, page, point, orderApp, answerYn)
      .then(
        result => {
          setTimeout(() => {
            dispatch(success(result));
          }, 1000);
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_FAILURE, error };
  }
}

function mobileSpecificReviewList(brandCode, officeSno, startDate, endDate, size, page, point, orderApp, answerYn) {
  return dispatch => {
    dispatch(request());

    customerReviewService
      .mobileSpecificReviewList(brandCode, officeSno, startDate, endDate, size, page, point, orderApp, answerYn)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_FAILURE, error };
  }
}

function insertAnswer(reviewIdx, reviewSno, answer) {
  return dispatch => {
    dispatch(request());

    customerReviewService.insertAnswer(reviewIdx, reviewSno, answer).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.INSERT_ANSWER_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.INSERT_ANSWER_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.INSERT_ANSWER_FAILURE, error };
  }
}

function deleteAnswer(reviewAnswerIdx) {
  return dispatch => {
    dispatch(request());

    customerReviewService.deleteAnswer(reviewAnswerIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.DELETE_ANSWER_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.DELETE_ANSWER_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.DELETE_ANSWER_FAILURE, error };
  }
}

function autoAnswerList(officeSno) {
  return dispatch => {
    dispatch(request());

    customerReviewService.autoAnswerList(officeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.AUTO_ANSWER_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.AUTO_ANSWER_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.AUTO_ANSWER_LIST_FAILURE, error };
  }
}

function updateAutoAnswer(officeSno, point, answerIdx, autoYn, storeNumberList) {
  return dispatch => {
    dispatch(request());

    customerReviewService.updateAutoAnswer(officeSno, point, answerIdx, autoYn, storeNumberList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.UPDATE_AUTO_ANSWER_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.UPDATE_AUTO_ANSWER_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.UPDATE_AUTO_ANSWER_FAILURE, error };
  }
}

function insertAutoAnswer(officeSno,  point, answer, autoYn, storeNumberList) {
  return dispatch => {
    dispatch(request());

    customerReviewService.insertAutoAnswer(officeSno, point, answer, autoYn, storeNumberList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.INSERT_AUTO_ANSWER_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.INSERT_AUTO_ANSWER_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.INSERT_AUTO_ANSWER_FAILURE, error };
  }
}

function deleteAutoAnswer(answerIdx, storeNumberList) {
  return dispatch => {
    dispatch(request());

    customerReviewService.deleteAutoAnswer(answerIdx, storeNumberList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.DELETE_AUTO_ANSWER_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.DELETE_AUTO_ANSWER_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.DELETE_AUTO_ANSWER_FAILURE, error };
  }
}

function specificReview(reviewIdx) {
  return dispatch => {
    dispatch(request());

    customerReviewService.specificReview(reviewIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.SPECIFIC_REVIEW_FAILURE, error };
  }
}

function reviewPrevUrl(url) {
  var result = url;
  return { type: customerReviewConstants.SET_REVIEW_PREV_URL, result };
}

function syncReviewList(officeSno) {
  return dispatch => {
    dispatch(request());

    customerReviewService.syncReviewList(officeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: customerReviewConstants.SYNC_REVIEW_LIST_REQUEST };
  }
  function success(result) {
    return { type: customerReviewConstants.SYNC_REVIEW_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: customerReviewConstants.SYNC_REVIEW_LIST_FAILURE, error };
  }
}
