import { storeList, paymethodsList } from '../components/KmongPayment/DataTable/data';
import { authHeader, handleResponse } from '../helpers';

export const paymentService = {
  getRegistInfo,
  registPaymentInfo,
  getHistory,
  singlePayment,
  setPaymentPlan,
  getPaymentPlan,
};

function getRegistInfo(userSno, officeSno, brandCode) {
  const requestUrl = '/payment/getRegistInfo';
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      officeSno: officeSno,
      brandCode: brandCode,
    }),
  };

  return fetch(requestUrl, requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function registPaymentInfo(requestData) {
  // TODO change api url
  const requestUrl = '/payment/registCard';
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(requestData),
  };

  return fetch(requestUrl, requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getHistory(userSno, officeSno, brandCode) {
  // TODO change api url
  const requestUrl = '/payment/getHistory';
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      officeSno: officeSno,
      brandCode: brandCode,
    }),
  };

  return fetch(requestUrl, requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function singlePayment(targetDate, userSno, officeSno) {
  const requestUrl = '/payment/singlePayment';
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      targetDate: targetDate,
      userSno: userSno,
      officeSno: officeSno,
    }),
  };
  return fetch(requestUrl, requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

export function getPaymentTableData() {
  return {
    storeList,
  };
}

export function getPayMethodList() {
  return paymethodsList;
}

function setPaymentPlan(userSno, productCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      productCode: productCode,
    }),
  };
  return fetch('/payment/setPaymentPlan', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getPaymentPlan(userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
    }),
  };

  return fetch('/payment/getPaymentPlan', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
