export const TEST_QUERY_KEY = {
  GET_TEST: 'GET_TEST',
};

export const FRANCHISE_NOTICE_QUERY_KEY = {
  MOBILE: {
    FRANCHISE_NOTICE_QUERY: 'FRANCHISE_NOTICE_QUERY',
  },
  PC: {
    WEEK_LIST_QUERY: 'WEEK_LIST_QUERY',
    RECENT_COMMENT_QUERY: 'RECENT_COMMENT_QUERY',
    FRANCHISE_NOTICE_LIST: 'FRANCHISE_NOTICE_LIST',
    FRANCHISE_NOTICE_ONE_LOAD: 'FRANCHISE_NOTICE_ONE_LOAD',
    FRANCHISE_NOTICE_READ_LIST: 'FRANCHISE_NOTICE_READ_LIST',
    FRANCHISE_RECENT_NOTICE_LIST: 'FRANCHISE_RECENT_NOTICE_LIST'
  },
};

export const CUSTOMER_REVIEW_QUERY_KEY = {
  MOBILE: {
    AUTO_REVIEW_LIST_QUERY: 'AUTO_REVIEW_LIST_QUERY'
  }
}