export const reviewConstants = {
  BRAND_REVIEW_HEADER_DATA_REQUEST: 'BRAND_REVIEW_HEADER_DATA_REQUEST',
  BRAND_REVIEW_HEADER_DATA_SUCCESS: 'BRAND_REVIEW_HEADER_DATA_SUCCESS',
  BRAND_REVIEW_HEADER_DATA_FAILURE: 'BRAND_REVIEW_HEADER_DATA_FAILURE',

  BRAND_REVIEW_LIST_DATA_RESET: 'BRAND_REVIEW_LIST_DATA_RESET',
  BRAND_REVIEW_LIST_DATA_REQUEST: 'BRAND_REVIEW_LIST_DATA_REQUEST',
  BRAND_REVIEW_LIST_DATA_SUCCESS: 'BRAND_REVIEW_LIST_DATA_SUCCESS',
  BRAND_REVIEW_LIST_DATA_FAILURE: 'BRAND_REVIEW_LIST_DATA_FAILURE',

  BRAND_RSS_HEADER_DATA_REQUEST: 'BRAND_RSS_HEADER_DATA_REQUEST',
  BRAND_RSS_HEADER_DATA_SUCCESS: 'BRAND_RSS_HEADER_DATA_SUCCESS',
  BRAND_RSS_HEADER_DATA_FAILURE: 'BRAND_RSS_HEADER_DATA_FAILURE',

  BRAND_RSS_LIST_DATA_RESET: 'BRAND_RSS_LIST_DATA_RESET',
  BRAND_RSS_LIST_DATA_REQUEST: 'BRAND_RSS_LIST_DATA_REQUEST',
  BRAND_RSS_LIST_DATA_SUCCESS: 'BRAND_RSS_LIST_DATA_SUCCESS',
  BRAND_RSS_LIST_DATA_FAILURE: 'BRAND_RSS_LIST_DATA_FAILURE',

  BRAND_RSS_KEYWORD_LIST_REQUEST: 'BRAND_RSS_KEYWORD_LIST_REQUEST',
  BRAND_RSS_KEYWORD_LIST_SUCCESS: 'BRAND_RSS_KEYWORD_LIST_SUCCESS',
  BRAND_RSS_KEYWORD_LIST_FAILURE: 'BRAND_RSS_KEYWORD_LIST_FAILURE',

  BRAND_RSS_KEYWORD_EDIT_REQUEST: 'BRAND_RSS_KEYWORD_EDIT_REQUEST',
  BRAND_RSS_KEYWORD_EDIT_SUCCESS: 'BRAND_RSS_KEYWORD_EDIT_SUCCESS',
  BRAND_RSS_KEYWORD_EDIT_FAILURE: 'BRAND_RSS_KEYWORD_EDIT_FAILURE',

  BRAND_REMOVE_CONTENTS_REQUEST: 'BRAND_REMOVE_CONTENTS_REQUEST',
  BRAND_REMOVE_CONTENTS_SUCCESS: 'BRAND_REMOVE_CONTENTS_SUCCESS',
  BRAND_REMOVE_CONTENTS_FAILURE: 'BRAND_REMOVE_CONTENTS_FAILURE',

  MAIN_REVIEW_DATA_REQUEST: 'MAIN_REVIEW_DATA_REQUEST',
  MAIN_REVIEW_DATA_SUCCESS: 'MAIN_REVIEW_DATA_SUCCESS',
  MAIN_REVIEW_DATA_FAILURE: 'MAIN_REVIEW_DATA_FAILURE',
};
