export const officeConstants = {
  RESETSTATE: 'RESET_STATE',

  MANAGED_OFFICE_LIST_REQUEST: 'MANAGED_OFFICE_LIST_REQUEST',
  MANAGED_OFFICE_LIST_SUCCESS: 'MANAGED_OFFICE_LIST_SUCCESS',
  MANAGED_OFFICE_LIST_FAILURE: 'MANAGED_OFFICE_LIST_FAILURE',

  ALL_OFFICE_LIST_REQUEST: 'ALL_OFFICE_LIST_REQUEST',
  ALL_OFFICE_LIST_SUCCESS: 'ALL_OFFICE_LIST_SUCCESS',
  ALL_OFFICE_LIST_FAILURE: 'ALL_OFFICE_LIST_FAILURE',

  AREA_LIST_REQUEST: 'AREA_LIST_REQUEST',
  AREA_LIST_SUCCESS: 'AREA_LIST_SUCCESS',
  AREA_LIST_FAILURE: 'AREA_LIST_FAILURE',

  SALE_OFFICE_HEADER_DATA_REQUEST: 'SALE_OFFICE_HEADER_DATA_REQUEST',
  SALE_OFFICE_HEADER_DATA_SUCCESS: 'SALE_OFFICE_HEADER_DATA_SUCCESS',
  SALE_OFFICE_HEADER_DATA_FAILURE: 'SALE_OFFICE_HEADER_DATA_FAILURE',

  SALE_DATE_LIST_DATA_RESET: 'SALE_DATE_LIST_DATA_RESET',
  SALE_DATE_LIST_DATA_REQUEST: 'SALE_DATE_LIST_DATA_REQUEST',
  SALE_DATE_LIST_DATA_SUCCESS: 'SALE_DATE_LIST_DATA_SUCCESS',
  SALE_DATE_LIST_DATA_FAILURE: 'SALE_DATE_LIST_DATA_FAILURE',

  SALE_OFFICE_LIST_DATA_RESET: 'SALE_OFFICE_LIST_DATA_RESET',
  SALE_OFFICE_LIST_DATA_REQUEST: 'SALE_OFFICE_LIST_DATA_REQUEST',
  SALE_OFFICE_LIST_DATA_SUCCESS: 'SALE_OFFICE_LIST_DATA_SUCCESS',
  SALE_OFFICE_LIST_DATA_FAILURE: 'SALE_OFFICE_LIST_DATA_FAILURE',

  SALE_WEEK_DAY_LIST_DATA_RESET: 'SALE_WEEK_DAY_LIST_DATA_RESET',
  SALE_WEEK_DAY_LIST_DATA_REQUEST: 'SALE_WEEK_DAY_LIST_DATA_REQUEST',
  SALE_WEEK_DAY_LIST_DATA_SUCCESS: 'SALE_WEEK_DAY_LIST_DATA_SUCCESS',
  SALE_WEEK_DAY_LIST_DATA_FAILURE: 'SALE_WEEK_DAY_LIST_DATA_FAILURE',

  ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_RESET: 'ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_RESET',
  ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_REQUEST: 'ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_REQUEST',
  ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_SUCCESS: 'ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_SUCCESS',
  ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_FAILURE: 'ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_FAILURE',

  DELIVERY_HEADER_DATA_REQUEST: 'DELIVERY_HEADER_DATA_REQUEST',
  DELIVERY_HEADER_DATA_SUCCESS: 'DELIVERY_HEADER_DATA_SUCCESS',
  DELIVERY_HEADER_DATA_FAILURE: 'DELIVERY_HEADER_DATA_FAILURE',

  DELIVERY_OFFICE_LIST_DATA_RESET: 'DELIVERY_OFFICE_LIST_DATA_RESET',
  DELIVERY_OFFICE_LIST_DATA_REQUEST: 'DELIVERY_OFFICE_LIST_DATA_REQUEST',
  DELIVERY_OFFICE_LIST_DATA_SUCCESS: 'DELIVERY_OFFICE_LIST_DATA_SUCCESS',
  DELIVERY_OFFICE_LIST_DATA_FAILURE: 'DELIVERY_OFFICE_LIST_DATA_FAILURE',

  DELIVERY_DATE_LIST_DATA_RESET: 'DELIVERY_DATE_LIST_DATA_RESET',
  DELIVERY_DATE_LIST_DATA_REQUEST: 'DELIVERY_DATE_LIST_DATA_REQUEST',
  DELIVERY_DATE_LIST_DATA_SUCCESS: 'DELIVERY_DATE_LIST_DATA_SUCCESS',
  DELIVERY_DATE_LIST_DATA_FAILURE: 'DELIVERY_DATE_LIST_DATA_FAILURE',

  DELIVERY_WEEK_DAY_LIST_DATA_RESET: 'DELIVERY_WEEK_DAY_LIST_DATA_RESET',
  DELIVERY_WEEK_DAY_LIST_DATA_REQUEST: 'DELIVERY_WEEK_DAY_LIST_DATA_REQUEST',
  DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS: 'DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS',
  DELIVERY_WEEK_DAY_LIST_DATA_FAILURE: 'DELIVERY_WEEK_DAY_LIST_DATA_FAILURE',

  ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_RESET: 'ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_RESET',
  ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST: 'ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST',
  ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS: 'ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS',
  ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE: 'ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE',

  DELIVERY_OFFICE_ALL_LIST_DATA_RESET: 'DELIVERY_OFFICE_ALL_LIST_DATA_RESET',
  DELIVERY_OFFICE_ALL_LIST_DATA_REQUEST: 'DELIVERY_OFFICE_ALL_LIST_DATA_REQUEST',
  DELIVERY_OFFICE_ALL_LIST_DATA_SUCCESS: 'DELIVERY_OFFICE_ALL_LIST_DATA_SUCCESS',
  DELIVERY_OFFICE_ALL_LIST_DATA_FAILURE: 'DELIVERY_OFFICE_ALL_LIST_DATA_FAILURE',

  DELIVERY_OFFICE_MARKER_RESET: 'DELIVERY_OFFICE_MARKER_RESET',
  DELIVERY_OFFICE_MARKER_REQUEST: 'DELIVERY_OFFICE_MARKER_REQUEST',
  DELIVERY_OFFICE_MARKER_SUCCESS: 'DELIVERY_OFFICE_MARKER_SUCCESS',
  DELIVERY_OFFICE_MARKER_FAILURE: 'DELIVERY_OFFICE_MARKER_FAILURE',

  DELIVERY_MARKER_DETAIL_RESET: 'DELIVERY_MARKER_DETAIL_RESET',
  DELIVERY_MARKER_DETAIL_REQUEST: 'DELIVERY_MARKER_DETAIL_REQUEST',
  DELIVERY_MARKER_DETAIL_SUCCESS: 'DELIVERY_MARKER_DETAIL_SUCCESS',
  DELIVERY_MARKER_DETAIL_FAILURE: 'DELIVERY_MARKER_DETAIL_FAILURE',

  IN_RANGE_DELIVERY_DATE_LIST_DATA_RESET: 'IN_RANGE_DELIVERY_DATE_LIST_DATA_RESET',
  IN_RANGE_DELIVERY_DATE_LIST_DATA_REQUEST: 'IN_RANGE_DELIVERY_DATE_LIST_DATA_REQUEST',
  IN_RANGE_DELIVERY_DATE_LIST_DATA_SUCCESS: 'IN_RANGE_DELIVERY_DATE_LIST_DATA_SUCCESS',
  IN_RANGE_DELIVERY_DATE_LIST_DATA_FAILURE: 'IN_RANGE_DELIVERY_DATE_LIST_DATA_FAILURE',

  MAIN_SALE_DATA_REQUEST: 'MAIN_SALE_DATA_REQUEST',
  MAIN_SALE_DATA_SUCCESS: 'MAIN_SALE_DATA_SUCCESS',
  MAIN_SALE_DATA_FAILURE: 'MAIN_SALE_DATA_FAILURE',

  MAIN_DELIVERY_DATA_REQUEST: 'MAIN_DELIVERY_DATA_REQUEST',
  MAIN_DELIVERY_DATA_SUCCESS: 'MAIN_DELIVERY_DATA_SUCCESS',
  MAIN_DELIVERY_DATA_FAILURE: 'MAIN_DELIVERY_DATA_FAILURE',

  SALE_TOTAL_DATA_RESET: 'SALE_TOTAL_DATA_RESET',
  SALE_TOTAL_DATA_REQUEST: 'SALE_TOTAL_DATA_REQUEST',
  SALE_TOTAL_DATA_SUCCESS: 'SALE_TOTAL_DATA_SUCCESS',
  SALE_TOTAL_DATA_FAILURE: 'SALE_TOTAL_DATA_FAILURE',

  DELIVERY_TOTAL_DATA_RESET: 'DELIVERY_TOTAL_DATA_RESET',
  DELIVERY_TOTAL_DATA_REQUEST: 'DELIVERY_TOTAL_DATA_REQUEST',
  DELIVERY_TOTAL_DATA_SUCCESS: 'DELIVERY_TOTAL_DATA_SUCCESS',
  DELIVERY_TOTAL_DATA_FAILURE: 'DELIVERY_TOTAL_DATA_FAILURE',

  POS_SIGNAL_LIST_REQUEST: 'POS_SIGNAL_LIST_REQUEST',
  POS_SIGNAL_LIST_SUCCESS: 'POS_SIGNAL_LIST_SUCCESS',
  POS_SIGNAL_LIST_FAILURE: 'POS_SIGNAL_LIST_FAILURE',

  DELIVERY_ORDER_APP_DATE_LIST_DATA_RESET: 'DELIVERY_ORDER_APP_DATE_LIST_DATA_RESET',
  DELIVERY_ORDER_APP_DATE_LIST_DATA_REQUEST: 'DELIVERY_ORDER_APP_DATE_LIST_DATA_REQUEST',
  DELIVERY_ORDER_APP_DATE_LIST_DATA_SUCCESS: 'DELIVERY_ORDER_APP_DATE_LIST_DATA_SUCCESS',
  DELIVERY_ORDER_APP_DATE_LIST_DATA_FAILURE: 'DELIVERY_ORDER_APP_DATE_LIST_DATA_FAILURE',

  DELIVERY_CUSTOMER_RATING_RESET: 'DELIVERY_CUSTOMER_RATING_RESET',
  DELIVERY_CUSTOMER_RATING_REQUEST: 'DELIVERY_CUSTOMER_RATING_REQUEST',
  DELIVERY_CUSTOMER_RATING_SUCCESS: 'DELIVERY_CUSTOMER_RATING_SUCCESS',
  DELIVERY_CUSTOMER_RATING_FAILURE: 'DELIVERY_CUSTOMER_RATING_FAILURE',

  EDIT_DELIVERY_CUSTOMER_TYPE_BIT_RESET: 'DELIVERY_CUSTOMER_RATING_RESET',
  EDIT_DELIVERY_CUSTOMER_TYPE_BIT_REQUEST: 'DELIVERY_CUSTOMER_RATING_REQUEST',
  EDIT_DELIVERY_CUSTOMER_TYPE_BIT_SUCCESS: 'DELIVERY_CUSTOMER_RATING_SUCCESS',
  EDIT_DELIVERY_CUSTOMER_TYPE_BIT_FAILURE: 'DELIVERY_CUSTOMER_RATING_FAILURE',

  ADD_DELIVERY_CUSTOMER_COMMENT_RESET: 'ADD_DELIVERY_CUSTOMER_COMMENT_RESET',
  ADD_DELIVERY_CUSTOMER_COMMENT_REQUEST: 'ADD_DELIVERY_CUSTOMER_COMMENT_REQUEST',
  ADD_DELIVERY_CUSTOMER_COMMENT_SUCCESS: 'ADD_DELIVERY_CUSTOMER_COMMENT_SUCCESS',
  ADD_DELIVERY_CUSTOMER_COMMENT_FAILURE: 'ADD_DELIVERY_CUSTOMER_COMMENT_FAILURE',

  DELETE_DELIVERY_CUSTOMER_COMMENT_RESET: 'DELETE_DELIVERY_CUSTOMER_COMMENT_RESET',
  DELETE_DELIVERY_CUSTOMER_COMMENT_REQUEST: 'DELETE_DELIVERY_CUSTOMER_COMMENT_REQUEST',
  DELETE_DELIVERY_CUSTOMER_COMMENT_SUCCESS: 'DELETE_DELIVERY_CUSTOMER_COMMENT_SUCCESS',
  DELETE_DELIVERY_CUSTOMER_COMMENT_FAILURE: 'DELETE_DELIVERY_CUSTOMER_COMMENT_FAILURE',

  ONE_CUSTOMER_DELIVERY_DETAIL_RESET: 'ONE_CUSTOMER_DELIVERY_DETAIL_RESET',
  ONE_CUSTOMER_DELIVERY_DETAIL_REQUEST: 'ONE_CUSTOMER_DELIVERY_DETAIL_REQUEST',
  ONE_CUSTOMER_DELIVERY_DETAIL_SUCCESS: 'ONE_CUSTOMER_DELIVERY_DETAIL_SUCCESS',
  ONE_CUSTOMER_DELIVERY_DETAIL_FAILURE: 'ONE_CUSTOMER_DELIVERY_DETAIL_FAILURE',

  DAILY_SALES_RESET: 'DAILY_SALES_RESET',
  DAILY_SALES_REQUEST: 'DAILY_SALES_REQUEST',
  DAILY_SALES_SUCCESS: 'DAILY_SALES_SUCCESS',
  DAILY_SALES_FAILURE: 'DAILY_SALES_FAILURE',

  TIME_SALES_RESET: 'TIME_SALES_RESET',
  TIME_SALES_REQUEST: 'TIME_SALES_REQUEST',
  TIME_SALES_SUCCESS: 'TIME_SALES_SUCCESS',
  TIME_SALES_FAILURE: 'TIME_SALES_FAILURE',

  WEEKDAY_SALES_RESET: 'WEEKDAY_SALES_RESET',
  WEEKDAY_SALES_REQUEST: 'WEEKDAY_SALES_REQUEST',
  WEEKDAY_SALES_SUCCESS: 'WEEKDAY_SALES_SUCCESS',
  WEEKDAY_SALES_FAILURE: 'WEEKDAY_SALES_FAILURE',

  OFFICE_SALES_RESET: 'OFFICE_SALES_RESET',
  OFFICE_SALES_REQUEST: 'OFFICE_SALES_REQUEST',
  OFFICE_SALES_SUCCESS: 'OFFICE_SALES_SUCCESS',
  OFFICE_SALES_FAILURE: 'OFFICE_SALES_FAILURE',

  OFFICE_SALES_DAILY_RESET: 'OFFICE_SALES_DAILY_RESET',
  OFFICE_SALES_DAILY_REQUEST: 'OFFICE_SALES_DAILY_REQUEST',
  OFFICE_SALES_DAILY_SUCCESS: 'OFFICE_SALES_DAILY_SUCCESS',
  OFFICE_SALES_DAILY_FAILURE: 'OFFICE_SALES_DAILY_FAILURE',

  ORDER_APP_SALES_RESET: 'ORDER_APP_SALES_RESET',
  ORDER_APP_SALES_REQUEST: 'ORDER_APP_SALES_REQUEST',
  ORDER_APP_SALES_SUCCESS: 'ORDER_APP_SALES_SUCCESS',
  ORDER_APP_SALES_FAILURE: 'ORDER_APP_SALES_FAILURE',

  SPECIFIC_DATE_SALES_RESET: 'SPECIFIC_DATE_SALES_RESET',
  SPECIFIC_DATE_SALES_REQUEST: 'SPECIFIC_DATE_SALES_REQUEST',
  SPECIFIC_DATE_SALES_SUCCESS: 'SPECIFIC_DATE_SALES_SUCCESS',
  SPECIFIC_DATE_SALES_FAILURE: 'SPECIFIC_DATE_SALES_FAILURE',

  SPECIFIC_TIME_SALES_RESET: 'SPECIFIC_TIME_SALES_RESET',
  SPECIFIC_TIME_SALES_REQUEST: 'SPECIFIC_TIME_SALES_REQUEST',
  SPECIFIC_TIME_SALES_SUCCESS: 'SPECIFIC_TIME_SALES_SUCCESS',
  SPECIFIC_TIME_SALES_FAILURE: 'SPECIFIC_TIME_SALES_FAILURE',

  SET_ORDER_APP_ACCOUNT_RESET: 'SET_ORDER_APP_ACCOUNT_RESET',
  SET_ORDER_APP_ACCOUNT_REQUEST: 'SET_ORDER_APP_ACCOUNT_REQUEST',
  SET_ORDER_APP_ACCOUNT_SUCCESS: 'SET_ORDER_APP_ACCOUNT_SUCCESS',
  SET_ORDER_APP_ACCOUNT_FAILURE: 'SET_ORDER_APP_ACCOUNT_FAILURE',

  GET_ORDER_APP_OFFICE_LIST_RESET: 'GET_ORDER_APP_OFFICE_LIST_RESET',
  GET_ORDER_APP_OFFICE_LIST_REQUEST: 'GET_ORDER_APP_OFFICE_LIST_REQUEST',
  GET_ORDER_APP_OFFICE_LIST_SUCCESS: 'GET_ORDER_APP_OFFICE_LIST_SUCCESS',
  GET_ORDER_APP_OFFICE_LIST_FAILURE: 'GET_ORDER_APP_OFFICE_LIST_FAILURE',

  GET_ORDER_APP_OFFICE_OWNER_RESET: 'GET_ORDER_APP_OFFICE_OWNER_RESET',
  GET_ORDER_APP_OFFICE_OWNER_REQUEST: 'GET_ORDER_APP_OFFICE_OWNER_REQUEST',
  GET_ORDER_APP_OFFICE_OWNER_SUCCESS: 'GET_ORDER_APP_OFFICE_OWNER_SUCCESS',
  GET_ORDER_APP_OFFICE_OWNER_FAILURE: 'GET_ORDER_APP_OFFICE_OWNER_FAILURE',

  GET_STORE_LIST_RESET: 'GET_STORE_LIST_RESET',
  GET_STORE_LIST_REQUEST: 'GET_STORE_LIST_REQUEST',
  GET_STORE_LIST_SUCCESS: 'GET_STORE_LIST_SUCCESS',
  GET_STORE_LIST_FAILURE: 'GET_STORE_LIST_FAILURE',

  MAPPING_STORE_RESET: 'MAPPING_STORE_RESET',
  MAPPING_STORE_REQUEST: 'MAPPING_STORE_REQUEST',
  MAPPING_STORE_SUCCESS: 'MAPPING_STORE_SUCCESS',
  MAPPING_STORE_FAILURE: 'MAPPING_STORE_FAILURE',

  DONG_SALES_DATA_RESET: 'DONG_SALES_DATA_RESET',
  DONG_SALES_DATA_REQUEST: 'DONG_SALES_DATA_REQUEST',
  DONG_SALES_DATA_SUCCESS: 'DONG_SALES_DATA_SUCCESS',
  DONG_SALES_DATA_FAILURE: 'DONG_SALES_DATA_FAILURE',

  TOTAL_DONG_SALES_DATA_RESET: 'TOTAL_DONG_SALES_DATA_RESET',
  TOTAL_DONG_SALES_DATA_REQUEST: 'TOTAL_DONG_SALES_DATA_REQUEST',
  TOTAL_DONG_SALES_DATA_SUCCESS: 'TOTAL_DONG_SALES_DATA_SUCCESS',
  TOTAL_DONG_SALES_DATA_FAILURE: 'TOTAL_DONG_SALES_DATA_FAILURE',

  ALL_BRAND_SALES_RESET: 'ALL_BRAND_SALES_RESET',
  ALL_BRAND_SALES_REQUEST: 'ALL_BRAND_SALES_REQUEST',
  ALL_BRAND_SALES_SUCCESS: 'ALL_BRAND_SALES_SUCCESS',
  ALL_BRAND_SALES_FAILURE: 'ALL_BRAND_SALES_FAILURE',

  MONTHLY_SALES_RESET: 'MONTHLY_SALES_RESET',
  MONTHLY_SALES_REQUEST: 'MONTHLY_SALES_REQUEST',
  MONTHLY_SALES_SUCCESS: 'MONTHLY_SALES_SUCCESS',
  MONTHLY_SALES_FAILURE: 'MONTHLY_SALES_FAILURE',

  QUARTERLY_SALES_RESET: 'QUARTERLY_SALES_RESET',
  QUARTERLY_SALES_REQUEST: 'QUARTERLY_SALES_REQUEST',
  QUARTERLY_SALES_SUCCESS: 'QUARTERLY_SALES_SUCCESS',
  QUARTERLY_SALES_FAILURE: 'QUARTERLY_SALES_FAILURE',

  YEARLY_SALES_RESET: 'YEARLY_SALES_RESET',
  YEARLY_SALES_REQUEST: 'YEARLY_SALES_REQUEST',
  YEARLY_SALES_SUCCESS: 'YEARLY_SALES_SUCCESS',
  YEARLY_SALES_FAILURE: 'YEARLY_SALES_FAILURE',

  SPECIFIC_MONTH_SALES_RESET: 'SPECIFIC_MONTH_SALES_RESET',
  SPECIFIC_MONTH_SALES_REQUEST: 'SPECIFIC_MONTH_SALES_REQUEST',
  SPECIFIC_MONTH_SALES_SUCCESS: 'SPECIFIC_MONTH_SALES_SUCCESS',
  SPECIFIC_MONTH_SALES_FAILURE: 'SPECIFIC_MONTH_SALES_FAILURE',

  SPECIFIC_QUARTER_SALES_RESET: 'SPECIFIC_QUARTER_SALES_RESET',
  SPECIFIC_QUARTER_SALES_REQUEST: 'SPECIFIC_QUARTER_SALES_REQUEST',
  SPECIFIC_QUARTER_SALES_SUCCESS: 'SPECIFIC_QUARTER_SALES_SUCCESS',
  SPECIFIC_QUARTER_SALES_FAILURE: 'SPECIFIC_QUARTER_SALES_FAILURE',

  SPECIFIC_YEAR_SALES_RESET: 'SPECIFIC_YEAR_SALES_RESET',
  SPECIFIC_YEAR_SALES_REQUEST: 'SPECIFIC_YEAR_SALES_REQUEST',
  SPECIFIC_YEAR_SALES_SUCCESS: 'SPECIFIC_YEAR_SALES_SUCCESS',
  SPECIFIC_YEAR_SALES_FAILURE: 'SPECIFIC_YEAR_SALES_FAILURE',

  SET_MULTI_ORDER_APP_ACCOUNT_RESET: 'SET_MULTI_ORDER_APP_ACCOUNT_RESET',
  SET_MULTI_ORDER_APP_ACCOUNT_REQUEST: 'SET_MULTI_ORDER_APP_ACCOUNT_REQUEST',
  SET_MULTI_ORDER_APP_ACCOUNT_SUCCESS: 'SET_MULTI_ORDER_APP_ACCOUNT_SUCCESS',
  SET_MULTI_ORDER_APP_ACCOUNT_FAILURE: 'SET_MULTI_ORDER_APP_ACCOUNT_FAILURE',

  GET_OFFICE_STATUS_REQUEST: 'GET_OFFICE_STATUS_REQUEST',
  GET_OFFICE_STATUS_SUCCESS: 'GET_OFFICE_STATUS_SUCCESS',
  GET_OFFICE_STATUS_FAILURE: 'GET_OFFICE_STATUS_FAILURE',

  ALERT_LIST_REQUEST: 'ALERT_LIST_REQUEST',
  ALERT_LIST_SUCCESS: 'ALERT_LIST_SUCCESS',
  ALERT_LIST_FAILURE: 'ALERT_LIST_FAILURE',

  ORDER_DETAIL_LIST_RESET: 'ORDER_DETAIL_LIST_RESET',
  ORDER_DETAIL_LIST_REQUEST: 'ORDER_DETAIL_LIST_REQUEST',
  ORDER_DETAIL_LIST_SUCCESS: 'ORDER_DETAIL_LIST_SUCCESS',
  ORDER_DETAIL_LIST_FAILURE: 'ORDER_DETAIL_LIST_FAILURE',

  READ_ALERT_REQUEST: 'READ_ALERT_REQUEST',
  READ_ALERT_SUCCESS: 'READ_ALERT_SUCCESS',
  READ_ALERT_FAILURE: 'READ_ALERT_FAILURE',

  READ_ORDER_DETAIL_REQUEST: 'READ_ORDER_DETAIL_REQUEST',
  READ_ORDER_DETAIL_SUCCESS: 'READ_ORDER_DETAIL_SUCCESS',
  READ_ORDER_DETAIL_FAILURE: 'READ_ORDER_DETAIL_FAILURE',

  SET_SKIP_ACCOUNT_LINK_REQUEST: 'SET_SKIP_ACCOUNT_LINK_REQUEST',
  SET_SKIP_ACCOUNT_LINK_SUCCESS: 'SET_SKIP_ACCOUNT_LINK_SUCCESS',
  SET_SKIP_ACCOUNT_LINK_FAILURE: 'SET_SKIP_ACCOUNT_LINK_FAILURE',
};
