import moment from 'moment';
import 'moment/locale/ko';
import { convertNumToWeekday, getLocale } from '../locales';

/**
 * @module DateConverter
 */

/**
 * @function
 * @description 전달 받은 param을 moment 객체로 리턴, (기본값 : 호출된 시점의 날짜)
 * @param {String} input
 * @return {Object} moment객체
 */
const _moment = input => (input ? moment(input) : moment());

/**
 * @function
 * @description hour가 한 자리 수 일때 0을 붙여 리턴
 * @param {String} input hour에 해당하는 텍스트
 * @return {String} hour(hh)
 */
export const concatZeroToTime = input => {
  if (!input) {
    return '00';
  }

  input = input.toString();

  return input.toString().length === 1 ? '0' + input : input;
};

/**
 * @function
 * @description .구분자를 -로 변경하여 moment 객체를 리턴
 * @param {String} input 날짜
 * @param {Object} input 날짜
 * @return {Object} js date객체
 */
export const toDate = input => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  return date.toDate(); // moment 객체를 js Date 객체로 변환
};

/**
 * @function
 * @description 해당 기간만큼 뺄셈된 날짜를 리턴
 * @param {String} input 날짜
 * @param {Number} value 연산할 기간
 * @param {String} 뺄셈할 단위 (일,주,월,년)
 * @return {Object} moment객체
 */
export const subDate = (input, value, type = 'D') => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  switch (type.toLowerCase()) {
    case 'd':
      date.subtract(value, 'days');
      break;
    case 'w':
      date.subtract(value, 'weeks');
      break;
    case 'm':
      date.subtract(value, 'months');
      break;
    case 'q':
      date.subtract(value, 'quarters');
      break;
    case 'y':
      date.subtract(value, 'years');
      break;
    default:
      break;
  }

  return date.format('YYYY-MM-DD');
};

/**
 * @function
 * @description 해당 기간만큼 덧셈됨 날짜를 리턴
 * @param {String} input 날짜
 * @param {Number} value 연산할 기간
 * @param {String} 덧셈할 단위 (일,주,월,년)
 * @return {Object} moment객체
 */
export const addDate = (input, value, type = 'D') => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  switch (type.toLowerCase()) {
    case 'd':
      date.subtract(value * -1, 'days'); // 왜 -1이지
      break;
    case 'w':
      date.subtract(value * -1, 'weeks');
      break;
    case 'm':
      date.subtract(value * -1, 'months');
      break;
    case 'q':
      date.subtract(value * -1, 'quarters');
      break;
    case 'y':
      date.subtract(value * -1, 'years');
      break;
    default:
      break;
  }

  return date.format('YYYY-MM-DD');
};

/**
 * @function
 * @description 전달 받은 두 날짜 범위의 일수
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @param {Boolean} isAbs 음/양수 표현 여부
 * @return {String} 범위의 일수
 */
export const dateDiff = (input1, input2, isAbs = true) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = _moment(input1);

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = _moment(input2);

  var diff = date1.diff(date2, 'days');

  return isAbs === true ? Math.abs(diff) : diff;
};

/**
 * @function
 * @description 전달 받은 날짜가 10글자 이하일 경우, .구분자를  -로 변경하고, 전달 받은 format으로 날짜를 리턴
 * @param {String} input 날짜
 * @param {String} format 형식
 * @return {*} format화된 moment객체
 */
export const dateToStr = (input, format = 'YYYY-MM-DD') => {
  input = typeof input === 'string' && input.length <= 10 ? input.replace(/\./g, '-') : input;

  var date = _moment(input);

  return date.format(format);
};

/**
 * @function
 * @description dateToStr는 전달받은 포맷대로 값을 리턴하고, dateToStr2는 type에 따른 포맷으로 값을 리턴
 * @param {String} input 날짜
 * @param {String} format 형식
 * @return {String} format화된 moment객체
 */
export const dateToStr2 = (input, type = 0) => {
  input = typeof input === 'string' && input.length <= 10 ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  switch (type) {
    case 2:
      return date.format('YYYY.MM.DD') + '.' + weekDayStr(input);
    case 3:
      return date.format('YYYY.MM.DD');
    case 4:
      return date.format('A hh:mm');
    case 5:
      return date.format('MM.DD');
    case 6:
      return date.format('M.DD');
    case 7:
      return date.format('MM.DD') + '.' + weekDayStr(input);
    case 1:
    case 8:
      return date.format('M.D') + ' (' + weekDayStr(input) + ')';
    case 9:
      return getLocale() === 'ko'
        ? date.format('M월 D일') + ' (' + weekDayStr(input) + ')'
        : date.format('YYYY-MM-DD') + ' (' + weekDayStr(input) + ')';
    case 10:
      return date.format('M월 D일');
    case 11:
      return date.format('M월 D일 dddd');
    case 12:
      return date.format('YYYY년 M월 D일') + ' ' + weekDayStr(input);
    case 13:
      return date.format('HH:mm');
    case 14:  
      return date.format('YY.MM:DD');
    case 15:  
      return date.format('YY');
    default:
      return date.format('YYYY-MM-DD');
  }
};

/**
 * @function
 * @description 기간 데이터를 정제하여 리턴한다. 예) startDate를 endDate보다 뒷 날짜로 설정해도 바르게 바꾸어서 출력
 * @param {String} startInput datepicker1에서 선택된 날짜
 * @param {String} endInput datepicker2에서 선택된 날짜
 * @param {Boolean} needEmpty
 * @return {*}
 */
export const getDateRange = (startInput, endInput, needEmpty = false) => {
  var result = [];

  startInput = strToDateQuery(startInput); // strToDateQuery: YYYY-mm-dd 형태의 moment객체
  endInput = strToDateQuery(endInput);

  startInput = typeof input === 'string' ? startInput.replace(/\./g, '-') : startInput;
  endInput = typeof input === 'string' ? endInput.replace(/\./g, '-') : endInput;

  var dateMove = _moment(startInput);
  var strDate = startInput;

  if (startInput === endInput) {
    strDate = dateMove.format('YYYY-MM-DD');
    result.push(strDate);
    if (needEmpty === true) {
      result.push('');
    }
  } else {
    while (strDate < endInput) {
      // 어떤 작업?
      strDate = dateMove.format('YYYY-MM-DD');
      result.push(strDate);
      if (needEmpty === true) {
        result.push('');
      }
      dateMove.add(1, 'days');
    }
  }

  return result;
};

/**
 * @function
 * @description 전달받은 날짜를 moment객체로 리턴
 * @param {String} input 날짜
 * @return {Object} moment객체 (YYYY-mm-dd)
 */
export const strToDateQuery = input => {
  input = typeof input === 'string' ? concatZeroToDate(input.replace(/\./g, '-')) : input;

  var date = _moment(input);

  return date.format('YYYY-MM-DD');
};

/**
 * @function
 * @description 설명추가
 * @param {String} input
 * @return {string} -구분자로 구성된 날짜
 */
export const concatZeroToDate = input => {
  var inputArray = input.split('-');

  inputArray.map((item, i) => {
    if (i === 0) {
      return null;
    }

    inputArray[i] = concatZeroToTime(item);
    return null;
  });

  return inputArray.join('-');
};

/**
 * @function
 * @description 전달받은 param의 달의 마지막 날짜를 리턴
 * @param {String} input 날짜
 * @param {Boolean} diffNow
 * @return {String} moment객체 (YYYY-mm-dd형태의 마지막 날짜)
 */
export const endOfMonthDay = (input, diffNow = true) => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  var endOfDay = date.endOf('month').format('YYYY-MM-DD');

  if (diffNow) {
    var now = _moment().format('YYYY-MM-DD');

    return endOfDay > now ? now : endOfDay;
  }

  return endOfDay;
};

/**
 * @function
 * @description 전달받은 param의 달의 시작 날짜를 리턴
 * @param {String} input 날짜
 * @return {Object} moment객체 (YYYY-mm-dd)
 */
export const startOfMonthDay = input => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  var startOfDay = date.startOf('month').format('YYYY-MM-DD');

  return startOfDay;
};

/**
 * @function
 * @description 현재시각을 반환
 * @return {Object} moment객체
 */
export const getCurrentTime = () => {
  return _moment().valueOf();
};

/**
 * @function
 * @description 전달 받은 날짜의 시간을 세팅하여 moment객체로 리턴
 * @param {String} input 날짜
 * @param {String} hour 세팅하고자 하는 hour
 * @return {Object} moment객체
 */
export const hours = (input, hour) => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;

  return _moment(input).hours(hour);
};

/**
 * @function
 * @description 전달 받은 param의 요일을 구하여 리턴
 * @param {String} input 날짜
 * @return {String} 월 화 수 목 금 토 일
 */
export const weekDayStr = input => {
  var result = '';
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = input ? moment(input) : moment();

  result = convertNumToWeekday(date.weekday());

  return result;
};

/**
 * @function
 * @description UNIX timestamp
 * @return {Number}
 */
export const getUnixTime = () => {
  return new Date().getTime();
};

export const changeToYMD = target => {
  let dateData = new Date(target);
  let year = dateData.getFullYear();
  let month = (dateData.getMonth() + 1).toString();
  let date = dateData.getDate().toString();

  month = month.length === 1 ? '0' + month : month;
  date = date.length === 1 ? '0' + date : date;

  return `${year}-${month}-${date}`;
};

export const validDate = (date, format = 'YYYY-MM-DD') => {
  if (!date) {
    return false;
  }

  return _moment(date, format).isValid();
};

/**
 * @function
 * @description 전달 받은 두 날짜의 시간 차이
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @return {Number} 분 차이
 */
export const minuteDiff = (input1, input2) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = input1 ? moment(input1) : moment();

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = input2 ? moment(input2) : moment();

  var diff = date1.diff(date2, 'minutes');
  return diff;
};

/**
 * @function
 * @description 전달 받은 두 날짜의 시간 차이
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @return {Number} 시간 차이
 */
export const hourDiff = (input1, input2) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = input1 ? moment(input1) : moment();

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = input2 ? moment(input2) : moment();

  var diff = date1.diff(date2, 'hour');
  return diff;
};

/**
 * @function
 * @description 전달 받은 두 날짜의 월 차이
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @param {Boolean} isAbs 음/양수 표현 여부
 * @return {Number} 월 차이
 */
export const monthDiff = (input1, input2, isAbs = true) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = input1 ? moment(input1) : moment();

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = input2 ? moment(input2) : moment();

  var diff = date1.diff(date2, 'months');

  return isAbs === true ? Math.abs(diff) : diff;
};

/**
 * @function
 * @description 전달 받은 두 날짜의 분기 차이
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @param {Boolean} isAbs 음/양수 표현 여부
 * @return {Number} 분기 차이
 */
export const quarterDiff = (input1, input2, isAbs = true) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = input1 ? moment(input1) : moment();

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = input2 ? moment(input2) : moment();

  var diff = date1.diff(date2, 'quarters');

  return isAbs === true ? Math.abs(diff) : diff;
};

/**
 * @function
 * @description 전달 받은 두 날짜의 연도 차이
 * @param {String} input1 날짜1
 * @param {String} input2 날짜2
 * @param {Boolean} isAbs 음/양수 표현 여부
 * @return {Number} 연도 차이
 */
export const yearDiff = (input1, input2, isAbs = true) => {
  input1 = typeof input1 === 'string' ? input1.replace(/\./g, '-') : input1;
  var date1 = input1 ? moment(input1) : moment();

  input2 = typeof input2 === 'string' ? input2.replace(/\./g, '-') : input2;
  var date2 = input2 ? moment(input2) : moment();

  var diff = date1.diff(date2, 'years');

  return isAbs === true ? Math.abs(diff) : diff;
};

/**
 * @function
 * @description 전달받은 param의 연도의 마지막 날짜를 리턴
 * @param {String} input 날짜
 * @param {Boolean} diffNow
 * @return {String} moment객체 (YYYY-mm-dd형태의 마지막 날짜)
 */
export const endOfYearDay = (input, diffNow = true) => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  var endOfDay = date.endOf('years').format('YYYY-MM-DD');

  if (diffNow) {
    var now = _moment().format('YYYY-MM-DD');

    return endOfDay > now ? now : endOfDay;
  }

  return endOfDay;
};

/**
 * @function
 * @description 전달받은 param의 연도의 시작 날짜를 리턴
 * @param {String} input 날짜
 * @return {Object} moment객체 (YYYY-mm-dd)
 */
export const startOfYearDay = input => {
  input = typeof input === 'string' ? input.replace(/\./g, '-') : input;
  var date = _moment(input);

  var startOfDay = date.startOf('years').format('YYYY-MM-DD');

  return startOfDay;
};

/**
 * @function
 * @description 전달받은 param 시간과 현재 시간과 차이를 계산하여 구분하는 함수
 * @param {String} input 날짜
 * @return {Object} 초,분,시간 차이
 */
export const compareTimeToCurrent = targetTime => {
  const currentTime = moment();
  const diffMinutes = currentTime.diff(targetTime, 'minutes');
  const diffHours = currentTime.diff(targetTime, 'hours');

  // 결과 반환
  if (diffMinutes < 1) {
    return '방금';
  } else if (diffMinutes <= 59) {
    return `${diffMinutes}분전`;
  } else if (diffHours <= 23) {
    return `${diffHours}시간전`;
  } else {
    return dateToStr2(targetTime, 10);
  }
};

//mobile 로컬스토리지 하루동안 보지 않기
export const checkPopupClose = () => {
  const expireDay = localStorage.getItem('hidePopup');
  let today = new Date();

  if (!expireDay) {
    return true;
  }
  if (today.getTime() > expireDay) {
    return true;
  } else {
    return false;
  }
};
