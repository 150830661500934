import { menuConstants } from '../constants';
import { menuService } from '../services';
import { paymentPlanProcess } from '../helpers/PaymentPlanProcess';

export const menuActions = {
  brandMenuList,
  categoryList,
  saleMenuHeaderData,
  saleMenuListData,
  menuDateListData,
  deliveryMenuHeaderData,
  deliveryMenuListData,
  deliveryMenuOptionListData,
  allMenuEngineering,
  menuEngineeringOffice,
  oneMenuOfficeHeaderData,
  oneMenuOfficeListData,
  oneMenuDateListData,
  oneMenuSaleWeekDayListData,
  oneMenuDeliveryWeekDayListData,
  mainMenuData,
  saleMenuTotalData,
  editCategoryName,
  disableBrandMenu,
  unlinkMenu,
  editBrandMenu,
  menuLastReceipt,
  recommendUnlinkMenuData,
  registBrandMenu,
  deleteCategory,
  disableRetailMenu,

  // v2
  menuSales,
  diffMenuSales,
  menuSalesByDaily,
  optionMenuSales,
  diffOptionMenuSales,
  oneMenuOptions,
  oneOptionMenuMains,
  oneMenuOffices,
  diffOneMenuOffices,
  menuSalesSumByDaily,
  optionMenuSalesSumByDaily,
  categorySales,
  oneCategoryOffices,
  setTabState,
  setPrevTabState,
  getSuggestMenus,
};

function brandMenuList(brandCode) {
  return dispatch => {
    dispatch(request());

    menuService.brandMenuList(brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.BRAND_MENU_LIST_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.BRAND_MENU_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.BRAND_MENU_LIST_FAILURE, error };
  }
}

function categoryList(brandCode) {
  return dispatch => {
    dispatch(request());

    menuService.categoryList(brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.CATEGORY_LIST_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.CATEGORY_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.CATEGORY_LIST_FAILURE, error };
  }
}

function saleMenuHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .saleMenuHeaderData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.SALE_MENU_HEADER_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.SALE_MENU_HEADER_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.SALE_MENU_HEADER_DATA_FAILURE, error };
  }
}

function saleMenuListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  officeName,
  menuSnoQuery,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    menuService
      .saleMenuListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
        officeName,
        menuSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: menuConstants.SALE_MENU_LIST_DATA_RESET };
  }
  function request() {
    return { type: menuConstants.SALE_MENU_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.SALE_MENU_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.SALE_MENU_LIST_DATA_FAILURE, error };
  }
}

function menuDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  officeName,
  menuSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .menuDateListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        officeName,
        menuSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.MENU_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_DATE_LIST_DATA_FAILURE, error };
  }
}

function deliveryMenuHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  sortKey,
  officeSnoQuery,
  dlType,
  menuSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .deliveryMenuHeaderData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        sortKey,
        officeSnoQuery,
        dlType,
        menuSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.DELIVERY_MENU_HEADER_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DELIVERY_MENU_HEADER_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DELIVERY_MENU_HEADER_DATA_FAILURE, error };
  }
}

function deliveryMenuListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
  optionViewType,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    menuService
      .deliveryMenuListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
        dlType,
        officeName,
        menuSnoQuery,
        optionViewType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: menuConstants.DELIVERY_MENU_LIST_DATA_RESET };
  }
  function request() {
    return { type: menuConstants.DELIVERY_MENU_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DELIVERY_MENU_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DELIVERY_MENU_LIST_DATA_FAILURE, error };
  }
}

function deliveryMenuOptionListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
  optionType,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    menuService
      .deliveryMenuOptionListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
        dlType,
        officeName,
        menuSnoQuery,
        optionType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_RESET };
  }
  function request() {
    return { type: menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_FAILURE, error };
  }
}

function allMenuEngineering(userSno, brandCode, serviceType, startDate, endDate, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    menuService.allMenuEngineering(userSno, brandCode, serviceType, startDate, endDate, officeSnoQuery).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.ALL_MENU_ENGINEERING_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ALL_MENU_ENGINEERING_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ALL_MENU_ENGINEERING_FAILURE, error };
  }
}

function menuEngineeringOffice(userSno, categoryIdx, brandCode, choiceAreaCode, startDate, endDate, serviceType) {
  return dispatch => {
    dispatch(request());

    menuService
      .menuEngineeringOffice(userSno, categoryIdx, brandCode, choiceAreaCode, startDate, endDate, serviceType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.MENU_ENGINEERING_OFFICE_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_ENGINEERING_OFFICE_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_ENGINEERING_OFFICE_FAILURE, error };
  }
}

function oneMenuOfficeHeaderData(
  userSno,
  brandCode,
  menuSno,
  startDate,
  endDate,
  serviceType,
  diffStartDate,
  diffEndDate,
  menuName,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuOfficeHeaderData(
        userSno,
        brandCode,
        menuSno,
        startDate,
        endDate,
        serviceType,
        diffStartDate,
        diffEndDate,
        menuName,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_OFFICE_HEADER_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_OFFICE_HEADER_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_OFFICE_HEADER_DATA_FAILURE, error };
  }
}

function oneMenuOfficeListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    menuService
      .oneMenuOfficeListData(
        menuSno,
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: menuConstants.ONE_MENU_OFFICE_LIST_DATA_RESET };
  }
  function request() {
    return { type: menuConstants.ONE_MENU_OFFICE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_OFFICE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_OFFICE_LIST_DATA_FAILURE, error };
  }
}

function oneMenuDateListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuName,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuDateListData(
        menuSno,
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuName,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_DATE_LIST_DATA_FAILURE, error };
  }
}

function oneMenuSaleWeekDayListData(
  menuSno,
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuName,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuSaleWeekDayListData(
        menuSno,
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuName,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}

function oneMenuDeliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuDeliveryWeekDayListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuSnoQuery,
        dlType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}

function mainMenuData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    menuService.mainMenuData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.MAIN_MENU_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MAIN_MENU_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MAIN_MENU_DATA_FAILURE, error };
  }
}

function saleMenuTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    menuService.saleMenuTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.SALE_MENU_TOTAL_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.SALE_MENU_TOTAL_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.SALE_MENU_TOTAL_DATA_FAILURE, error };
  }
}

function editCategoryName(brandCode, categoryIdx, categoryName) {
  return dispatch => {
    dispatch(request());

    menuService.editCategoryName(brandCode, categoryIdx, categoryName).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.EDIT_CATEGORY_NAME_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.EDIT_CATEGORY_NAME_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.EDIT_CATEGORY_NAME_FAILURE, error };
  }
}

function disableBrandMenu(brandMenuSno) {
  return dispatch => {
    dispatch(request());

    menuService.disableBrandMenu(brandMenuSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.DISABLE_BRAND_MENU_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DISABLE_BRAND_MENU_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DISABLE_BRAND_MENU_FAILURE, error };
  }
}

function unlinkMenu(brandMenuSno, inMenuSnoQuery, outMenuSnoQuery) {
  return dispatch => {
    dispatch(request());

    menuService.unlinkMenu(brandMenuSno, inMenuSnoQuery, outMenuSnoQuery).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.UNLINK_MENU_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.UNLINK_MENU_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.UNLINK_MENU_FAILURE, error };
  }
}

function editBrandMenu(
  brandMenuSno,
  brandMenuName,
  brandMenuPrice,
  categoryIdx,
  menuType,
  brandType,
  inMenuSnoQuery,
  outMenuSnoQuery,
  menuImgPath,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .editBrandMenu(
        brandMenuSno,
        brandMenuName,
        brandMenuPrice,
        categoryIdx,
        menuType,
        brandType,
        inMenuSnoQuery,
        outMenuSnoQuery,
        menuImgPath,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.EDIT_BRAND_MENU_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.EDIT_BRAND_MENU_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.EDIT_BRAND_MENU_FAILURE, error };
  }
}

function menuLastReceipt(brandCode, menuSno, srcType) {
  return dispatch => {
    dispatch(request());

    menuService.menuLastReceipt(brandCode, menuSno, srcType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.MENU_LAST_RECEIPT_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_LAST_RECEIPT_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_LAST_RECEIPT_FAILURE, error };
  }
}

function recommendUnlinkMenuData(brandCode) {
  return dispatch => {
    dispatch(request());

    menuService.recommendUnlinkMenuData(brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.RECOMMEND_UNLINK_MENU_DATA_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.RECOMMEND_UNLINK_MENU_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.RECOMMEND_UNLINK_MENU_DATA_FAILURE, error };
  }
}

function registBrandMenu(brandCode, menuName, menuPrice, categoryIdx, menuSnoQuery, menuImgPath) {
  return dispatch => {
    dispatch(request());

    menuService.registBrandMenu(brandCode, menuName, menuPrice, categoryIdx, menuSnoQuery, menuImgPath).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.REGIST_BRAND_MENU_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.REGIST_BRAND_MENU_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.REGIST_BRAND_MENU_FAILURE, error };
  }
}

function deleteCategory(categoryIdx) {
  return dispatch => {
    dispatch(request());

    menuService.deleteCategory(categoryIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.DELETE_CATEGORY_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DELETE_CATEGORY_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DELETE_CATEGORY_FAILURE, error };
  }
}

function disableRetailMenu(brandCode, menuSnoQuery) {
  return dispatch => {
    dispatch(request());

    menuService.disableRetailMenu(brandCode, menuSnoQuery).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.DISABLE_RETAIL_MENU_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DISABLE_RETAIL_MENU_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DISABLE_RETAIL_MENU_FAILURE, error };
  }
}

function menuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      menuService
        .menuSales(
          getType,
          userSno,
          brandCode,
          orderApp,
          startDate,
          endDate,
          officeSnoList,
          menuSnoList,
          siteType,
          includeOption,
          orderChannelList,
          standardSaleTime,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: menuConstants.MENU_SALES_RESET };
  }
  function request() {
    return { type: menuConstants.MENU_SALES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_SALES_FAILURE, error };
  }
}

function diffMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .diffMenuSales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSnoList,
        siteType,
        includeOption,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.DIFF_MENU_SALES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DIFF_MENU_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DIFF_MENU_SALES_FAILURE, error };
  }
}

function menuSalesByDaily(
  userSno,
  brandCode,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .menuSalesByDaily(
        userSno,
        brandCode,
        startDate,
        endDate,
        officeSnoList,
        menuSnoList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.MENU_SALES_BY_DAILY_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_SALES_BY_DAILY_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_SALES_BY_DAILY_FAILURE, error };
  }
}

function optionMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      menuService
        .optionMenuSales(
          getType,
          userSno,
          brandCode,
          orderApp,
          startDate,
          endDate,
          officeSnoList,
          menuSnoList,
          siteType,
          includeOption,
          orderChannelList,
          standardSaleTime,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: menuConstants.OPTION_MENU_SALES_RESET };
  }
  function request() {
    return { type: menuConstants.OPTION_MENU_SALES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.OPTION_MENU_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.OPTION_MENU_SALES_FAILURE, error };
  }
}

function diffOptionMenuSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  siteType,
  includeOption,
  orderChannelList,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .diffOptionMenuSales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSnoList,
        siteType,
        includeOption,
        orderChannelList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.DIFF_OPTION_MENU_SALES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DIFF_OPTION_MENU_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DIFF_OPTION_MENU_SALES_FAILURE, error };
  }
}

function oneMenuOptions(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  orderChannelList,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuOptions(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSno,
        orderChannelList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_OPTIONS_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_OPTIONS_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_OPTIONS_FAILURE, error };
  }
}

function oneOptionMenuMains(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  orderChannelList,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneOptionMenuMains(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSno,
        orderChannelList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_OPTION_MENU_OPTIONS_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_OPTION_MENU_OPTIONS_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_OPTION_MENU_OPTIONS_FAILURE, error };
  }
}

function oneMenuOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  includeOption,
  orderChannelList,
  standardSaleTime,
  onlyOption,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneMenuOffices(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSno,
        includeOption,
        orderChannelList,
        standardSaleTime,
        onlyOption,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_MENU_OFFICES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_MENU_OFFICES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_MENU_OFFICES_FAILURE, error };
  }
}

function diffOneMenuOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSno,
  includeOption,
  orderChannelList,
  standardSaleTime,
  onlyOption,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .diffOneMenuOffices(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        menuSno,
        includeOption,
        orderChannelList,
        standardSaleTime,
        onlyOption,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.DIFF_ONE_MENU_OFFICES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.DIFF_ONE_MENU_OFFICES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.DIFF_ONE_MENU_OFFICES_FAILURE, error };
  }
}

function menuSalesSumByDaily(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      menuService
        .menuSalesSumByDaily(
          getType,
          userSno,
          brandCode,
          orderApp,
          startDate,
          endDate,
          officeSnoList,
          includeOption,
          orderChannelList,
          standardSaleTime,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: menuConstants.MENU_SALES_SUM_BY_DAILY_RESET };
  }
  function request() {
    return { type: menuConstants.MENU_SALES_SUM_BY_DAILY_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.MENU_SALES_SUM_BY_DAILY_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.MENU_SALES_SUM_BY_DAILY_FAILURE, error };
  }
}
function optionMenuSalesSumByDaily(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  includeOption,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      menuService
        .optionMenuSalesSumByDaily(
          getType,
          userSno,
          brandCode,
          orderApp,
          startDate,
          endDate,
          officeSnoList,
          includeOption,
          orderChannelList,
          standardSaleTime,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_RESET };
  }
  function request() {
    return { type: menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_FAILURE, error };
  }
}

function categorySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  menuSnoList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      menuService
        .categorySales(
          getType,
          userSno,
          brandCode,
          orderApp,
          startDate,
          endDate,
          officeSnoList,
          menuSnoList,
          orderChannelList,
          standardSaleTime,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: menuConstants.CATEGORY_SALES_RESET };
  }
  function request() {
    return { type: menuConstants.CATEGORY_SALES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.CATEGORY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.CATEGORY_SALES_FAILURE, error };
  }
}

function oneCategoryOffices(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  officeSnoList,
  categoryIdx,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    menuService
      .oneCategoryOffices(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        officeSnoList,
        categoryIdx,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: menuConstants.ONE_CATEGORY_OFFICES_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.ONE_CATEGORY_OFFICES_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.ONE_CATEGORY_OFFICES_FAILURE, error };
  }
}

function setTabState(tab) {
  return { type: menuConstants.SET_TAB_STATE, tab };
}

function setPrevTabState(tab) {
  return { type: menuConstants.SET_PREV_TAB_STATE, tab };
}

function getSuggestMenus(brandCode, menuSno, menuName) {
  return dispatch => {
    dispatch(request());

    menuService.getSuggestMenus(brandCode, menuSno, menuName).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: menuConstants.GET_SUGGEST_MENUS_REQUEST };
  }
  function success(result) {
    return { type: menuConstants.GET_SUGGEST_MENUS_SUCCESS, result };
  }
  function failure(error) {
    return { type: menuConstants.GET_SUGGEST_MENUS_FAILURE, error };
  }
}
