import { reportConstants } from "../constants";
import { reportService } from "../services";

export const reportActions = {
  excelDownloadBySearch,

  excelSaleDateView,
  excelSaleMonthView,
  excelSaleTimeView,
  excelSaleDetailView,

  excelMenuDayView,
  excelMenuMonthView,
  excelMenuTimeView,

  excelDeliveryDayView,
  excelDeliveryMonthView,
  excelDeliveryLocationView,

  excelMaterialDateView,
  excelMaterialMonthView,
  excelMaterialTimeView,

  monthlyReportHeaderData,
  monthlyReportSalesData,
  monthlyReportMenuSalesData,
  monthlyReportDeliveryData,
  monthlyReportDeliveryOrderAppData,
  monthlyReportPDFUpload,
  reportList,
  reportBookmark,
  reportRemove,
};

function excelDownloadBySearch(type) {
  return dispatch => {
    dispatch(request());

    reportService.excelDownloadBySearch(type)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_FAILURE, error } }
}

function excelSaleDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelSaleDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_SALE_DATE_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_SALE_DATE_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_SALE_DATE_VIEW_FAILURE, error } }
}

function excelSaleMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelSaleMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_SALE_MONTH_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_SALE_MONTH_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_SALE_MONTH_VIEW_FAILURE, error } }
}

function excelSaleTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelSaleTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_SALE_TIME_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_SALE_TIME_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_SALE_TIME_VIEW_FAILURE, error } }
}

function excelSaleDetailView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelSaleDetailView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_SALE_DETAIL_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_SALE_DETAIL_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_SALE_DETAIL_VIEW_FAILURE, error } }
}

function excelMenuDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMenuDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MENU_DAY_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MENU_DAY_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MENU_DAY_VIEW_FAILURE, error } }
}

function excelMenuMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMenuMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MENU_MONTH_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MENU_MONTH_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MENU_MONTH_VIEW_FAILURE, error } }
}

function excelMenuTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMenuTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MENU_TIME_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MENU_TIME_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MENU_TIME_VIEW_FAILURE, error } }
}

function excelDeliveryDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  return dispatch => {
    dispatch(request());

    reportService.excelDeliveryDayView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_DELIVERY_DAY_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_DELIVERY_DAY_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_DELIVERY_DAY_VIEW_FAILURE, error } }
}


function excelDeliveryMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  return dispatch => {
    dispatch(request());

    reportService.excelDeliveryMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_DELIVERY_MONTH_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_DELIVERY_MONTH_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_DELIVERY_MONTH_VIEW_FAILURE, error } }
}

function excelDeliveryLocationView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType) {
  return dispatch => {
    dispatch(request());
    reportService.excelDeliveryLocationView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, menuSnoQuery, dlType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_FAILURE, error } }
}

function excelMaterialDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMaterialDateView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MATERIAL_DATE_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MATERIAL_DATE_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MATERIAL_DATE_VIEW_FAILURE, error } }
}

function excelMaterialMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMaterialMonthView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MATERIAL_MONTH_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MATERIAL_MONTH_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MATERIAL_MONTH_VIEW_FAILURE, error } }
}

function excelMaterialTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.excelMaterialTimeView(userSno, brandCode, serviceType, startDate, endDate, sortType, officeSnoQuery, materialSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.EXCEL_MATERIAL_TIME_VIEW_REQUEST } }
  function success(result) { return { type: reportConstants.EXCEL_MATERIAL_TIME_VIEW_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.EXCEL_MATERIAL_TIME_VIEW_FAILURE, error } }
}

function reportList(userSno, brandCode) {
  return dispatch => {
    dispatch(request());

    reportService.reportList(userSno, brandCode)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.REPORT_LIST_REQUEST } }
  function success(result) { return { type: reportConstants.REPORT_LIST_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.REPORT_LIST_FAILURE, error } }
}

function reportBookmark(userSno, reportSno, flag) {
  return dispatch => {
    dispatch(request());

    reportService.reportBookmark(userSno, reportSno, flag)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.REPORT_BOOKMARK_REQUEST } }
  function success(result) { return { type: reportConstants.REPORT_BOOKMARK_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.REPORT_BOOKMARK_FAILURE, error } }
}

function reportRemove(brandCode, reportSnoQuery) {
  return dispatch => {
    dispatch(request());

    reportService.reportRemove(brandCode, reportSnoQuery)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.REPORT_REMOVE_REQUEST } }
  function success(result) { return { type: reportConstants.REPORT_REMOVE_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.REPORT_REMOVE_FAILURE, error } }
}

function monthlyReportHeaderData(brandCode, serviceType, searchYear, searchMonth) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportHeaderData(brandCode, serviceType, searchYear, searchMonth)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_HEADER_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_HEADER_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_HEADER_FAILURE, error } }
}

function monthlyReportSalesData(brandCode, serviceType, searchYear, searchMonth) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportSalesData(brandCode, serviceType, searchYear, searchMonth)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_SALES_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_SALES_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_SALES_FAILURE, error } }
}

function monthlyReportMenuSalesData(brandCode, serviceType, searchYear, searchMonth) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportMenuSalesData(brandCode, serviceType, searchYear, searchMonth)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_MENU_SALES_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_MENU_SALES_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_MENU_SALES_FAILURE, error } }
}

function monthlyReportDeliveryData(brandCode, serviceType, searchYear, searchMonth) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportDeliveryData(brandCode, serviceType, searchYear, searchMonth)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_FAILURE, error } }
}

function monthlyReportDeliveryOrderAppData(brandCode, searchYear, searchMonth) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportDeliveryOrderAppData(brandCode, searchYear, searchMonth)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_FAILURE, error } }
}

function monthlyReportPDFUpload(title, fileType, brandCode, userSno, uploadData, fileName) {
  return dispatch => {
    dispatch(request());

    reportService.monthlyReportPDFUpload(title, fileType, brandCode, userSno, uploadData, fileName)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: reportConstants.MONTHLY_REPORT_UPLOAD_REQUEST } }
  function success(result) { return { type: reportConstants.MONTHLY_REPORT_UPLOAD_SUCCESS, result } }
  function failure(error) { return { type: reportConstants.MONTHLY_REPORT_UPLOAD_FAILURE, error } }
}
