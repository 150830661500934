import { advertisementConstants, advertisementProperty } from '../constants';
import { advertisementService } from '../services/Advertisement';
import { deepClone, sortDesc } from '../helpers';

export const advertisementActions = {
  setOfficeInfo,
  setDistanceToggle,
  setActiveMarkerInfo,
  setDimmedOfficeFilter,
  setOfficeView,
  getAreaLocationPinListData,
  getOfficeLocationPinListData,
  setOfficeLocationPinListData,
  getAdvertisementMarkerData,
  setPinInfo,
  setActiveChannels,
  setMaxDeliveryBounds,
  setAdvertisementFilter,
};

function setOfficeInfo(officeInfo) {
  return { type: advertisementConstants.SET_OFFICE_INFO, officeInfo };
}

function setDistanceToggle(distance) {
  return { type: advertisementConstants.SET_DISTANCE_TOGGLE, distance };
}

function setActiveMarkerInfo(isReset, activeMarkerInfo) {
  switch (isReset) {
    case true:
      return { type: advertisementConstants.RESET_ACTIVE_MARKER_INFO };
    case false:
      return { type: advertisementConstants.SET_ACTIVE_MARKER_INFO, activeMarkerInfo };
    default:
      return null;
  }
}

function setDimmedOfficeFilter(isDimmed) {
  return { type: advertisementConstants.SET_DIMMED_OFFICE_FILTER, isDimmed };
}

function setOfficeView(isShow) {
  return { type: advertisementConstants.SET_OFFICE_VIEW, isShow };
}

function getAreaLocationPinListData(userSno, brandCode, officeSnoList, startDate, endDate) {
  return dispatch => {
    dispatch(request());

    advertisementService.getAreaLocationPinListData(userSno, brandCode, officeSnoList, startDate, endDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: advertisementConstants.ADVERTISEMENT_AREA_LIST_REQUEST };
  }
  function success(result) {
    return { type: advertisementConstants.ADVERTISEMENT_AREA_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: advertisementConstants.ADVERTISEMENT_AREA_LIST_FAILURE, error };
  }
}

function getOfficeLocationPinListData(
  userSno,
  brandCode,
  officeSno,
  startDate,
  endDate,
  pinIdListStr,
  advertisementViewType,
) {
  return dispatch => {
    dispatch(request());

    advertisementService
      .getOfficeLocationPinListData(userSno, brandCode, officeSno, startDate, endDate, pinIdListStr)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: advertisementConstants.ADVERTISEMENT_OFFICE_LIST_REQUEST };
  }
  function success(result) {
    const clone = deepClone(result);

    clone.result.pinList = sortDesc(result.result.pinList, advertisementProperty[advertisementViewType].pin);
    result = clone;

    return { type: advertisementConstants.ADVERTISEMENT_OFFICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: advertisementConstants.ADVERTISEMENT_OFFICE_LIST_FAILURE, error };
  }
}

function setOfficeLocationPinListData(result) {
  return { type: advertisementConstants.ADVERTISEMENT_OFFICE_LIST_SUCCESS, result };
}

function getAdvertisementMarkerData(userSno, brandCode, officeSno, startDate, endDate, pinIdListStr) {
  return dispatch => {
    dispatch(request());

    advertisementService
      .getOfficeLocationPinListData(userSno, brandCode, officeSno, startDate, endDate, pinIdListStr)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: advertisementConstants.ADVERTISEMENT_MARKER_LIST_REQUEST };
  }
  function success(result) {
    return { type: advertisementConstants.ADVERTISEMENT_MARKER_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: advertisementConstants.ADVERTISEMENT_MARKER_LIST_FAILURE, error };
  }
}

function setPinInfo(pinInfo) {
  return { type: advertisementConstants.SET_PIN_INFO, pinInfo };
}

function setActiveChannels(channels) {
  return { type: advertisementConstants.SET_ACTIVE_CHANNELS, channels };
}

function setMaxDeliveryBounds(bounds) {
  return { type: advertisementConstants.SET_MAX_DELIVERY_BOUNDS, bounds };
}

function setAdvertisementFilter(filterType, visibleAdvertisements) {
  const result = { filterType: filterType, visibleAdvertisements: visibleAdvertisements };
  return { type: advertisementConstants.SET_ADVERTISEMENT_FILTER, result };
}
