import { commonActions, userActions } from '.';
import { authConstants, isMobile, isTablet } from '../constants';
import { dateToStr, startOfMonthDay, toSha256 } from '../helpers';
import { authService } from '../services';
import { checkReactNativeWebView } from '../helpers/AppWebView';
import WebSocket from '../helpers/WebSocket';
import ChannelService from '../helpers/ChannelChat';

export const authActions = {
  logout,
  login,
  brandLogin,

  validPhoneNum,
  validMobileAuth,
  sendPhoneAuthNumber,
  checkAuthNumber,
  resetPassword,
  checkLoginState,
  systemErrorLog,
  changeEmail,
  changeMobile,
  ghostInit,
  checkAdminAccount,
  checkJandiBrandAccessAuth,
  setAppInfo,
  setIsAlarmPermissionDenied,
  setAppPushOption,
  setAuthInfo,
};

function logout(callLogout) {
  authService.logout(callLogout);

  return { type: authConstants.LOGOUT };
}

function login(userID, password, serviceType, kakaoKey, deviceType, localToken) {
  return dispatch => {
    dispatch(request());

    authService.login(userID, password, serviceType, kakaoKey, deviceType, localToken).then(
      result => {
        if (isMobile() && !isTablet() && !checkReactNativeWebView()) {
          const confirmResult = window.confirm('‘앳트래커’ 앱을 실행하시겠습니까?');
          const executionDynamicLink = async () => {
            const dynamicBaseUrl = 'https://attracker.page.link';
            const appId = 'shop.attracker.service';
            const iosAppId = '1638899690';
            const targetUrl = `${window.location.protocol}//${window.location.host}`;
            const userSno = toSha256(result['result']['userData']['user_sno']);
            const link = encodeURIComponent(`${targetUrl}/#/mobile/officeOwnerAnalysis?compare_user=${userSno}`);
            const longDynamicLink = `${dynamicBaseUrl}/?link=${link}&apn=${appId}&isi=${iosAppId}&ibi=${appId}&efr=1`;

            const shortDynamicLink = await fetch(
              'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDFZMggJqo9cocXqvfmXRqVcWAEVeuuzos',
              {
                method: 'POST',
                body: JSON.stringify({
                  longDynamicLink: longDynamicLink,
                }),
              },
            )
              .then(response => {
                if (!response.ok) {
                  throw new Error(`${response.status} error`);
                }

                return response.json();
              })
              .catch(error => console.log(error.message));

            localStorage.clear();
            WebSocket.destory();
            ChannelService.reboot();

            window.location.href = shortDynamicLink ? shortDynamicLink['shortLink'] : longDynamicLink;
          };

          if (confirmResult) {
            executionDynamicLink();
            return;
          }
        }

        dispatch(success(result));
        dispatch(
          commonActions.setFilterParams(
            {
              startDate: startOfMonthDay(),
              endDate: dateToStr(),
            },
            true,
          ),
        );
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }

  function success(result) {
    return { type: authConstants.LOGIN_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function brandLogin(userID, token) {
  return dispatch => {
    dispatch(request());

    authService.brandLogin(userID, token).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.BRAND_LOGIN_REQUEST };
  }

  function success(result) {
    return { type: authConstants.BRAND_LOGIN_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.BRAND_LOGIN_FAILURE, error };
  }
}

function validPhoneNum(phoneNumber, serviceType) {
  return dispatch => {
    dispatch(request());

    authService.validPhoneNum(phoneNumber, serviceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.VALID_PHONENUM_REQUEST };
  }

  function success(result) {
    return { type: authConstants.VALID_PHONENUM_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.VALID_PHONENUM_FAILURE, error };
  }
}

function validMobileAuth(authData) {
  return dispatch => {
    dispatch(request());

    if (authData) {
      dispatch(success({ result: authData }));
    } else {
      dispatch(failure('error'));
    }
  };

  function request() {
    return { type: authConstants.VALID_MOBILE_AUTH_REQUEST };
  }

  function success(result) {
    return { type: authConstants.VALID_MOBILE_AUTH_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.VALID_MOBILE_AUTH_FAILURE, error };
  }
}

function sendPhoneAuthNumber(phoneNumber, locationServiceType) {
  return dispatch => {
    dispatch(request());

    authService.sendPhoneAuthNumber(phoneNumber, locationServiceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.PHONE_AUTH_NUMBER_REQUEST };
  }

  function success(result) {
    return { type: authConstants.PHONE_AUTH_NUMBER_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.PHONE_AUTH_NUMBER_FAILURE, error };
  }
}

function checkAuthNumber(token, authNumber) {
  return dispatch => {
    dispatch(request());

    authService.checkAuthNumber(token, authNumber).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHECK_AUTH_NUMBER_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHECK_AUTH_NUMBER_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHECK_AUTH_NUMBER_FAILURE, error };
  }
}

function resetPassword(phoneNumber, password) {
  return dispatch => {
    dispatch(request());

    authService.resetPassword(phoneNumber, password).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.RESETPASSWORD_REQUEST };
  }

  function success(result) {
    return { type: authConstants.RESETPASSWORD_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.RESETPASSWORD_FAILURE, error };
  }
}

function checkLoginState(userSno) {
  return dispatch => {
    dispatch(request());

    authService.checkLoginState(userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHECK_LOGIN_STATE_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHECK_LOGIN_STATE_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHECK_LOGIN_STATE_FAILURE, error };
  }
}

function systemErrorLog(host, path, message) {
  return dispatch => {
    dispatch(request());

    authService.systemErrorLog(host, path, message).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.SYSTEM_ERROR_LOG_REQUEST };
  }

  function success(user) {
    return { type: authConstants.SYSTEM_ERROR_LOG_SUCCESS, user };
  }

  function failure(error) {
    return { type: authConstants.SYSTEM_ERROR_LOG_FAILURE, error };
  }
}

function changeEmail(userSno, email) {
  return dispatch => {
    dispatch(request());

    authService.changeEmail(userSno, email).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHANGE_EMAIL_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHANGE_EMAIL_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHANGE_EMAIL_FAILURE, error };
  }
}

function changeMobile(userSno, mobile) {
  return dispatch => {
    dispatch(request());

    authService.changeMobile(userSno, mobile).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHANGE_MOBILE_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHANGE_MOBILE_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHANGE_EMAIL_FAILURE, error };
  }
}

function ghostInit(companyCode, brandCode, userSno) {
  return dispatch => {
    dispatch(request());

    authService.ghostInit(companyCode, brandCode, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.GHOST_INIT_REQUEST };
  }

  function success(result) {
    return { type: authConstants.GHOST_INIT_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.GHOST_INIT_FAILURE, error };
  }
}

function checkAdminAccount(userID, password, otp) {
  return dispatch => {
    dispatch(request());

    authService.checkAdminAccount(userID, password, otp).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHECK_ADMIN_ACCOUNT_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHECK_ADMIN_ACCOUNT_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHECK_ADMIN_ACCOUNT_FAILURE, error };
  }
}

function checkJandiBrandAccessAuth(userSno, officeSno, token) {
  return dispatch => {
    dispatch(request());

    authService.checkJandiBrandAccessAuth(userSno, officeSno, token).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_REQUEST };
  }

  function success(result) {
    return { type: authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_FAILURE, error };
  }
}

function setAppInfo(appInfo) {
  return { type: authConstants.SET_APP_INFO, appInfo };
}

function setIsAlarmPermissionDenied(isAlarmPermissionDenied) {
  return { type: authConstants.SET_IS_ALARM_PERMISSION_DENIED, isAlarmPermissionDenied };
}

function setAppPushOption(userSno, serviceType, isInit, optionType, optionValue) {
  return dispatch => {
    dispatch(request());

    authService.setAppPushOption(userSno, isInit, optionType, optionValue).then(
      result => {
        dispatch(success(result));
        dispatch(userActions.myUserData(userSno, serviceType));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: authConstants.SET_APP_PUSH_OPTION_REQUEST };
  }

  function success(result) {
    return { type: authConstants.SET_APP_PUSH_OPTION_SUCCESS, result };
  }

  function failure(error) {
    return { type: authConstants.SET_APP_PUSH_OPTION_FAILURE, error };
  }
}

function setAuthInfo(authInfo) {
  return { type: authConstants.SET_AUTH_INFO, authInfo };
}
