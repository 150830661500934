import { validLoginExpire } from './AuthValidation';
/**
 * @module ApiResponse
 */

/**
 * @function
 * @description 섦명추가
 * @param {Promise} response
 * @return {Object}
 */
export const handleResponse = response => {
  validLoginExpire();

  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    if (data.error !== undefined) {
      return Promise.reject(data.error);
    }

    return data;
  });
};
